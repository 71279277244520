import React from "react";
import { useSelector } from "react-redux";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../partials/content/Portlet";
import { metronic } from "../../../_metronic";
import { Table,Button } from "react-bootstrap";


export default function Aksi() {

  return (
    <>

    <div className="row">
      <div className="col-xl-12">
        <div className="row row-full-height">
          <div className="col-sm-8">

          <div className="kt-portlet kt-portlet--height-fluid">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">Task</h3>
              </div>
            </div>
            <div className="kt-portlet__body">
              <div className="kt-widget4">
                <div className="kt-widget4__item">
                  <span className="kt-widget4__icon">
                    <i className="flaticon2-trash kt-font-danger" />
                  </span>
                  <a
                    className="kt-widget4__title"
                    href="https://keenthemes.com.my/metronic"
                  >
                    Persetujuan Hapus Data Aset Operator karena salah input
                  </a>
                  <Button variant="primary">
                    Setuju
                  </Button>
                  &nbsp;
                  <Button variant="danger">
                    Tidak Setuju
                  </Button>
                </div>



                <div className="kt-widget4__item">
                  <span className="kt-widget4__icon">
                    <i className="flaticon2-edit kt-font-success" />
                  </span>
                  <a
                    className="kt-widget4__title"
                    href="https://keenthemes.com.my/metronic"
                  >
                    Persetujuan Ubah Jumlah Data Aset
                  </a>
                  <Button variant="primary">
                    Setuju
                  </Button>
                  &nbsp;
                  <Button variant="danger">
                    Tidak Setuju
                  </Button>
                </div>

              </div>
            </div>
          </div>

        </div>




      </div>

    </div>
</div>








    </>
  );
}
