import React from 'react';

import { Form } from "react-bootstrap";

export default function CmbTahun(){


  return (
    <Form.Control as="select">
      <option value=''>Pilih Tahun..</option>
      <option value='2020'>2020</option>
      <option value='2019'>2019</option>
    </Form.Control>
  )
}
