import axios from "axios";

export const SAVE_URL = "/api/inventory/table/";
export const UPDATE_URL = "/api/inventory/table/";
export const DELETE_URL = "/api/inventory/table/";
export const LIST_URL = "/api/inventory/table/";
export const GET_DATA_URL="/api/inventory/table/";

export function doAdd(data) {
  return axios.post(SAVE_URL, data);
}

export function doUpdate(data, id) {
  return axios.put(`${UPDATE_URL}${id}/`, data);
}

export function doDelete(id) {
  return axios.delete(`${DELETE_URL}${id}/`);
}
export function getData(id) {
  return axios.get(`${GET_DATA_URL}${id}/`);
}

export function doList(params) {
  return axios.get(LIST_URL, {
    params: params
  })
}
