import axios from "axios";
import { BASE_API_PORTAL } from './config'

const api = axios.create({
  baseURL:BASE_API_PORTAL ,
  timeout: 10000,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
  }
});

export const SAVE_URL = "cctv/save";
export const UPDATE_URL = "cctv/update";
export const DELETE_URL = "cctv/delete";
export const LIST_URL = "cctv/list/getpost";
export const GET_DATA="cctv/getData";

// Set config defaults when creating the instance



export function doAdd(obj) {
  return api.post(SAVE_URL,obj);
}

export function doUpdate(obj) {
  return api.post(UPDATE_URL, obj);
}

export function doDelete(val) {
  return api.get(DELETE_URL+'/'+val);
}
export function getData(val) {
  return api.get(GET_DATA+'/'+val);
}

export function doList(page,pageSize,sorted,filtered) {

  return api.post(LIST_URL,{
    page:page,pagesize:pageSize,sorted:sorted,filtered:filtered
  })
}
