/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import { withRouter } from "react-router-dom";
import { QuickActions } from "./components/QuickActions";
import { LayoutContextConsumer } from "../LayoutContext";
import { ReactComponent as SortNum1Icon } from "../../../_metronic/layout/assets/layout-svg-icons/SortNum1.svg";
import * as builder from "../../ducks/builder";
import BreadCrumbs from "./components/BreadCrumbs";
import {
  Col,
  Form,
  Button,
  Dropdown
} from "react-bootstrap";
import CmbKotama from "../../../app/pages/ajax/combobox/CmbKotama";
import CmbTahun from "../../../app/pages/ajax/combobox/CmbTahun";


class SubHeader extends React.Component {
  render() {
    const {
      subheaderCssClasses,
      subheaderContainerCssClasses,
      subheaderMobileToggle
    } = this.props;


    return (
      <div
        id="kt_subheader"
        className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}
      >
        <div className={`kt-container ${subheaderContainerCssClasses}`}>
          <div className="kt-subheader__main">
            {subheaderMobileToggle && (
              <button
                className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                id="kt_subheader_mobile_toggle"
              >
                <span />
              </button>
            )}

            <LayoutContextConsumer>
              {({ subheader: { title, breadcrumb } }) => (
                // console.log('test sub header',title, breadcrumb)
                <BreadCrumbs items={breadcrumb} />
              )}

              {/* {({ subheader: { title } }) => (
                <>
                  <h3 className="kt-subheader__title">{title}</h3>
                </>
              )} */}
            </LayoutContextConsumer>
          </div>


          {(() => {
        switch (this.props.location.pathname) {
          case "/list/aset/":   return <>
          <div className="kt-subheader__toolbar">
          <Dropdown className="kt-portlet__head-toolbar" drop="down" alignRight>
            <Dropdown.Toggle
              variant="transparent"
              className="btn btn-label-success btn-sm btn-bold dropdown-toggle"
              id="dropdown-toggle-top"
            >
              Export
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right">
              <ul className="kt-nav">
              <li className="kt-nav__item">
                <a href="#" className="kt-nav__link">
                  <span className="kt-nav__link-text">XLS</span>
                </a>
              </li>
              <li className="kt-nav__item">
                <a href="#" className="kt-nav__link">
                  <span className="kt-nav__link-text">PDF</span>
                </a>
              </li>
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        &nbsp;
        <div className="kt-subheader__wrapper">
           <Button variant="success"><i className="kt-nav__link-icon flaticon2-add" /> Tambah</Button>
           </div>
         </div>
          </>
          ;
          case "/list/arealayanan/":   return <>

          </>
          ;
          case "/dashboard/fungsi/":   return <>

          </>
          ;
          case "/laporan/distribusi/":   return <>


          <div className="kt-subheader__toolbar">
                              <Dropdown className="kt-portlet__head-toolbar" drop="down" alignRight>
                                <Dropdown.Toggle
                                  variant="transparent"
                                  className="btn btn-label-success btn-sm btn-bold dropdown-toggle"
                                  id="dropdown-toggle-top"
                                >
                                  Export
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right">
                                  <ul className="kt-nav">
                                  <li className="kt-nav__item">
                                    <a href="#" className="kt-nav__link">
                                      <span className="kt-nav__link-text">XLS</span>
                                    </a>
                                  </li>
                                  <li className="kt-nav__item">
                                    <a href="#" className="kt-nav__link">
                                      <span className="kt-nav__link-text">PDF</span>
                                    </a>
                                  </li>
                                  </ul>
                                </Dropdown.Menu>
                              </Dropdown>
                            &nbsp;
                      <div className="kt-subheader__wrapper">
                        <CmbTahun></CmbTahun>
                      </div>
                    </div>
          </>
          ;
          default:      return<>

          <div className="kt-subheader__toolbar">
                          <Dropdown className="kt-portlet__head-toolbar" drop="down" alignRight>
                            <Dropdown.Toggle
                              variant="transparent"
                              className="btn btn-label-success btn-sm btn-bold dropdown-toggle"
                              id="dropdown-toggle-top"
                            >
                              Export
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right">
                              <ul className="kt-nav">
                              <li className="kt-nav__item">
                                <a href="#" className="kt-nav__link">
                                  <span className="kt-nav__link-text">XLS</span>
                                </a>
                              </li>
                              <li className="kt-nav__item">
                                <a href="#" className="kt-nav__link">
                                  <span className="kt-nav__link-text">PDF</span>
                                </a>
                              </li>
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        &nbsp;
                      <div className="kt-subheader__wrapper">
                        <CmbKotama idBidang=''></CmbKotama>
                      </div>
                    </div>

                    </>
;
        }
      })()}

        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  subheaderMobileToggle: objectPath.get(
    store.builder.layoutConfig,
    "subheader.mobile-toggle"
  ),
  subheaderCssClasses: builder.selectors.getClasses(store, {
    path: "subheader",
    toString: true
  }),
  subheaderContainerCssClasses: builder.selectors.getClasses(store, {
    path: "subheader_container",
    toString: true
  })
});

export default withRouter(connect(mapStateToProps)(SubHeader));
