import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../partials/content/Portlet";
import { metronic } from "../../../_metronic";
import QuickStatsChart from "../../widgets/QuickStatsChart";
import OrderStatisticsChart from "../../widgets/OrderStatisticsChart";
import OrdersWidget from "../../widgets/OrdersWidget";
import SalesBarChart from "../../widgets/SalesBarChart";
import DownloadFiles from "../../widgets/DownloadFiles";
import NewUsers from "../../widgets/NewUsers";
import LatestUpdates from "../../widgets/LatestUpdates";
import BestSellers from "../../widgets/BestSellers";
import RecentActivities from "../../widgets/RecentActivities";
import PortletHeaderDropdown from "../../partials/content/CustomDropdowns/PortletHeaderDropdown";
import PieChart from "../../components/dashboard/Pie";
import BarChart from "../../components/dashboard/Bar";
import { Table } from "react-bootstrap";


export default function Pesawat() {
  const { brandColor, dangerColor, successColor, primaryColor } = useSelector(
    state => ({
      brandColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.brand"
      ),
      dangerColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.danger"
      ),
      successColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.success"
      ),
      primaryColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.primary"
      )
    })
  );



  return (
    <>
    <div className="row">
    <div className="col-xl-12">
      <h3>PESAWAT & HELIKOPTER</h3>
    </div>
    </div>
    <Portlet>
      <PortletBody fit={true}>
        <div className="row row-no-padding row-col-separator-xl">
          <div className="col-xl-4">

          <div className="kt-widget1">
            <div className="kt-widget1__item ng-star-inserted">
              <div className="kt-widget1__info">
                <h3 className="kt-widget1__title">KONDISI BAIK</h3>
                <span className="kt-widget1__desc">Pesawat & Helikopter status kondisi baik</span>
              </div>

              <span className="kt-widget1__number  kt-font-success">1173‬</span>
            </div>

            <div className="kt-widget1__item ng-star-inserted">
              <div className="kt-widget1__info">
                <h3 className="kt-widget1__title">KONDISI RUSAK RINGAN</h3>
                <span className="kt-widget1__desc">Pesawat & Helikopter status kondisi rusak ringan</span>
              </div>
              <span className="kt-widget1__number kt-font-warning">75</span>
            </div>

            <div className="kt-widget1__item ng-star-inserted">
              <div className="kt-widget1__info">
                <h3 className="kt-widget1__title">KONDISI RUSAK BERAT</h3>
                <span className="kt-widget1__desc">Pesawat & Helikopter status kondisi rusak berat</span>
              </div>
              <span className="kt-widget1__number kt-font-danger ">75</span>
            </div>
            <div className="kt-widget1__item ng-star-inserted">
              <div className="kt-widget1__info">
                <h3 className="kt-widget1__title">TOTAL</h3>
                <span className="kt-widget1__desc">total aset barang</span>
              </div>
              <span className="kt-widget1__number kt-font-brand">1323</span>
            </div>

          </div>
          </div>
          <div className="col-xl-4">
            <PieChart total="707" baik="603" rr="52" rb="52" title="PESAWAT" desc="data pesawat"/>
          </div>
          <div className="col-xl-4">
              <PieChart total="616" baik="570" rr="23" rb="23" title="HELIPKOTER" desc="Data Helikopter"/>
          </div>
        </div>
      </PortletBody>
    </Portlet>


    <div className="row">

    <div className="col-xl-4">
    <Portlet fluidHeight={true}>
      <PortletHeader
        title="TABEL PESAWAT"

      />

      <PortletBody>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Nama Jenis</th>
              <th>Baik</th>
              <th>RR</th>
              <th>RB</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>TANK ANGKUT PERSONEL</td>
              <td>300</td>
              <td>13</td>
              <td>13</td>
            </tr>
            <tr>
              <td>2</td>
              <td>TANK KANON</td>
              <td>279</td>
              <td>36</td>
              <td>36</td>
            </tr>
            <tr>
              <td>3</td>
              <td>TANK AMBULAN</td>
              <td>14</td>
              <td>0</td>
              <td>0</td>
            </tr>
          </tbody>
        </Table>
        </PortletBody>
      </Portlet>
    </div>
      <div className="col-xl-8">
      <Portlet fluidHeight={true}>
        <PortletHeader
          title="GRAFIK PESAWAT"

        />

        <PortletBody>
          <BarChart />
        </PortletBody>
      </Portlet>


      </div>
    </div>
    <div className="row">

    <div className="col-xl-4">
    <Portlet fluidHeight={true}>
      <PortletHeader
        title="TABEL HELIKOPTER"

      />

      <PortletBody>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Nama Jenis</th>
              <th>Baik</th>
              <th>RR</th>
              <th>RB</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>TANK ANGKUT PERSONEL</td>
              <td>300</td>
              <td>13</td>
              <td>13</td>
            </tr>
            <tr>
              <td>2</td>
              <td>TANK KANON</td>
              <td>279</td>
              <td>36</td>
              <td>36</td>
            </tr>
            <tr>
              <td>3</td>
              <td>TANK AMBULAN</td>
              <td>14</td>
              <td>0</td>
              <td>0</td>
            </tr>
          </tbody>
        </Table>
        </PortletBody>
      </Portlet>
    </div>
      <div className="col-xl-8">
      <Portlet fluidHeight={true}>
        <PortletHeader
          title="GRAFIK HELIKOPTER"

        />

        <PortletBody>
          <BarChart />
        </PortletBody>
      </Portlet>


      </div>
    </div>




    </>
  );
}
