import React, { useState, useEffect } from "react"
import { useFormik } from "formik";
import * as Yup from 'yup';
import { connect } from "react-redux";
import { 
  FormattedMessage, 
  injectIntl 
} from "react-intl";
import { Grid } from "@material-ui/core";
import clsx from "clsx";
import * as satyanis from "../../../store/ducks/admin/satyanis.duck";
import * as global from "../../../store/ducks/global.duck";
import { doAdd, doUpdate } from "../../../crud/admin/satyanis.crud";
import { doList as doListKotama } from "../../../crud/admin/kotama.crud";
import AlertForm from "../../../components/AlertForm";
import { Modal } from "react-bootstrap";
import TextFieldGroup from "../../../components/form/TextFieldGroup";
import { validationErrorMessage } from "../../../../_metronic/utils/helper";

function Form(props) {
    const { show, closeModal, data, reloadData } = props;
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({open: false, type: 'success', message: ''})
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
      paddingRight: "2.5rem"
    });

    const enableLoading = () => {
      setLoading(true);
      setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
      setLoading(false);
      setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };

    const closeModalHandler = () => {
        closeModal();
        setAlert({open:false})
    };

    const loadKotamaOptions = () => {
      doListKotama({ordering: 'nm_kotama', page_size: 9999999})
        .then(({ data: { results } }) => {
          props.getKotamaOptions(results);
        }
      )
    }

    useEffect(() => {
      loadKotamaOptions()
      // eslint-disable-next-line
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            kd_satyanis: data ? data.kd_satyanis : '',
            nm_satyanis: data ? data.nm_satyanis : '',
            kd_rka: data ? data.kd_rka : '',
            nm_rka: data ? data.nm_rka : '',
            kd_pekas: data ? data.kd_pekas : '',
            nm_pekas: data ? data.nm_pekas : '',
            kotama: data ? data.kotama.id : ''
        },
        validationSchema: Yup.object({
            kd_satyanis: Yup.string()
                .max(4, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 4}} />)
                .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            nm_satyanis: Yup.string()
                .max(20, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 20}} />)
                .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            kd_rka: Yup.string()
                .max(10, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 10}} />)
                .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            nm_rka: Yup.string()
                .max(20, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 20}} />)
                .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            kd_pekas: Yup.string()
                .max(4, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 4}} />)
                .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            nm_pekas: Yup.string()
                .max(20, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 20}} />)
                .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            kotama: Yup.number()
                .integer(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />)
                .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
        }),
        onSubmit: (values, { resetForm }) => {
            enableLoading();
            if(data === undefined){
                doAdd(values)
                .then(({ data }) => {
                    resetForm();
                    disableLoading();
                    reloadData();
                    setAlert({open: true, type: 'success', message: 'Tambah data berhasil.'})
                })
                .catch((err) => {
                    disableLoading();
                    let errorMessage = validationErrorMessage(err)
                    setAlert({open: true, type: 'danger', message: `Tambah data gagal. ${errorMessage}`})
                });
            } else {
                doUpdate(values, data.id)
                .then(({ data }) => {
                    disableLoading();
                    reloadData();
                    setAlert({open: true, type: 'success', message: 'Simpan data berhasil.'})
                })
                .catch((err) => {
                    disableLoading();
                    let errorMessage = validationErrorMessage(err)
                    setAlert({open: true, type: 'danger', message: `Simpan data gagal. ${errorMessage}`})
                });
            }
        }
    });

    return (
        <Modal
            size="lg"
            show={show}
            onHide={() => closeModalHandler()}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    {data ? 'Edit' : 'Tambah'} data satyanis
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                    noValidate={true}
                    autoComplete="off"
                    className="kt-form"
                    onSubmit={formik.handleSubmit}
                >
                    <AlertForm 
                        show={alert.open}
                        variant={alert.type}
                        msg={alert.message}
                        closeAlert={() => setAlert({open: false})}
                    />
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                                <TextFieldGroup
                                    type="text"
                                    label="Kode Satyanis"
                                    margin="normal"
                                    className="kt-width-full"
                                    name="kd_satyanis"
                                    {...formik.getFieldProps('kd_satyanis')}
                                    errorText={formik.touched.kd_satyanis && formik.errors.kd_satyanis}
                                    error={Boolean(formik.touched.kd_satyanis && formik.errors.kd_satyanis)}
                                />
                                <TextFieldGroup
                                    type="text"
                                    margin="normal"
                                    label="Nama Satyanis"
                                    className="kt-width-full"
                                    name="nm_satyanis"
                                    {...formik.getFieldProps('nm_satyanis')}
                                    errorText={formik.touched.nm_satyanis && formik.errors.nm_satyanis}
                                    error={Boolean(formik.touched.nm_satyanis && formik.errors.nm_satyanis)}
                                />
                                <TextFieldGroup
                                    type="text"
                                    label="Kode RKA"
                                    margin="normal"
                                    className="kt-width-full"
                                    name="kd_rka"
                                    {...formik.getFieldProps('kd_rka')}
                                    errorText={formik.touched.kd_rka && formik.errors.kd_rka}
                                    error={Boolean(formik.touched.kd_rka && formik.errors.kd_rka)}
                                />
                                <TextFieldGroup
                                    type="text"
                                    margin="normal"
                                    label="Nama RKA"
                                    className="kt-width-full"
                                    name="nm_rka"
                                    {...formik.getFieldProps('nm_rka')}
                                    errorText={formik.touched.nm_rka && formik.errors.nm_rka}
                                    error={Boolean(formik.touched.nm_rka && formik.errors.nm_rka)}
                                />
                        </Grid>
                        <Grid item xs={6}>
                                <TextFieldGroup
                                    type="text"
                                    label="Kode Pekas"
                                    margin="normal"
                                    className="kt-width-full"
                                    name="kd_pekas"
                                    {...formik.getFieldProps('kd_pekas')}
                                    errorText={formik.touched.kd_pekas && formik.errors.kd_pekas}
                                    error={Boolean(formik.touched.kd_pekas && formik.errors.kd_pekas)}
                                />
                                <TextFieldGroup
                                    type="text"
                                    margin="normal"
                                    label="Nama Pekas"
                                    className="kt-width-full"
                                    name="nm_pekas"
                                    {...formik.getFieldProps('nm_pekas')}
                                    errorText={formik.touched.nm_pekas && formik.errors.nm_pekas}
                                    error={Boolean(formik.touched.nm_pekas && formik.errors.nm_pekas)}
                                />
                                <TextFieldGroup
                                    select={true}
                                    margin="normal"
                                    label="Kotama"
                                    className="kt-width-full"
                                    name="kotama"
                                    {...formik.getFieldProps('kotama')}
                                    errorText={formik.touched.kotama && formik.errors.kotama}
                                    error={Boolean(formik.touched.kotama && formik.errors.kotama)}
                                >
                                    <option>Pilih Kotama</option>
                                    { props.kotama_options && props.kotama_options.map(kotama => (<option key={kotama.id} value={kotama.id}>{kotama.nm_kotama}</option>)) }
                                </TextFieldGroup>
                        </Grid>
                    </Grid>
                    <div className="kt-login__actions">

                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={loading}
                        className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                            "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                        }
                        )}`}
                        style={loadingButtonStyle}
                    >
                        { data ? 'Update' : 'Simpan'}
                    </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

const { getKotamaOptions } = global.actions

export default injectIntl(
    connect(
      satyanis.states,
      {...satyanis.actions, getKotamaOptions}
    )(Form)
  );
