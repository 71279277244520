import React, { useEffect } from 'react';
import { connect} from "react-redux";
import { injectIntl } from "react-intl";
import * as global from "../../../store/ducks/global.duck";
import { doList, getData } from "../../../crud/admin/kotama.crud";
import { Form } from "react-bootstrap";

const CmbKotama = (props) => {
  
  const onChangeCmb = (e) => {
    if(e.target.value) {
      getData(e.target.value)
        .then(({ data }) => {
          props.getKotama(data);
        }
      )
    } else {
      props.getKotama('');
    }
  }
 
  const loadKotamaOptions = () => {
    doList({ordering: 'nm_kotama', page_size: 9999999})
      .then(({ data: { results } }) => {
        props.getKotamaOptions(results);
      }
    )
  }

  useEffect(() => {
    loadKotamaOptions()
  }, []);

  return (
    <Form.Control size="sm" as="select" onChange={onChangeCmb} value={props.kotama.id}>
      <option value=''>Pilih kotama..</option>
      {props.kotama_options && props.kotama_options.map(data => (<option key={data.id} value={data.id}>{data.nm_kotama}</option>))}
    </Form.Control>
  )
}

export default injectIntl(
    connect(
      global.states,
      global.actions
    )(CmbKotama)
  );