export default {
  header: {
    self: {},
    items: [
      {
        title: "SISTEM INFORMASI DATA LOGISTIK",
        root: true,
        alignment: "left"
      }
    ]
  },
  aside: {
    self: {},
    items: [
      { section: "Executive Dashboard" },
      {
        title: "Aset Dashboard",
        root: true,
        icon: "flaticon-pie-chart-1",
        page: "dashboard/fungsi"
      },
      {
        title: "Dashboard",
        root: true,
        icon: "flaticon-graph",
        submenu: [
          {
            title: "Ranpur",
            bullet: "dot",
            page: "dashboard/ranpur"
          },{
            title: "Pesawat & Helikopter",
            bullet: "dot",
            page: "dashboard/pesawat"
          },{
            title: "Kapal Angkut & Pendarat",
            bullet: "dot",
            page: ""
          },{
            title: "Kapal Tradisional & Jenis Lain",
            bullet: "dot",
            page: ""
          }
        ],
        translate: "MENU.DASHBOARD",
        bullet: "dot"
      },{
        title: "TOP/DSPP",
        root: true,
        icon: "flaticon-clipboard",
        page: "pencarian/aset",
        bullet: "dot"
      },
      {
        title: "Rencana Kebutuhan",
        root: true,
        icon: "flaticon2-pie-chart-2",
        page: ""
      }, { section: "Task" },
        {
          title: "Persetujuan",
          root: true,
          icon: "flaticon-like",
          page: "persetujuan/aksi"
        },
        { section: "Pemeliharaan Data" }
      ,{
        title: "Input Aset",
        root: true,
        icon: "flaticon-clipboard",
        page: "pencarian/aset",
        bullet: "dot"
      },
      {
        title: "Penghapusan Data",
        root: true,
        icon: "flaticon-close",
        page: "dashboard/fungsi"
      },
      {
        title: "Input Laporan Kerusakan",
        root: true,
        icon: "flaticon-cogwheel",
        page: "dashboard/fungsi"
      },
      {
        title: "Pendistribusian",
        root: true,
        bullet: "dot",
        icon: "flaticon-interface-4",
        submenu: [
          {
            title: "Perencanaan",
            bullet: "dot",
          },{
            title: "Transfer Masuk",
            bullet: "dot",
            page: "distribusi/tm"
          },{
            title: "Transfer Keluar",
            bullet: "dot",
            page: "tktm/in"
          }
        ]
      },
      // {
      //   title: "Dashboard Backup",
      //   root: true,
      //   icon: "flaticon2-architecture-and-city",
      //   page: "dashboardbackup",
      //   bullet: "dot"
      // },

      { section: "Laporan" },
      {
        title: "Rencana Distribusi",
        bullet: "dot",
        icon:"flaticon2-crisp-icons",
      },
      {
        title: "TOP / DSPP",
        bullet: "dot",
        icon:"flaticon2-crisp-icons",
      },

      { section: "Administrator" },
      {
        title: "User Management",
        root: true,
        bullet: "dot",
        icon: "flaticon-user",
        page: "admin/user-management"
      },
      {
        title: "User Log",
        root: true,
        bullet: "dot",
        icon: "flaticon2-browser-2",
        page: "admin/user-log"
      },
      {
        title: "Utility",
        root: true,
        bullet: "dot",
        icon: "flaticon-settings-1",
        // submenu: [
        //   {
        //     title: "Satker",
        //     bullet: "dot",
        //     page: "admin/satker"
        //   },{
        //     title: "Satpor",
        //     bullet: "dot",
        //     page: "admin/satpor"
        //   },
        //   {
        //     title: "Satyanis",
        //     bullet: "dot",
        //     page: "admin/satyanis"
        //   },
        //   {
        //     title: "Kotama",
        //     bullet: "dot",
        //     page: "admin/kotama"
        //   },
        //   {
        //     title: "Satker",
        //     bullet: "dot",
        //     page: "admin/satker"
        //   }
        // ]
      },
      {
        title: "Hartabel",
        root: true,
        bullet: "dot",
        icon: "flaticon2-browser-2",
        submenu: [
          {
            title: "Tanah & Pendukung BTB",
            bullet: "dot",
            submenu: [
              {
                title: "Tabel Referensi",
                bullet: "dot",
                submenu: [
                  {
                    title: "Air",
                    bullet: "dot",
                    page: "admin/air"
                  },
                  {
                    title: "Asal",
                    bullet: "dot",
                    page: "admin/asal"
                  },
                  {
                    title: "Bentuk Bangunan",
                    bullet: "dot",
                    page: "admin/bentuk-bangunan"
                  },
                  {
                    title: "Bukti",
                    bullet: "dot",
                    page: "admin/bukti"
                  },
                  {
                    title: "Gas",
                    bullet: "dot",
                    page: "admin/gas"
                  },
                  {
                    title: "Konstruksi",
                    bullet: "dot",
                    page: "admin/konstruksi"
                  },
                  {
                    title: "Listrik",
                    bullet: "dot",
                    page: "admin/listrik"
                  },
                  {
                    title: "Status",
                    bullet: "dot",
                    page: "admin/status"
                  },
                  {
                    title: "Kondisi",
                    bullet: "dot",
                    page: "admin/kondisi"
                  },
                ]
              },
              {
                title: "Klasifikasi/Tabel Barang",
                bullet: "dot",
                submenu: [
                  {
                    title: "Kelompok",
                    bullet: "dot",
                    page: "admin/kelompok"
                  },
                  {
                    title: "Komoditi",
                    bullet: "dot",
                    page: "admin/komoditi"
                  },
                  {
                    title: "Fungsi",
                    bullet: "dot",
                    page: "admin/fungsi"
                  },
                  {
                    title: "Jenis",
                    bullet: "dot",
                    page: "admin/jenis"
                  },
                  {
                    title: "Merk",
                    bullet: "dot",
                    page: "admin/merk"
                  }
                ]
              }
            ]
          },
          {
            title: "Bangunan & BB",
            bullet: "dot",
            submenu: [
              {
                title: "Tabel Referensi",
                bullet: "dot",
                submenu: [
                  {
                    title: "Tabel",
                    bullet: "dot",
                    page: "admin/table"
                  },
                  {
                    title: "Isi Tabel",
                    bullet: "dot",
                    page: "admin/table-option"
                  },
                ]
              },
              {
                title: "Klasifikasi/Tabel Barang",
                bullet: "dot",
                submenu: [
                  {
                    title: "Kelompok",
                    bullet: "dot",
                    page: "admin/top-kelompok"
                  },
                  {
                    title: "Komoditi",
                    bullet: "dot",
                    page: "admin/top-komoditi"
                  },
                  {
                    title: "Fungsi",
                    bullet: "dot",
                    page: "admin/top-fungsi"
                  },
                  {
                    title: "Jenis",
                    bullet: "dot",
                    page: "admin/top-jenis"
                  },
                  {
                    title: "Merk",
                    bullet: "dot",
                    page: "admin/top-merk"
                  }
                ]
              }
            ]
          },
          {
            title: "Umum",
            bullet: "dot",
            submenu: [
              {
                title: "Wilayah",
                bullet: "dot",
                submenu: [
                  {
                    title: "Propinsi",
                    bullet: "dot",
                    page: "admin/propinsi"
                  },
                  {
                    title: "Kabupaten",
                    bullet: "dot",
                    page: "admin/kabupaten"
                  },
                  {
                    title: "Kecamatan",
                    bullet: "dot",
                    page: "admin/kecamatan"
                  },
                  {
                    title: "Lurah",
                    bullet: "dot",
                    page: "admin/lurah"
                  },
                  {
                    title: "Alamat",
                    bullet: "dot",
                    page: "admin/alamat"
                  }
                ]
              },
              {
                title: "Kesatuan",
                bullet: "dot",
                submenu: [
                  {
                    title: "Pengguna Kelompok",
                    bullet: "dot",
                    page: "admin/pengguna-kelompok"
                  },
                  {
                    title: "Pengguna",
                    bullet: "dot",
                    page: "admin/pengguna"
                  },
                  {
                    title: "Area Service",
                    bullet: "dot",
                    page: "admin/area-service"
                  },
                  {
                    title: "Kotama",
                    bullet: "dot",
                    page: "admin/kotama"
                  },
                  {
                    title: "Satker",
                    bullet: "dot",
                    page: "admin/satker"
                  },
                  {
                    title: "Satyanis",
                    bullet: "dot",
                    page: "admin/satyanis"
                  },
                  {
                    title: "Top Satuan",
                    bullet: "dot",
                    page: "admin/top-satuan"
                  }
                ]
              }
            ]
          }
        ]
      },
      // { section: "Portal" },
      // {
      //   title: "CCTV",
      //   root: true,
      //   icon: "flaticon2-architecture-and-city",
      //   alignment: "left",
      //   page: "cctv",
      //   translate: "MENU.CCTV"
      // },
      { section: "Components" },
      {
        title: "Material UI",
        root: true,
        bullet: "dot",
        icon: "flaticon2-browser-2",
        submenu: [
          {
            title: "Layout",
            bullet: "dot",
            submenu: [
              {
                title: "Box",
                page: "google-material/layout/box"
              },
              {
                title: "Container",
                page: "google-material/layout/container"
              },
              {
                title: "Grid",
                page: "google-material/layout/grid"
              },
              {
                title: "Grid list",
                page: "google-material/layout/grid-list"
              },
              {
                title: "Hidden",
                page: "google-material/layout/hidden"
              }
            ]
          },
          {
            title: "Inputs",
            bullet: "dot",
            submenu: [
              {
                title: "Autocomplete",
                page: "google-material/inputs/autocomplete",
                permission: "accessToECommerceModule"
              },
              {
                title: "Buttons",
                page: "google-material/inputs/buttons"
              },
              {
                title: "Checkboxes",
                page: "google-material/inputs/checkboxes"
              },
              {
                title: "Pickers",
                page: "google-material/inputs/pickers"
              },
              {
                title: "Radio Buttons",
                page: "google-material/inputs/radio-buttons"
              },
              {
                title: "Selects",
                page: "google-material/inputs/selects"
              },
              {
                title: "Switches",
                page: "google-material/inputs/switches"
              },
              {
                title: "Text Fields",
                page: "google-material/inputs/text-fields"
              },
              {
                title: "Transfer List",
                page: "google-material/inputs/transfer-list"
              }
            ]
          },
          {
            title: "Navigation",
            bullet: "dot",
            submenu: [
              {
                title: "Bottom Navigation",
                page: "google-material/navigation/bottom-navigation"
              },
              {
                title: "Breadcrumbs",
                page: "google-material/navigation/breadcrumbs"
              },
              {
                title: "Drawers",
                page: "google-material/navigation/drawer"
              },
              {
                title: "Links",
                page: "google-material/navigation/links"
              },
              {
                title: "Menus",
                page: "google-material/navigation/menus"
              },
              {
                title: "Steppers",
                page: "google-material/navigation/steppers"
              },
              {
                title: "Tabs",
                page: "google-material/navigation/tabs"
              }
            ]
          },
          {
            title: "Surfaces",
            bullet: "dot",
            submenu: [
              {
                title: "App Bar",
                page: "google-material/surfaces/app-bar"
              },
              {
                title: "Paper",
                page: "google-material/surfaces/paper"
              },
              {
                title: "Cards",
                page: "google-material/surfaces/cards"
              },
              {
                title: "Expansion Panels",
                page: "google-material/surfaces/expansion-panels"
              }
            ]
          },
          {
            title: "Feedback",
            bullet: "dot",
            submenu: [
              {
                title: "Progress",
                page: "google-material/feedback/progress"
              },
              {
                title: "Dialogs",
                page: "google-material/feedback/dialogs"
              },
              {
                title: "Snackbars",
                page: "google-material/feedback/snackbars"
              }
            ]
          },
          {
            title: "Data Display",
            bullet: "dot",
            submenu: [
              {
                title: "Avatars",
                page: "google-material/data-displays/avatars"
              },
              {
                title: "Badges",
                page: "google-material/data-displays/badges"
              },
              {
                title: "Chips",
                page: "google-material/data-displays/chips"
              },
              {
                title: "Dividers",
                page: "google-material/data-displays/dividers"
              },
              {
                title: "Icons",
                page: "google-material/data-displays/icons"
              },
              {
                title: "Lists",
                page: "google-material/data-displays/lists"
              },
              {
                title: "Tables",
                page: "google-material/data-displays/tables"
              },
              {
                title: "Tooltips",
                page: "google-material/data-displays/tooltips"
              },
              {
                title: "Typography",
                page: "google-material/data-displays/typography"
              }
            ]
          },
          {
            title: "Utils",
            bullet: "dot",
            submenu: [
              {
                title: "Click Away Listener",
                page: "google-material/utils/click-away-listener"
              },
              {
                title: "Modal",
                page: "google-material/utils/modal"
              },
              {
                title: "No SSR",
                page: "google-material/utils/no-ssr"
              },
              {
                title: "Popover",
                page: "google-material/utils/popover"
              },
              {
                title: "Popper",
                page: "google-material/utils/popper"
              },
              {
                title: "Portal",
                page: "google-material/utils/portal"
              },
              {
                title: "Transitions",
                page: "google-material/utils/transitions"
              },
              {
                title: "useMediaQuery",
                page: "google-material/utils/use-media-query"
              }
            ]
          }
        ]
      },
      {
        title: "Bootstrap",
        root: true,
        bullet: "dot",
        icon: "flaticon2-digital-marketing",
        submenu: [
          {
            title: "Alerts",
            page: "react-bootstrap/alert"
          },
          {
            title: "Accordion",
            page: "react-bootstrap/accordion"
          },
          {
            title: "Badge",
            page: "react-bootstrap/badge"
          },
          {
            title: "Breadcrumb",
            page: "react-bootstrap/breadcrumb"
          },
          {
            title: "Buttons",
            page: "react-bootstrap/buttons"
          },
          {
            title: "Button Group",
            page: "react-bootstrap/button-group"
          },
          {
            title: "Cards",
            page: "react-bootstrap/cards"
          },
          {
            title: "Carousel",
            page: "react-bootstrap/carousel"
          },
          {
            title: "Dropdowns",
            page: "react-bootstrap/dropdowns"
          },
          {
            title: "Forms",
            page: "react-bootstrap/forms"
          },
          {
            title: "Input Group",
            page: "react-bootstrap/input-group"
          },
          {
            title: "Images",
            page: "react-bootstrap/images"
          },
          {
            title: "Figures",
            page: "react-bootstrap/figures"
          },
          {
            title: "Jumbotron",
            page: "react-bootstrap/jumbotron"
          },
          {
            title: "List group",
            page: "react-bootstrap/list-group"
          },
          {
            title: "Modal",
            page: "react-bootstrap/modal"
          },
          {
            title: "Navs",
            page: "react-bootstrap/navs"
          },
          {
            title: "Navbar",
            page: "react-bootstrap/navbar"
          },
          {
            title: "Overlays",
            page: "react-bootstrap/overlays"
          },
          {
            title: "Pagination",
            page: "react-bootstrap/pagination"
          },
          {
            title: "Popovers",
            page: "react-bootstrap/popovers"
          },
          {
            title: "Progress",
            page: "react-bootstrap/progress"
          },
          {
            title: "Spinners",
            page: "react-bootstrap/spinners"
          },
          {
            title: "Table",
            page: "react-bootstrap/table"
          },
          {
            title: "Tabs",
            page: "react-bootstrap/tabs"
          },
          {
            title: "Tooltips",
            page: "react-bootstrap/tooltips"
          },
          {
            title: "Toasts",
            page: "react-bootstrap/toasts"
          }
        ]
      }
    ]
  }
};
