import React, { useState } from "react"
import { useFormik } from "formik";
import * as Yup from 'yup';
import { connect } from "react-redux";
import { 
  FormattedMessage, 
  injectIntl 
} from "react-intl";
import clsx from "clsx";
import * as top_merk from "../../../store/ducks/admin/top-merk.duck";
import { doAdd, doUpdate } from "../../../crud/admin/top-merk.crud";
import AlertForm from "../../../components/AlertForm";
import { Modal } from "react-bootstrap";
import TextFieldGroup from "../../../components/form/TextFieldGroup";
import { validationErrorMessage } from "../../../../_metronic/utils/helper";

function Form(props) {
    const { show, closeModal, data, reloadData } = props;
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({open: false, type: 'success', message: ''})
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
      paddingRight: "2.5rem"
    });

    const enableLoading = () => {
      setLoading(true);
      setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
      setLoading(false);
      setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };

    const closeModalHandler = () => {
        closeModal();
        setAlert({open:false})
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            kd_merk: data ? data.kd_merk : '',
            nm_merk: data ? data.nm_merk : '',
            kelompok: data ? data.jenis.fungsi.komoditi.kelompok.id : '',
            komoditi: data ? data.jenis.fungsi.komoditi.id : '',
            fungsi: data ? data.jenis.fungsi.id : '',
            jenis: data ? data.jenis.id : ''
        },
        validationSchema: Yup.object({
            kd_merk: Yup.string()
              .max(2, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 2}} />)
              .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            nm_merk: Yup.string()
              .max(20, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 20}} />)
              .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            kelompok: Yup.number()
                .integer(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />)
                .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            komoditi: Yup.number()
                .integer(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />)
                .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            fungsi: Yup.number()
                .integer(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />)
                .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            jenis: Yup.number()
                .integer(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />)
                .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
        }),
        onSubmit: (values, { resetForm }) => {
            enableLoading();
            if(data === undefined){
                doAdd(values)
                .then(({ data }) => {
                    resetForm();
                    disableLoading();
                    reloadData();
                    setAlert({open: true, type: 'success', message: 'Tambah data berhasil.'})
                })
                .catch((err) => {
                    disableLoading();
                    let errorMessage = validationErrorMessage(err)
                    setAlert({open: true, type: 'danger', message: `Tambah data gagal. ${errorMessage}`})
                });
            } else {
                doUpdate(values, data.id)
                .then(({ data }) => {
                    disableLoading();
                    reloadData();
                    setAlert({open: true, type: 'success', message: 'Simpan data berhasil.'})
                })
                .catch((err) => {
                    disableLoading();
                    let errorMessage = validationErrorMessage(err)
                    setAlert({open: true, type: 'danger', message: `Simpan data gagal. ${errorMessage}`})
                });
            }
        }
    });

    return (
        <Modal
            size="md"
            show={show}
            onHide={() => closeModalHandler()}
            aria-labelledby="example-modal-sizes-title-md"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-md">
                    {data ? 'Edit' : 'Tambah'} data top merk
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                    noValidate={true}
                    autoComplete="off"
                    className="kt-form"
                    onSubmit={formik.handleSubmit}
                >
                    <AlertForm 
                        show={alert.open}
                        variant={alert.type}
                        msg={alert.message}
                        closeAlert={() => setAlert({open: false})}
                    />

                        <TextFieldGroup
                            select={true}
                            margin="normal"
                            label="Top Kelompok"
                            className="kt-width-full"
                            name="kelompok"
                            {...formik.getFieldProps('kelompok')}
                            onChange={(e) => {
                                formik.handleChange(e)
                                formik.setFieldValue('komoditi', '')
                                props.loadTopKomoditiOptions(e.target.value)
                            }}
                            errorText={formik.touched.kelompok && formik.errors.kelompok}
                            error={Boolean(formik.touched.kelompok && formik.errors.kelompok)}
                        >
                        <option>Pilih Kelompok</option>
                            { props.top_kelompok_options && props.top_kelompok_options.map(top_kelompok => (<option key={top_kelompok.id} value={top_kelompok.id}>{top_kelompok.nm_kelompok}</option>)) }
                        </TextFieldGroup>
                        <TextFieldGroup
                            select={true}
                            margin="normal"
                            label="Top Komoditi"
                            className="kt-width-full"
                            name="komoditi"
                            {...formik.getFieldProps('komoditi')}
                            errorText={formik.touched.komoditi && formik.errors.komoditi}
                            error={Boolean(formik.touched.komoditi && formik.errors.komoditi)}
                        >
                        <option>Pilih Komoditi</option>
                            { props.top_komoditi_options && props.top_komoditi_options.map(top_komoditi => (<option key={top_komoditi.id} value={top_komoditi.id}>{top_komoditi.nm_komoditi}</option>)) }
                        </TextFieldGroup>
                        <TextFieldGroup
                            select={true}
                            margin="normal"
                            label="Top Fungsi"
                            className="kt-width-full"
                            name="fungsi"
                            {...formik.getFieldProps('fungsi')}
                            errorText={formik.touched.fungsi && formik.errors.fungsi}
                            error={Boolean(formik.touched.fungsi && formik.errors.fungsi)}
                        >
                        <option>Pilih Fungsi</option>
                            { props.top_fungsi_options && props.top_fungsi_options.map(top_fungsi => (<option key={top_fungsi.id} value={top_fungsi.id}>{top_fungsi.nm_fungsi}</option>)) }
                        </TextFieldGroup>
                        <TextFieldGroup
                            select={true}
                            margin="normal"
                            label="Top Jenis"
                            className="kt-width-full"
                            name="jenis"
                            {...formik.getFieldProps('jenis')}
                            errorText={formik.touched.jenis && formik.errors.jenis}
                            error={Boolean(formik.touched.jenis && formik.errors.jenis)}
                        >
                        <option>Pilih Jenis</option>
                            { props.top_jenis_options && props.top_jenis_options.map(top_jenis => (<option key={top_jenis.id} value={top_jenis.id}>{top_jenis.nm_jenis}</option>)) }
                        </TextFieldGroup>
                        <TextFieldGroup
                            type="text"
                            label="Kode Top Merk"
                            margin="normal"
                            className="kt-width-full"
                            name="kd_merk"
                            {...formik.getFieldProps('kd_merk')}
                            errorText={formik.touched.kd_merk && formik.errors.kd_merk}
                            error={Boolean(formik.touched.kd_merk && formik.errors.kd_merk)}
                        />
                        <TextFieldGroup
                            type="text"
                            margin="normal"
                            label="Nama Top Merk"
                            className="kt-width-full"
                            name="nm_merk"
                            {...formik.getFieldProps('nm_merk')}
                            errorText={formik.touched.nm_merk && formik.errors.nm_merk}
                            error={Boolean(formik.touched.nm_merk && formik.errors.nm_merk)}
                        />

                    <div className="kt-login__actions">

                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={loading}
                        className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                            "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                        }
                        )}`}
                        style={loadingButtonStyle}
                    >
                        { data ? 'Update' : 'Simpan'}
                    </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default injectIntl(
    connect(
      top_merk.states,
      top_merk.actions
    )(Form)
  );
