import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import { put, takeLatest } from "redux-saga/effects";

export const actionTypes = {
  LIST_NYATA: "nyata/List",
  DETAIL_NYATA: "nyata/Detail",
};

const initialAuthState = {
  results: [],
  count: '',
  record: undefined
};

export const reducer = persistReducer(
    { storage, key: "sisfolog-nyata", whitelist: ["results", "count", "record"] },
    (state = initialAuthState, action) => {
      switch (action.type) {
        case actionTypes.LIST_NYATA: {
          const { results, count } = action.payload;
          return { ...state, results, count };
        }
        case actionTypes.DETAIL_NYATA: {
          const { record } = action.payload;
          return { ...state, record };
        }

        default:
          return state;
      }
    }
);

export const states = (state) => (
  {
    top_kelompok_options: state.global.top_kelompok_options,
    top_komoditi_options: state.global.top_komoditi_options,
    top_komoditi: state.global.top_komoditi,
    top_fungsi_options: state.global.top_fungsi_options,
    top_jenis_options: state.global.top_jenis_options,
    datum: state.nyata.results,
    count: state.nyata.count,
    data: state.nyata.record,
  }
);

export const actions = {
  getNyataData: (results, count) => ({ type: actionTypes.LIST_NYATA, payload: { results, count} }),
  getNyataDetail: (record) => ({ type: actionTypes.DETAIL_NYATA, payload: { record} })
};

export function* saga() {
  // yield takeLatest(actionTypes.Login, function* loginSaga() {
  //   console.log('worker login')
  //   yield put(actions.requestUser());
  // });

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   console.log('worker register')
  //   yield put(actions.requestUser());
  // });

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const { data: user } = yield getUserByToken();
  //   const respons = yield getUserByToken();
  //   console.log('worker user requested')
  //   console.log(user)
  //   console.log(respons)

  //   yield put(actions.fulfillUser(user));
  // });
}
