import React, { useState, useEffect } from 'react';
import CmbBidang from "../../ajax/combobox/CmbBidang";
import {
  Button,
  Modal,
  Col,
  Form
} from "react-bootstrap";
import { doUpdate } from "../../../crud/cctv.crud";
 export default function EditForm(props) {
   const [showform,setShowform]=useState(false);
   const [validated,setValidated]=useState(false);
   const [loading,setLoading]=useState(false);
   const items=props.formData;

   const updateHandler = (event) => {
     event.preventDefault();
     const form = event.currentTarget;
     const field = event.target.elements;

     if (form.checkValidity() === false) {
       event.stopPropagation();
     }else{
       var obj={
         lokasi:field.formLokasi.value,
         jumlah:field.formJumlah.value,
         lintang:field.formLintang.value,
         bujur:field.formBujur.value,
         idBidang:field.formBidang.value,
         idCctv:props.idData
       }

       doUpdate(obj).then(res => res.success)
       .then(
         (result) => {
           props.onFormChange({status:true,msg:"data berhasil diupdate",variant:'success'});
       },
       (error) => {
         props.onFormChange({status:false,msg:"data tidak berhasil diupdate",variant:'danger'});
       }
       )
       handleClose(false);
       }
       setValidated(true);
       }

        function handleClose() {
          props.closeForm(false);
        }

     return (
       <>
       <Modal show={props.modalVisible} onHide={handleClose}>
         <Modal.Header closeButton>
           <Modal.Title>Ubah Data</Modal.Title>
         </Modal.Header>
         <Form
         noValidate
         validated={validated}
           onSubmit={updateHandler}
         >
         <Modal.Body>
         {items.map((item,i) => (
           <>
             <Form.Group as={Col} md="12" controlId="formLokasi">
               <Form.Label>Lokasi</Form.Label>
               <Form.Control
                 required
                 defaultValue={item.lokasi}
                 type="text"
                 placeholder="Lokasi.."
               />
               <Form.Control.Feedback>Sudah sesuai..</Form.Control.Feedback>
               <Form.Control.Feedback type="invalid">
                 Data Belum diisi..
               </Form.Control.Feedback>
             </Form.Group>
             <Form.Group as={Col} md="12" controlId="formJumlah">
               <Form.Label>Jumlah</Form.Label>
               <Form.Control
                 required
                 defaultValue={item.jumlah}
                 type="text"
                 placeholder="Jumlah"
               />
               <Form.Control.Feedback>Sudah sesuai..</Form.Control.Feedback>
               <Form.Control.Feedback type="invalid">
                 Data Belum diisi..
               </Form.Control.Feedback>
             </Form.Group>
             <Form.Group as={Col} md="12" controlId="formBidang">
             <Form.Label>Nama Bidang</Form.Label>
             <Form.Control as="select">
             <CmbBidang idBidang={item.idBidang}></CmbBidang>
            </Form.Control>
             </Form.Group>
             <Form.Group as={Col} md="12" controlId="formLintang">
               <Form.Label>Lintang</Form.Label>
               <Form.Control
                 required
                 type="text"
                 defaultValue={item.lintang}
                 placeholder="Lintang.."
               />
               <Form.Control.Feedback>Sudah sesuai..</Form.Control.Feedback>
               <Form.Control.Feedback type="invalid">
                 Data Belum diisi..
               </Form.Control.Feedback>
             </Form.Group>
             <Form.Group as={Col} md="12" controlId="formBujur">
               <Form.Label>Bujur</Form.Label>
               <Form.Control
                 required
                 defaultValue={item.bujur}
                 type="text"
                 placeholder="Bujur.."
               />
               <Form.Control.Feedback>Sudah sesuai..</Form.Control.Feedback>
               <Form.Control.Feedback type="invalid">
                 Data Belum diisi..
               </Form.Control.Feedback>
             </Form.Group>
             </>
           ))}
         </Modal.Body>
         <Modal.Footer>
           <Button variant="secondary" onClick={handleClose}>
             Tutup
           </Button>
           <Button type="submit" variant="primary">
             Update Data
           </Button>
         </Modal.Footer>
          </Form>
       </Modal>
</>

     )
}
