import axios from "axios";
import { BASE_API_URL } from './config'

//const token='';\

//if(localStorage.getItem('persist:sisfolog-auth').token!=undefined){
var token="";
const storage=JSON.parse(localStorage.getItem('persist:sisfolog-auth'));
if(storage.token!=undefined){
 token=JSON.parse(storage.token);
}else {
  dataFail()
}
//}

const api = axios.create({
  baseURL:BASE_API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Token '+token
  }
});

export const SAVE_URL = "";
export const UPDATE_URL = "";
export const DELETE_URL = "";
export const LIST_URL = "/api/inventory/tktm-in/";
export const GET_DATA="";

// Set config defaults when creating the instance



export function doAdd(obj) {
  return api.post(SAVE_URL,obj);
}

export function doUpdate(obj) {
  return api.post(UPDATE_URL, obj);
}

export function doDelete(val) {
  return api.get(DELETE_URL+'/'+val);
}
export function getData(val) {
  return api.get(GET_DATA+'/'+val);
}

export function dataFail() {
return false;
}


export function doList(page,pageSize,sorted,filtered) {

  return api.get(LIST_URL,{
    params:{
      ordering:'tgl_update',
      search:'',
      page:page
    }
  })
}
