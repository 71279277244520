import React, { useMemo } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
  PortletFooter
} from "../../partials/content/Portlet";
import { metronic } from "../../../_metronic";
import { Table,Form,Col,Row,Badge,Pagination,Dropdown,Button } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";

import SearchFilter from "../../components/aset/SearchFilterAreaLayanan";
import {
  Link
} from "react-router-dom";
let active = 2;
let items = [];
for (let number = 1; number <= 5; number++) {
  items.push(
    <Pagination.Item key={number} active={number === active}>
      {number}
    </Pagination.Item>
  );
}

export default function List(props) {


  return (
    <>

    <div className="row">
      <div className="col-lg-12">
      <SearchFilter/>
      </div>
      </div>

    <div className="row">
      <div className="col-lg-3">

      <Portlet fluidHeight={true}>
        <PortletHeader
          title='FORKLIFT KOMATSU'

        />

        <PortletBody>
        <div className="row">

        <div className="col-lg-12">
        <div className="kt-widget4__pic" style={{textAlign:"center"}}>
          <img
            alt=""
            className="kt-widget4__img"
            src={toAbsoluteUrl("/media/products/product.jpg")}
          />
        </div>



          <Form.Group controlId="formName" as={Row}>
            <Form.Label column sm="5">NO REG</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue='1130-XVII'/>
            </Col>
            <Form.Label column sm="5">KOTAMA</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="KODAM XVII/CEN"/>
            </Col>
            <Form.Label column sm="5">SATKER</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="BEKANGDAM XVII/CEN"/>
            </Col>

            <Form.Label column sm="5">KOMODITI</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="RANMOR"/>
            </Col>

            <Form.Label column sm="5">FUNGSI:</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="RANSUS"/>
            </Col>

            <Form.Label column sm="5">KONDISI</Form.Label>
            <Col sm="7">
            <Badge variant="success">Baik</Badge>
            </Col>
          </Form.Group>



          </div>


          </div>



        </PortletBody>
        <PortletFooter style={{textAlign:"center"}}>
        <Link to={`/detail/aset/1130-XVII`} ><Button variant="secondary">Detail</Button></Link>
        </PortletFooter>
      </Portlet>

      </div>

      <div className="col-lg-3">
      <Portlet fluidHeight={true}>
        <PortletHeader
          title='SEPEDA MOTOR HONDA CS 1'

        />

        <PortletBody>
        <div className="row">

        <div className="col-lg-12">
        <div className="kt-widget4__pic" style={{textAlign:"center"}}>
          <img
            alt=""
            className="kt-widget4__img"
            src={toAbsoluteUrl("/media/products/honda.jpg")}
          />
        </div>



          <Form.Group controlId="formName" as={Row}>
            <Form.Label column sm="5">NO REG</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue='412312'/>
            </Col>
            <Form.Label column sm="5">KOTAMA</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="KODAM I/BB"/>
            </Col>
            <Form.Label column sm="5">SATKER</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="BEKANGDAM I/BB"/>
            </Col>

            <Form.Label column sm="5">KOMODITI</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="RANMOR"/>
            </Col>

            <Form.Label column sm="5">FUNGSI:</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="RANMIN"/>
            </Col>



            <Form.Label column sm="5">KONDISI</Form.Label>
            <Col sm="7">
            <Badge variant="success">Baik</Badge>
            </Col>
          </Form.Group>



          </div>


          </div>



        </PortletBody>
        <PortletFooter style={{textAlign:"center"}}>
        <Link to={`/detail/aset/1130-XVII`} ><Button variant="secondary">Detail</Button></Link>
        </PortletFooter>
      </Portlet>


      </div>


      <div className="col-lg-3">
      <Portlet fluidHeight={true}>
        <PortletHeader
          title='MITSHUBISI STRADA'

        />

        <PortletBody>
        <div className="row">

        <div className="col-lg-12">
        <div className="kt-widget4__pic" style={{textAlign:"center"}}>
          <img
            alt=""
            className="kt-widget4__img"
            src={toAbsoluteUrl("/media/products/strada.jpg")}
          />
        </div>



          <Form.Group controlId="formName" as={Row}>
            <Form.Label column sm="5">NO REG</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue='324112312'/>
            </Col>
            <Form.Label column sm="5">KOTAMA</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="KODAM II/SWJ"/>
            </Col>
            <Form.Label column sm="5">SATKER</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="BEKANGDAM II/SWJ"/>
            </Col>

            <Form.Label column sm="5">KOMODITI</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="RANMIN"/>
            </Col>

            <Form.Label column sm="5">FUNGSI:</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="JEEP"/>
            </Col>

            <Form.Label column sm="5">KONDISI</Form.Label>
            <Col sm="7">
            <Badge variant="success">Baik</Badge>
            </Col>
          </Form.Group>



          </div>


          </div>



        </PortletBody>
        <PortletFooter style={{textAlign:"center"}}>
        <Link to={`/detail/aset/1130-XVII`} ><Button variant="secondary">Detail</Button></Link>
        </PortletFooter>
      </Portlet>


      </div>



      <div className="col-lg-3">
      <Portlet fluidHeight={true}>
        <PortletHeader
          title='SENAPAN US M16A1 KAL 5,56 MM'

        />

        <PortletBody>
        <div className="row">

        <div className="col-lg-12">
        <div className="kt-widget4__pic" style={{textAlign:"center"}}>
          <img
            alt=""
            className="kt-widget4__img"
            src={toAbsoluteUrl("/media/products/senapan.png")}
          />
        </div>



          <Form.Group controlId="formName" as={Row}>
            <Form.Label column sm="5">NO REG</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue='5174764'/>
            </Col>
            <Form.Label column sm="5">KOTAMA</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="KODAM XVII/CEN"/>
            </Col>
            <Form.Label column sm="5">SATKER</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="PALDAM XVII/CEN"/>
            </Col>

            <Form.Label column sm="5">KOMODITI</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="SENJATA API "/>
            </Col>

            <Form.Label column sm="5">FUNGSI:</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="SISTA INFANTRI"/>
            </Col>

            <Form.Label column sm="5">KONDISI</Form.Label>
            <Col sm="7">
            <Badge variant="success">Baik</Badge>
            </Col>
          </Form.Group>



          </div>


          </div>



        </PortletBody>
        <PortletFooter style={{textAlign:"center"}}>
        <Link to={`/detail/aset/1130-XVII`} ><Button variant="secondary">Detail</Button></Link>
        </PortletFooter>
      </Portlet>


      </div>



      <div className="col-lg-3">
      <Portlet fluidHeight={true}>
        <PortletHeader
          title='PISTOL FN 46 KAL. 9 MM'

        />

        <PortletBody>
        <div className="row">

        <div className="col-lg-12">
        <div className="kt-widget4__pic" style={{textAlign:"center"}}>
          <img
            alt=""
            className="kt-widget4__img"
            src={toAbsoluteUrl("/media/products/pistol.jpg")}
          />
        </div>



          <Form.Group controlId="formName" as={Row}>
            <Form.Label column sm="5">NO REG</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue='3996'/>
            </Col>
            <Form.Label column sm="5">KOTAMA</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="KODAM XVII/CEN"/>
            </Col>
            <Form.Label column sm="5">SATKER</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="PALDAM XVII/CEN"/>
            </Col>

            <Form.Label column sm="5">KOMODITI</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="SENJATA API "/>
            </Col>

            <Form.Label column sm="5">FUNGSI:</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="SISTA INFANTRI"/>
            </Col>

            <Form.Label column sm="5">KONDISI</Form.Label>
            <Col sm="7">
            <Badge variant="success">Baik</Badge>
            </Col>
          </Form.Group>



          </div>


          </div>



        </PortletBody>
        <PortletFooter style={{textAlign:"center"}}>
        <Link to={`/detail/aset/1130-XVII`} ><Button variant="secondary">Detail</Button></Link>
        </PortletFooter>
      </Portlet>


      </div>


      <div className="col-lg-3">
      <Portlet fluidHeight={true}>
        <PortletHeader
          title='SENAPAN US M16A1 KAL 5,56 MM'

        />

        <PortletBody>
        <div className="row">

        <div className="col-lg-12">
        <div className="kt-widget4__pic" style={{textAlign:"center"}}>
          <img
            alt=""
            className="kt-widget4__img"
            src={toAbsoluteUrl("/media/products/senapan.png")}
          />
        </div>



          <Form.Group controlId="formName" as={Row}>
            <Form.Label column sm="5">NO REG</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue='94003394'/>
            </Col>
            <Form.Label column sm="5">KOTAMA</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="KODAM XIV/HSN"/>
            </Col>
            <Form.Label column sm="5">SATKER</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="PALDAM XIV/HSN"/>
            </Col>

            <Form.Label column sm="5">KOMODITI</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="SENJATA API "/>
            </Col>

            <Form.Label column sm="5">FUNGSI:</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="SISTA INFANTRI"/>
            </Col>

            <Form.Label column sm="5">KONDISI</Form.Label>
            <Col sm="7">
            <Badge variant="success">Baik</Badge>
            </Col>
          </Form.Group>



          </div>


          </div>



        </PortletBody>
        <PortletFooter style={{textAlign:"center"}}>
        <Link to={`/detail/aset/1130-XVII`} ><Button variant="secondary">Detail</Button></Link>
        </PortletFooter>
      </Portlet>


      </div>


      <div className="col-lg-3">
      <Portlet fluidHeight={true}>
        <PortletHeader
          title='	GENSET 3 KVA herkules'

        />

        <PortletBody>
        <div className="row">

        <div className="col-lg-12">
        <div className="kt-widget4__pic" style={{textAlign:"center"}}>
          <img
            alt=""
            className="kt-widget4__img"
            src={toAbsoluteUrl("/media/products/genset.jpg")}
          />
        </div>



          <Form.Group controlId="formName" as={Row}>
            <Form.Label column sm="5">NO REG</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue='4A 0343'/>
            </Col>
            <Form.Label column sm="5">KOTAMA</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="KOSTRAD"/>
            </Col>
            <Form.Label column sm="5">SATKER</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="PAL KOSTRAD"/>
            </Col>

            <Form.Label column sm="5">KOMODITI</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="ALZI"/>
            </Col>

            <Form.Label column sm="5">FUNGSI:</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="ALAT PERBEKALAN (PENYEDIAAN LISTRIK)"/>
            </Col>

            <Form.Label column sm="5">KONDISI</Form.Label>
            <Col sm="7">
            <Badge variant="success">Baik</Badge>
            </Col>
          </Form.Group>



          </div>


          </div>



        </PortletBody>
        <PortletFooter style={{textAlign:"center"}}>
        <Link to={`/detail/aset/1130-XVII`} ><Button variant="secondary">Detail</Button></Link>
        </PortletFooter>
      </Portlet>


      </div>




      <div className="col-lg-3">
      <Portlet fluidHeight={true}>
        <PortletHeader
          title='KOMPRESOR BENSIN HONDA'

        />

        <PortletBody>
        <div className="row">

        <div className="col-lg-12">
        <div className="kt-widget4__pic" style={{textAlign:"center"}}>
          <img
            alt=""
            className="kt-widget4__img"
            src={toAbsoluteUrl("/media/products/kompresor.png")}
          />
        </div>



          <Form.Group controlId="formName" as={Row}>
            <Form.Label column sm="5">NO REG</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue='	LWU-6502'/>
            </Col>
            <Form.Label column sm="5">KOTAMA</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="KOSTRAD"/>
            </Col>
            <Form.Label column sm="5">SATKER</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="PAL KOSTRAD"/>
            </Col>

            <Form.Label column sm="5">KOMODITI</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="ALTEKMEK"/>
            </Col>

            <Form.Label column sm="5">FUNGSI:</Form.Label>
            <Col sm="7">
            <Form.Control
            plaintext
            readOnly
            defaultValue="ALBENG STASIONER"/>
            </Col>

            <Form.Label column sm="5">KONDISI</Form.Label>
            <Col sm="7">
            <Badge variant="success">Baik</Badge>
            </Col>
          </Form.Group>



          </div>


          </div>



        </PortletBody>
        <PortletFooter style={{textAlign:"center"}}>
        <Link to={`/detail/aset/1130-XVII`} ><Button variant="secondary">Detail</Button></Link>
        </PortletFooter>
      </Portlet>


      </div>

    </div>
    <div className="row">

    <div className="col-lg-8">
     <Pagination>{items}</Pagination>
    </div>
    <div className="col-lg-4" style={{textAlign:"right"}}>
      150 Total Data
    </div>
    </div>




    </>
  );
}
