import React, { useState, useRef, useEffect } from "react";
// import { Link } from "react-router-dom";
import ReactTable from "react-table";
import { connect } from "react-redux";
import { 
  // FormattedMessage, 
  injectIntl 
} from "react-intl";
import { Portlet, PortletHeader, PortletHeaderToolbar, PortletBody } from "../../../partials/content/Portlet";
import * as lurah from "../../../store/ducks/admin/lurah.duck";
import * as global from "../../../store/ducks/global.duck";
import { doList as doListPropinsi } from "../../../crud/admin/propinsi.crud";
import { doList as doListKabupaten } from "../../../crud/admin/kabupaten.crud";
import { doList as doListKecamatan } from "../../../crud/admin/kecamatan.crud";
import { doList, doDelete } from "../../../crud/admin/lurah.crud";
import ConfirmDelete from "../../../components/ConfirmDelete";
import AlertForm from "../../../components/AlertForm";
import Form from "./Form";
import { Button, InputBase, Divider, IconButton, Typography, Grid, Card, CardContent } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TextFieldGroup from "../../../components/form/TextFieldGroup";

const useStyles = makeStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 8,
  },
});

function Lurah(props) {
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState({
      propinsi: '',
      kabupaten: '',
      kecamatan: ''
    });
    const [confirmDelete, setConfirmDelete] = useState({
      open: false,
      id: null
    });
    const [form, setForm] = useState({
      open: false,
      data: null
    });
    const [alert, setAlert] = useState({open: false, type: 'success', message: ''})
    const [totalpages, setTotalpages] = useState(1);
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
      paddingRight: "2.5rem"
    });

    const reactTable = useRef();
    const classes = useStyles();

    const enableLoading = () => {
      setLoading(true);
      setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
      setLoading(false);
      setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };

    const reloadTable = () => reactTable.current && reactTable.current.fireFetchData()
    
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        reloadTable()
      }
    }

    function fetchData(state) {
      enableLoading();
      const ordering = state.sorted.map(next => ((next.desc ? '-' : '') + next.id)).join(',')
      const params = {
        kecamatan__kabupaten__propinsi__id: filter.propinsi !== 'all' ? filter.propinsi : '',
        kecamatan__kabupaten__id: filter.kabupaten !== 'all' ? filter.kabupaten : '',
        kecamatan__id: filter.kecamatan !== 'all' ? filter.kecamatan : '',
        ordering: ordering ? ordering : '-nm_lurah',
        search: search,
        page: state.page + 1,
        page_size: state.pageSize
      }
      doList(params)
        .then(({ data: { results, count } }) => {
            disableLoading();
            let totalpage=Math.ceil(parseInt(count)/parseInt(state.pageSize));
            setTotalpages(totalpage)
            props.getLurahData(results, count);
          }
        ).catch(error => {
          disableLoading();
        })
    }

    function handleDelete() {
      setConfirmDelete({open:false})
      setAlert({open: false})
      enableLoading();
      doDelete(confirmDelete.id).then(response => {
        setAlert({open: true, type: 'success', message: 'Hapus data berhasil.'})
        disableLoading();
        reloadTable();
      }).catch(error => {
        setAlert({open: true, type: 'danger', message: 'Hapus data gagal.'})
        disableLoading();
      })
    }
    
    const loadPropinsiOptions = () => {
      doListPropinsi({ordering: 'nm_propinsi', page_size: 9999999})
          .then(({ data: { results } }) => {
              props.getPropinsiOptions(results);
          }
      )
    }

    const loadKabupatenOptions = (id_propinsi) => {
      doListKabupaten({ordering: 'nm_kabupaten', propinsi__id: id_propinsi, page_size: 9999999})
          .then(({ data: { results } }) => {
              props.getKabupatenOptions(results);
          }
      )
    }

    const loadKecamatanOptions = (id_kabupaten) => {
      doListKecamatan({ordering: 'nm_kecamatan', kabupaten__id: id_kabupaten, page_size: 9999999})
          .then(({ data: { results } }) => {
              props.getKecamatanOptions(results);
          }
      )
    }

    useEffect(() => {
      loadPropinsiOptions()
      // eslint-disable-next-line
    }, []);

    const columns = [
      {
        Header: "Propinsi",
        accessor: "kecamatan.kabupaten.propinsi.nm_propinsi",
      },
      {
        Header: "Kabupaten",
        accessor: "kecamatan.kabupaten.nm_kabupaten",
      },
      {
        Header: "Kecamatan",
        accessor: "kecamatan.nm_kecamatan"
      },
      {
        Header: "Kode Lurah",
        accessor: "kd_lurah"
      },
      {
        Header: "Nama Lurah",
        accessor: "nm_lurah"
      },
      {
        Header: "Kode Pos",
        accessor: "kodepos"
      },
      {
        Header: "Aksi",
        accessor: "id",
        Cell: ({ original }) => (
          <div style={{ textAlign: "center" }}>
            <IconButton onClick={(e) => {
              loadKabupatenOptions(original.kecamatan.kabupaten.propinsi.id)
              loadKecamatanOptions(original.kecamatan.kabupaten.id)
              setForm({open: true, data: original})
            }} aria-label="Edit" size="small">
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton onClick={(e) => setConfirmDelete({open: true, id: original.id})} aria-label="Delete" size="small">
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </div>
        )
      }
    ]

    return (
      <Portlet>
        <PortletHeader
          title={'Lurah'}
          toolbar={(
              <PortletHeaderToolbar>
                <InputBase
                  className={classes.input}
                  placeholder="Cari Lurah"
                  inputProps={{ 'aria-label': 'Cari Lurah' }}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e)}
                />
                <IconButton className={classes.iconButton} aria-label="Search" onClick={() => reloadTable()}>
                  <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} />
                <Button variant="contained" color="secondary" onClick={() => setForm({open: true})}><i className="kt-nav__link-icon flaticon2-add" /> Tambah</Button>
              </PortletHeaderToolbar>
            )
          }
        />
        <PortletBody>
          <ConfirmDelete
            handleClose={() => setConfirmDelete({open:false})}
            handleDelete={() => handleDelete()}
            show={confirmDelete.open}
          />
          <AlertForm 
            show={alert.open}
            variant={alert.type}
            msg={alert.message}
            closeAlert={() => setAlert({open: false})}
          />
          <Card>
            <CardContent>
              <Typography variant="h6" component="h6">
                Filter
              </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <TextFieldGroup
                      select={true}
                      margin="normal"
                      label="Propinsi"
                      className="kt-width-full"
                      onChange={(e) => {
                        setFilter({...filter, propinsi: e.target.value})
                        loadKabupatenOptions(e.target.value)
                      }}
                      value={filter.propinsi}
                    >
                      <option key={0} value="all">Semua</option>
                      { props.propinsi_options && props.propinsi_options.map(propinsi => (<option key={propinsi.id} value={propinsi.id}>{propinsi.nm_propinsi}</option>)) }
                    </TextFieldGroup>
                    <button
                      disabled={loading}
                      className={`btn btn-primary btn-elevate btn-sm ${clsx(
                      {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                      )}`}
                      style={loadingButtonStyle}
                      onClick={() => reloadTable()}
                    >
                    Filter
                  </button>
                </Grid>
                <Grid item xs={4}>
                  <TextFieldGroup
                      select={true}
                      margin="normal"
                      label="Kabupaten"
                      className="kt-width-full"
                      onChange={(e) => {
                        setFilter({...filter, kabupaten: e.target.value})
                        loadKecamatanOptions(e.target.value)
                      }}
                      value={filter.kabupaten}
                  >
                    <option key={0} value="all">Semua</option>
                    { props.kabupaten_options && props.kabupaten_options.map(kabupaten => (<option key={kabupaten.id} value={kabupaten.id}>{kabupaten.nm_kabupaten}</option>)) }
                  </TextFieldGroup>
                </Grid>
                <Grid item xs={4}>
                  <TextFieldGroup
                      select={true}
                      margin="normal"
                      label="Kecamatan"
                      className="kt-width-full"
                      onChange={(e) => setFilter({...filter, kecamatan: e.target.value})}
                      value={filter.kecamatan}
                  >
                    <option key={0} value="all">Semua</option>
                    { props.kecamatan_options && props.kecamatan_options.map(kecamatan => (<option key={kecamatan.id} value={kecamatan.id}>{kecamatan.nm_kecamatan}</option>)) }
                  </TextFieldGroup>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <br/>
          <ReactTable
            ref={reactTable}
            data={props.datum}
            pages={totalpages}
            columns={columns}
            defaultPageSize={10}
            className="-striped -highlight"
            loading={loading}
            showPagination={true}
            pageSizeOptions={[10, 20, 25, 50, 100]}
            manual // this would indicate that server side pagination has been enabled
            onFetchData={fetchData}
          />
          <Form
            show={form.open}
            closeModal={() => setForm({open: false, data: null})}
            data={form.data}
            reloadData={() => reloadTable()}
            loadPropinsiOptions={() => loadPropinsiOptions()}
            loadKabupatenOptions={(id_propinsi) => loadKabupatenOptions(id_propinsi)}
            loadKecamatanOptions={(id_kabupaten) => loadKecamatanOptions(id_kabupaten)}
          />
        </PortletBody>
      </Portlet>
    )
}

const { getPropinsiOptions, getKabupatenOptions, getKecamatanOptions } = global.actions

export default injectIntl(
    connect(
      lurah.states,
      { ...lurah.actions, getPropinsiOptions, getKabupatenOptions, getKecamatanOptions }
    )(Lurah)
  );
