import React, { useState, useEffect } from "react"
import { useFormik } from "formik";
import * as Yup from 'yup';
import { connect } from "react-redux";
import { 
  FormattedMessage, 
  injectIntl 
} from "react-intl";
import { Grid } from "@material-ui/core";
import clsx from "clsx";
import * as satker from "../../../store/ducks/admin/satker.duck";
import * as global from "../../../store/ducks/global.duck";
import { doAdd, doUpdate } from "../../../crud/admin/satker.crud";
import { doList as doListKotama } from "../../../crud/admin/kotama.crud";
import { doList as doListTopSatuan } from "../../../crud/admin/top-satuan.crud";
import AlertForm from "../../../components/AlertForm";
import { Modal } from "react-bootstrap";
import TextFieldGroup from "../../../components/form/TextFieldGroup";
import { validationErrorMessage } from "../../../../_metronic/utils/helper";

function Form(props) {
    const { show, closeModal, data, reloadData } = props;
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({open: false, type: 'success', message: ''})
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
      paddingRight: "2.5rem"
    });

    const enableLoading = () => {
      setLoading(true);
      setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
      setLoading(false);
      setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };

    const closeModalHandler = () => {
        closeModal();
        setAlert({open:false})
    };

    const loadKotamaOptions = () => {
      doListKotama({ordering: 'nm_kotama', page_size: 9999999})
        .then(({ data: { results } }) => {
          props.getKotamaOptions(results);
        }
      )
    }

    const loadTopSatuanOptions = () => {
      doListTopSatuan({ordering: 'nm_top_satuan', page_size: 9999999})
        .then(({ data: { results } }) => {
          props.getTopSatuanOptions(results);
        }
      )
    }

    useEffect(() => {
      loadKotamaOptions()
      loadTopSatuanOptions()
      // eslint-disable-next-line
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            kd_satpor: data ? data.kd_satpor : '',
            nm_satpor: data ? data.nm_satpor : '',
            kd_area: data ? data.kd_area : '',
            gudang: data ? data.gudang : '',
            kd_satker: data ? data.kd_satker : '',
            kd_subsatker: data ? data.kd_subsatker : '',
            kemenkeu: data ? data.kemenkeu : '',
            kd_uniq: data ? data.kd_uniq : '',
            alamat: data ? data.alamat : '',
            latitude: data ? data.latitude : '',
            longitude: data ? data.longitude : '',
            kotama: data ? data.kotama.id : '',
            top_satuan: data ? data.top_satuan.id : ''
        },
        validationSchema: Yup.object({
            kd_satpor: Yup.string()
              .max(2, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 2}} />)
              .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            nm_satpor: Yup.string()
              .max(20, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 20}} />)
              .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            kd_area: Yup.string()
              .max(2, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 2}} />)
              .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            gudang: Yup.string()
              .max(20, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 20}} />)
              .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            kd_satker: Yup.string()
              .max(2, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 2}} />)
              .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            kd_subsatker: Yup.string()
              .max(20, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 20}} />)
              .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            kemenkeu: Yup.string()
              .max(20, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 20}} />)
              .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            kd_uniq: Yup.string()
              .max(20, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 20}} />)
              .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            alamat: Yup.string()
              .max(20, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 20}} />)
              .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            latitude: Yup.string()
              .max(20, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 20}} />)
                .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            longitude: Yup.string()
                .max(20, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 20}} />)
                .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            kotama: Yup.number()
              .integer(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />)
              .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            top_satuan: Yup.number()
              .integer(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />)
              .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />)
        }),
        onSubmit: (values, { resetForm }) => {
            enableLoading();
            if(data === undefined){
                doAdd(values)
                .then(({ data }) => {
                    resetForm();
                    disableLoading();
                    reloadData();
                    setAlert({open: true, type: 'success', message: 'Tambah data berhasil.'})
                })
                .catch((err) => {
                    disableLoading();
                    let errorMessage = validationErrorMessage(err)
                    setAlert({open: true, type: 'danger', message: `Tambah data gagal. ${errorMessage}`})
                });
            } else {
                doUpdate(values, data.id)
                .then(({ data }) => {
                    disableLoading();
                    reloadData();
                    setAlert({open: true, type: 'success', message: 'Simpan data berhasil.'})
                })
                .catch((err) => {
                    disableLoading();
                    let errorMessage = validationErrorMessage(err)
                    setAlert({open: true, type: 'danger', message: `Simpan data gagal. ${errorMessage}`})
                });
            }
        }
    });

    return (
        <Modal
            size="lg"
            show={show}
            onHide={() => closeModalHandler()}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    {data ? 'Edit' : 'Tambah'} data satker
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                    noValidate={true}
                    autoComplete="off"
                    className="kt-form"
                    onSubmit={formik.handleSubmit}
                >
                    <AlertForm 
                        show={alert.open}
                        variant={alert.type}
                        msg={alert.message}
                        closeAlert={() => setAlert({open: false})}
                    />
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                                <TextFieldGroup
                                    select={true}
                                    margin="normal"
                                    label="Kotama"
                                    className="kt-width-full"
                                    name="kotama"
                                    {...formik.getFieldProps('kotama')}
                                    errorText={formik.touched.kotama && formik.errors.kotama}
                                    error={Boolean(formik.touched.kotama && formik.errors.kotama)}
                                >
                                    <option>Pilih Kotama</option>
                                    { props.kotama_options && props.kotama_options.map(kotama => (<option key={kotama.id} value={kotama.id}>{kotama.nm_kotama}</option>)) }
                                </TextFieldGroup>
                                <TextFieldGroup
                                    select={true}
                                    margin="normal"
                                    label="Top Satuan"
                                    className="kt-width-full"
                                    name="top_satuan"
                                    {...formik.getFieldProps('top_satuan')}
                                    errorText={formik.touched.top_satuan && formik.errors.top_satuan}
                                    error={Boolean(formik.touched.top_satuan && formik.errors.top_satuan)}
                                >
                                    <option>Pilih Top Stuan</option>
                                    { props.top_satuan_options && props.top_satuan_options.map(top_satuan => (<option key={top_satuan.id} value={top_satuan.id}>{top_satuan.nm_top_satuan}</option>)) }
                                </TextFieldGroup>
                                <TextFieldGroup
                                    type="text"
                                    label="Kode Satpor"
                                    margin="normal"
                                    className="kt-width-full"
                                    name="kd_satpor"
                                    {...formik.getFieldProps('kd_satpor')}
                                    errorText={formik.touched.kd_satpor && formik.errors.kd_satpor}
                                    error={Boolean(formik.touched.kd_satpor && formik.errors.kd_satpor)}
                                />
                                <TextFieldGroup
                                    type="text"
                                    margin="normal"
                                    label="Nama Satpor"
                                    className="kt-width-full"
                                    name="nm_satpor"
                                    {...formik.getFieldProps('nm_satpor')}
                                    errorText={formik.touched.nm_satpor && formik.errors.nm_satpor}
                                    error={Boolean(formik.touched.nm_satpor && formik.errors.nm_satpor)}
                                />
                                <TextFieldGroup
                                    type="text"
                                    margin="normal"
                                    label="Kode Area"
                                    className="kt-width-full"
                                    name="kd_area"
                                    {...formik.getFieldProps('kd_area')}
                                    errorText={formik.touched.kd_area && formik.errors.kd_area}
                                    error={Boolean(formik.touched.kd_area && formik.errors.kd_area)}
                                />
                                <TextFieldGroup
                                    select={true}
                                    type="text"
                                    margin="normal"
                                    label="Gudang"
                                    className="kt-width-full"
                                    name="gudang"
                                    {...formik.getFieldProps('gudang')}
                                    errorText={formik.touched.gudang && formik.errors.gudang}
                                    error={Boolean(formik.touched.gudang && formik.errors.gudang)}
                                >
                                    <option value={0}>Bukan</option>
                                    <option value={1}>Ya</option>
                                </TextFieldGroup>
                        </Grid>
                        <Grid item xs={6}>
                                <TextFieldGroup
                                    type="text"
                                    label="Kode Satker"
                                    margin="normal"
                                    className="kt-width-full"
                                    name="kd_satker"
                                    {...formik.getFieldProps('kd_satker')}
                                    errorText={formik.touched.kd_satker && formik.errors.kd_satker}
                                    error={Boolean(formik.touched.kd_satker && formik.errors.kd_satker)}
                                />
                                <TextFieldGroup
                                    type="text"
                                    label="Kode Sub Satker"
                                    margin="normal"
                                    className="kt-width-full"
                                    name="kd_subsatker"
                                    {...formik.getFieldProps('kd_subsatker')}
                                    errorText={formik.touched.kd_subsatker && formik.errors.kd_subsatker}
                                    error={Boolean(formik.touched.kd_subsatker && formik.errors.kd_subsatker)}
                                />
                                <TextFieldGroup
                                    type="text"
                                    label="Kemenkeu"
                                    margin="normal"
                                    className="kt-width-full"
                                    name="kemenkeu"
                                    {...formik.getFieldProps('kemenkeu')}
                                    errorText={formik.touched.kemenkeu && formik.errors.kemenkeu}
                                    error={Boolean(formik.touched.kemenkeu && formik.errors.kemenkeu)}
                                />
                                <TextFieldGroup
                                    type="text"
                                    label="Kode Unique"
                                    margin="normal"
                                    className="kt-width-full"
                                    name="kd_uniq"
                                    {...formik.getFieldProps('kd_uniq')}
                                    errorText={formik.touched.kd_uniq && formik.errors.kd_uniq}
                                    error={Boolean(formik.touched.kd_uniq && formik.errors.kd_uniq)}
                                />
                                <TextFieldGroup
                                    type="text"
                                    label="Alamat"
                                    margin="normal"
                                    className="kt-width-full"
                                    name="alamat"
                                    {...formik.getFieldProps('alamat')}
                                    errorText={formik.touched.alamat && formik.errors.alamat}
                                    error={Boolean(formik.touched.alamat && formik.errors.alamat)}
                                />
                                <TextFieldGroup
                                    type="text"
                                    margin="normal"
                                    label="Latitude"
                                    className="kt-width-full"
                                    name="latitude"
                                    {...formik.getFieldProps('latitude')}
                                    errorText={formik.touched.latitude && formik.errors.latitude}
                                    error={Boolean(formik.touched.latitude && formik.errors.latitude)}
                                />
                                <TextFieldGroup
                                    type="text"
                                    margin="normal"
                                    label="Longitude"
                                    className="kt-width-full"
                                    name="longitude"
                                    {...formik.getFieldProps('longitude')}
                                    errorText={formik.touched.longitude && formik.errors.longitude}
                                    error={Boolean(formik.touched.longitude && formik.errors.longitude)}
                                />
                        </Grid>
                    </Grid>
                    <div className="kt-login__actions">

                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={loading}
                        className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                            "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                        }
                        )}`}
                        style={loadingButtonStyle}
                    >
                        { data ? 'Update' : 'Simpan'}
                    </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

const { getKotamaOptions, getTopSatuanOptions } = global.actions

export default injectIntl(
    connect(
      satker.states,
      { ...satker.actions, getKotamaOptions, getTopSatuanOptions }
    )(Form)
  );
