import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import { put, takeLatest } from "redux-saga/effects";

export const actionTypes = {
  SET_PENGGUNA_KELOMPOK_OPTIONS: "global/PenggunaKelompok/Options",
  SET_PENGGUNA_OPTIONS: "global/Pengguna/Options",
  SET_PROPINSI_OPTIONS: "global/Propinsi/Options",
  SET_KABUPATEN_OPTIONS: "global/Kabupaten/Options",
  SET_KECAMATAN_OPTIONS: "global/Kecamatan/Options",
  SET_LURAH_OPTIONS: "global/Lurah/Options",
  SET_ALAMAT_OPTIONS: "global/Alamat/Options",
  SET_AREA_SERVICE_OPTIONS: "global/AreaService/Options",
  SET_KOTAMA_OPTIONS: "global/Kotama/Options",
  SET_SATKER_OPTIONS: "global/Satker/Options",
  SET_KOTAMA: "global/Kotama",
  SET_SATYANIS_OPTIONS: "global/Satyanis/Options",
  SET_SATYANIS: "global/Satyanis",
  SET_TABLE_OPTIONS: "global/Table/Options",
  SET_TABLE_OPTION_OPTIONS: "global/TableOption/Options",
  SET_TOP_SATUAN_OPTIONS: "global/TopSatuan/Options",
  SET_TOP_SATUAN: "global/TopSatuan",
  SET_TOP_KELOMPOK_OPTIONS: "global/TopKelompok/Options",
  SET_TOP_KOMODITI_OPTIONS: "global/TopKomoditi/Options",
  SET_TOP_KOMODITI: "global/TopKomoditi",
  SET_TOP_FUNGSI_OPTIONS: "global/TopFungsi/Options",
  SET_TOP_JENIS_OPTIONS: "global/TopJenis/Options",
  SET_TOP_MERK_OPTIONS: "global/TopMerk/Options",
};

const initialAuthState = {
  pengguna_kelompok_options: [],
  pengguna_options: [],
  propinsi_options: [],
  kabupaten_options: [],
  kecamatan_options: [],
  lurah_options: [],
  alamat_options: [],
  area_service_options: [],
  satker_options: [],
  kotama_options: [],
  kotama: '',
  satyanis_options: [],
  satyanis: '',
  table_options: [],
  table_option_options: [],
  top_satuan_options: [],
  top_satuan: '',
  top_kelompok_options: [],
  top_komoditi_options: [],
  top_komoditi: '',
  top_fungsi_options: [],
  top_jenis_options: [],
  top_merk_options: [],
};

export const reducer = persistReducer(
    { storage, key: "sisfolog-global", whitelist: ["kotama_options", "kotama", "top_satuan_options", "top_satuan", "top_kelompok_options", "top_komoditi_options", "top_fungsi_options", "top_jenis_options", "top_merk_options"] },
    (state = initialAuthState, action) => {
      switch (action.type) {
        case actionTypes.SET_PENGGUNA_KELOMPOK_OPTIONS: {
          const { pengguna_kelompok_options } = action.payload;
          return { ...state, pengguna_kelompok_options };
        }
        case actionTypes.SET_PENGGUNA_OPTIONS: {
          const { pengguna_options } = action.payload;
          return { ...state, pengguna_options };
        }
        case actionTypes.SET_PROPINSI_OPTIONS: {
          const { propinsi_options } = action.payload;
          return { ...state, propinsi_options };
        }
        case actionTypes.SET_KABUPATEN_OPTIONS: {
          const { kabupaten_options } = action.payload;
          return { ...state, kabupaten_options };
        }
        case actionTypes.SET_KECAMATAN_OPTIONS: {
          const { kecamatan_options } = action.payload;
          return { ...state, kecamatan_options };
        }
        case actionTypes.SET_LURAH_OPTIONS: {
          const { lurah_options } = action.payload;
          return { ...state, lurah_options };
        }
        case actionTypes.SET_ALAMAT_OPTIONS: {
          const { alamat_options } = action.payload;
          return { ...state, alamat_options };
        }
        case actionTypes.SET_SATKER_OPTIONS: {
          const { satker_options } = action.payload;
          return { ...state, satker_options };
        }
        case actionTypes.SET_AREA_SERVICE_OPTIONS: {
          const { area_service_options } = action.payload;
          return { ...state, area_service_options };
        }
        case actionTypes.SET_KOTAMA: {
          const { kotama } = action.payload;
          return { ...state, kotama };
        }
        case actionTypes.SET_KOTAMA_OPTIONS: {
          const { kotama_options } = action.payload;
          return { ...state, kotama_options };
        }
        case actionTypes.SET_SATYANIS: {
          const { satyanis } = action.payload;
          return { ...state, satyanis };
        }
        case actionTypes.SET_SATYANIS_OPTIONS: {
          const { satyanis_options } = action.payload;
          return { ...state, satyanis_options };
        }
        case actionTypes.SET_TABLE_OPTIONS: {
          const { table_options } = action.payload;
          return { ...state, table_options };
        }
        case actionTypes.SET_TABLE_OPTION_OPTIONS: {
          const { table_option_options } = action.payload;
          return { ...state, table_option_options };
        }
        case actionTypes.SET_TOP_SATUAN: {
          const { top_satuan } = action.payload;
          return { ...state, top_satuan };
        }
        case actionTypes.SET_TOP_SATUAN_OPTIONS: {
          const { top_satuan_options } = action.payload;
          return { ...state, top_satuan_options };
        }
        case actionTypes.SET_TOP_KELOMPOK_OPTIONS: {
          const { top_kelompok_options } = action.payload;
          return { ...state, top_kelompok_options };
        }
        case actionTypes.SET_TOP_KOMODITI_OPTIONS: {
          const { top_komoditi_options } = action.payload;
          return { ...state, top_komoditi_options };
        }
        case actionTypes.SET_TOP_KOMODITI: {
          const { top_komoditi } = action.payload;
          return { ...state, top_komoditi };
        }
        case actionTypes.SET_TOP_FUNGSI_OPTIONS: {
          const { top_fungsi_options } = action.payload;
          return { ...state, top_fungsi_options };
        }
        case actionTypes.SET_TOP_JENIS_OPTIONS: {
          const { top_jenis_options } = action.payload;
          return { ...state, top_jenis_options };
        }
        case actionTypes.SET_TOP_MERK_OPTIONS: {
          const { top_merk_options } = action.payload;
          return { ...state, top_merk_options };
        }
        default:
          return state;
      }
    }
);

export const states = (state) => (
  {
    pengguna_kelompok_options: state.global.pengguna_kelompok_options,
    pengguna_options: state.global.pengguna_options,
    propinsi_options: state.global.propinsi_options,
    kabupaten_options: state.global.kabupaten_options,
    kecamatan_options: state.global.kecamatan_options,
    lurah_options: state.global.lurah_options,
    alamat_options: state.global.alamat_options,
    kotama_options: state.global.kotama_options,
    satker_options: state.global.satker_options,
    area_service_options: state.global.area_service_options,
    kotama: state.global.kotama,
    satyanis_options: state.global.satyanis_options,
    satyanis: state.global.satyanis,
    table_options: state.global.table_options,
    table_option_options: state.global.table_option_options,
    top_satuan_options: state.global.top_satuan_options,
    top_satuan: state.global.top_satuan,
    top_kelompok_options: state.global.top_kelompok_options,
    top_komoditi_options: state.global.top_komoditi_options,
    top_fungsi_options: state.global.top_fungsi_options,
    top_jenis_options: state.global.top_jenis_options,
    top_merk_options: state.global.top_merk_options,
  }
);

export const actions = {
  getPenggunaKelompokOptions: (pengguna_kelompok_options) => ({ type: actionTypes.SET_PENGGUNA_KELOMPOK_OPTIONS, payload: { pengguna_kelompok_options } }),
  getPenggunaOptions: (pengguna_options) => ({ type: actionTypes.SET_PENGGUNA_OPTIONS, payload: { pengguna_options } }),
  getPropinsiOptions: (propinsi_options) => ({ type: actionTypes.SET_PROPINSI_OPTIONS, payload: { propinsi_options } }),
  getKabupatenOptions: (kabupaten_options) => ({ type: actionTypes.SET_KABUPATEN_OPTIONS, payload: { kabupaten_options } }),
  getKecamatanOptions: (kecamatan_options) => ({ type: actionTypes.SET_KECAMATAN_OPTIONS, payload: { kecamatan_options } }),
  getLurahOptions: (lurah_options) => ({ type: actionTypes.SET_LURAH_OPTIONS, payload: { lurah_options } }),
  getAlamatOptions: (alamat_options) => ({ type: actionTypes.SET_ALAMAT_OPTIONS, payload: { alamat_options } }),
  getSatkerOptions: (satker_options) => ({ type: actionTypes.SET_SATKER_OPTIONS, payload: { satker_options } }),
  getAreaServiceOptions: (area_service_options) => ({ type: actionTypes.SET_AREA_SERVICE_OPTIONS, payload: { area_service_options } }),
  getKotamaOptions: (kotama_options) => ({ type: actionTypes.SET_KOTAMA_OPTIONS, payload: { kotama_options } }),
  getKotama: (kotama) => ({ type: actionTypes.SET_KOTAMA, payload: { kotama } }),
  getSatyanisOptions: (satyanis_options) => ({ type: actionTypes.SET_SATYANIS_OPTIONS, payload: { satyanis_options } }),
  getSatyanis: (satyanis) => ({ type: actionTypes.SET_SATYANIS, payload: { satyanis } }),
  getTableOptions: (table_options) => ({ type: actionTypes.SET_TABLE_OPTIONS, payload: { table_options } }),
  getTableOptionOptions: (table_option_options) => ({ type: actionTypes.SET_TABLE_OPTION_OPTIONS, payload: { table_option_options } }),
  getTopSatuanOptions: (top_satuan_options) => ({ type: actionTypes.SET_TOP_SATUAN_OPTIONS, payload: { top_satuan_options } }),
  getTopSatuan: (top_satuan) => ({ type: actionTypes.SET_TOP_SATUAN, payload: { top_satuan } }),
  getTopKelompokOptions: (top_kelompok_options) => ({ type: actionTypes.SET_TOP_KELOMPOK_OPTIONS, payload: { top_kelompok_options } }),
  getTopKomoditiOptions: (top_komoditi_options) => ({ type: actionTypes.SET_TOP_KOMODITI_OPTIONS, payload: { top_komoditi_options } }),
  getTopKomoditi: (top_komoditi) => ({ type: actionTypes.SET_TOP_KOMODITI, payload: { top_komoditi } }),
  getTopFungsiOptions: (top_fungsi_options) => ({ type: actionTypes.SET_TOP_FUNGSI_OPTIONS, payload: { top_fungsi_options } }),
  getTopJenisOptions: (top_jenis_options) => ({ type: actionTypes.SET_TOP_JENIS_OPTIONS, payload: { top_jenis_options } }),
  getTopMerkOptions: (top_merk_options) => ({ type: actionTypes.SET_TOP_MERK_OPTIONS, payload: { top_merk_options } }),
};

export function* saga() {
  // yield takeLatest(actionTypes.Login, function* loginSaga() {
  //   console.log('worker login')
  //   yield put(actions.requestUser());
  // });

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   console.log('worker register')
  //   yield put(actions.requestUser());
  // });

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const { data: user } = yield getUserByToken();
  //   const respons = yield getUserByToken();
  //   console.log('worker user requested')
  //   console.log(user)
  //   console.log(respons)

  //   yield put(actions.fulfillUser(user));
  // });
}
