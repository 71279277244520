
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export default function ConfirmDelete(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
    fullScreen={fullScreen}
    open={props.show}
    onClose={props.handleClose}
    aria-labelledby="responsive-dialog-title"
    >
    <DialogTitle id="responsive-dialog-title">{"Apakah anda yakin ingin menghapus?"}</DialogTitle>
    <DialogContent>
        <DialogContentText>
        Data yang dihapus tidak dapat di kembalikan lagi.
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={props.handleClose} color="default">
        Batal
        </Button>
        <Button onClick={props.handleDelete} color="primary" autoFocus>
        Ya
        </Button>
    </DialogActions>
    </Dialog>
  );
}
