/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function PortletHeaderDropdown({data: { id }, setConfirmDelete}) {
  return (
    <Dropdown className="kt-portlet__head-toolbar" drop="down" alignRight>
      <Dropdown.Toggle
        variant="transparent"
        className="btn btn-label-success btn-sm btn-bold dropdown-toggle"
        id="dropdown-toggle-top"
      >
        Action
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right">
        <ul className="kt-nav">
        <li className="kt-nav__item">
          <a href="#" className="kt-nav__link">
            <i className="kt-nav__link-icon flaticon-edit" />
            <span className="kt-nav__link-text">Ubah</span>
          </a>
        </li>
        <li className="kt-nav__item">
          <a href="#" onClick={(e) => setConfirmDelete({open: true, id: id})} className="kt-nav__link">
            <i className="kt-nav__link-icon flaticon-delete-1" />
            <span className="kt-nav__link-text">Hapus</span>
          </a>
        </li>
        <li className="kt-nav__item">
          <Link to={`/detail/aset/${id}`} className="kt-nav__link">
            <i className="kt-nav__link-icon flaticon-folder-1" />
            <span className="kt-nav__link-text">Detail</span>
          </Link>
        </li>
          <li className="kt-nav__item">
            <a href="#" className="kt-nav__link">
              <i className="kt-nav__link-icon flaticon2-image-file" />
              <span className="kt-nav__link-text">Upload Gambar</span>
            </a>
          </li>
          <li className="kt-nav__item">
            <a href="#" className="kt-nav__link">
              <i className="kt-nav__link-icon flaticon2-heart-rate-monitor" />
              <span className="kt-nav__link-text">Kondisi</span>
            </a>
          </li>
          <li className="kt-nav__item">
            <a href="#" className="kt-nav__link">
              <i className="kt-nav__link-icon flaticon2-settings" />
              <span className="kt-nav__link-text">Pemeliharaan</span>
            </a>
          </li>

        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}
