import React, { useState } from 'react';
import CmbBidang from "../../ajax/combobox/CmbBidang";
import {
  Button,
  Modal,
  Col,
  Form
} from "react-bootstrap";
import { doAdd } from "../../../crud/cctv.crud";
 export default function AddForm(props) {
   const [show,setShow]=useState(false);
   const [validated,setValidated]=useState(false);
   const submitHandler = (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      const field = event.target.elements;

      if (form.checkValidity() === false) {

        event.stopPropagation();
      }else{
        var obj={
          lokasi:field.formLokasi.value,
          jumlah:field.formJumlah.value,
          lintang:field.formLintang.value,
          bujur:field.formBujur.value,
          idBidang:field.formBidang.value
        }

        doAdd(obj).then(res => res.success)
        .then(
          (result) => {
            props.onFormChange({status:true,msg:"data berhasil ditambah",variant:'success'});
        },
        (error) => {
          props.onFormChange({status:false,msg:"data tidak berhasil ditambah",variant:'danger'});
        }
        )
        handleClose(false);
        }
        setValidated(true);
        }



        function handleClose() {
          setShow(false);
        }

        function handleShow() {
          setShow(true);
        }



     return (
       <>
       <Button variant="primary" onClick={handleShow}>
         Tambah Data
       </Button>

       <Modal show={show} onHide={handleClose}>
         <Modal.Header closeButton>
           <Modal.Title>Tambah Data</Modal.Title>
         </Modal.Header>
         <Form
         noValidate
         validated={validated}
           onSubmit={submitHandler}
         >
         <Modal.Body>

           <Form.Row>
             </Form.Row>
             <Form.Group as={Col} md="12" controlId="formLokasi">
               <Form.Label>Lokasi</Form.Label>
               <Form.Control
                 required
                 type="text"
                 placeholder="Lokasi.."
               />
               <Form.Control.Feedback>Sudah sesuai..</Form.Control.Feedback>
               <Form.Control.Feedback type="invalid">
                 Data Belum diisi..
               </Form.Control.Feedback>
             </Form.Group>
             <Form.Group as={Col} md="12" controlId="formJumlah">
               <Form.Label>Jumlah</Form.Label>
               <Form.Control
                 required
                 type="text"
                 placeholder="Jumlah"
               />
               <Form.Control.Feedback>Sudah sesuai..</Form.Control.Feedback>
               <Form.Control.Feedback type="invalid">
                 Data Belum diisi..
               </Form.Control.Feedback>
             </Form.Group>
             <Form.Group as={Col} md="12" controlId="formBidang">
             <Form.Label>Nama Bidang</Form.Label>
             <Form.Control as="select">
             <CmbBidang idBidang=''></CmbBidang>
            </Form.Control>

             </Form.Group>
             <Form.Group as={Col} md="12" controlId="formLintang">
               <Form.Label>Lintang</Form.Label>
               <Form.Control
                 required
                 type="text"
                 placeholder="Lintang.."
               />
               <Form.Control.Feedback>Sudah sesuai..</Form.Control.Feedback>
               <Form.Control.Feedback type="invalid">
                 Data Belum diisi..
               </Form.Control.Feedback>
             </Form.Group>
             <Form.Group as={Col} md="12" controlId="formBujur">
               <Form.Label>Bujur</Form.Label>
               <Form.Control
                 required
                 type="text"
                 placeholder="Bujur.."
               />
               <Form.Control.Feedback>Sudah sesuai..</Form.Control.Feedback>
               <Form.Control.Feedback type="invalid">
                 Data Belum diisi..
               </Form.Control.Feedback>
             </Form.Group>


         </Modal.Body>
         <Modal.Footer>
           <Button variant="secondary" onClick={handleClose}>
             Tutup
           </Button>
           <Button type="submit" variant="primary">
             Simpan Data
           </Button>
         </Modal.Footer>
          </Form>
       </Modal>
     </>

     )
}
