import React, { useEffect, useMemo, useRef } from "react";
import { Chart } from "chart.js";
import { useSelector } from "react-redux";
import { metronic } from "../../../_metronic";
import { Badge, Button } from "react-bootstrap";
export default function Doughnut(props) {
  const ref = useRef();
  const { brandColor, shape2Color, shape3Color } = useSelector(state => ({
    brandColor: metronic.builder.selectors.getConfig(
      state,
      "colors.state.brand"
    ),
    shape2Color: metronic.builder.selectors.getConfig(
      state,
      "colors.base.shape.2"
    ),
    shape3Color: metronic.builder.selectors.getConfig(
      state,
      "colors.base.shape.3"
    )
  }));

  const data = useMemo(
    () => ({
      labels: ['Baik','RR','RB'],
      datasets: [
        {
          fill: true,
          borderWidth: 0,
          backgroundColor:[Chart.helpers
            .color('#0ABB87')
            .alpha(0.8)
            .rgbString(),Chart.helpers
              .color('#FFB822')
              .alpha(0.8)
              .rgbString(),Chart.helpers
                .color('#FD397A')
                .alpha(0.8)
                .rgbString()],
          borderColor: Chart.helpers
            .color(brandColor)
            .alpha(0)
            .rgbString(),

          pointHoverRadius: 4,
          data: [603,52,52]
        }
      ]
    }),
    [brandColor]
  );

  useEffect(() => {
    // For more information about the chartjs, visit this link
    // https://www.chartjs.org/docs/latest/getting-started/usage.html

    const chart = new Chart(ref.current, {
      data,
      type: "doughnut",
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 65,
        legend:false,
        title: {
          display: false
        },
        hover: {
          mode: "ErrorsPage.js"
        },
        tooltips: {
          enabled: true,
          intersect: false,
          mode: "nearest",
          bodySpacing: 5,
          yPadding: 10,
          xPadding: 10,
          caretPadding: 0,
          displayColors: false,
          backgroundColor: brandColor,
          titleFontColor: "#ffffff",
          cornerRadius: 4,
          footerSpacing: 0,
          titleSpacing: 0
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 5,
            bottom: 5
          }
        }
      }
    });

    return () => {
      chart.destroy();
    };
  }, [data, brandColor, shape2Color, shape3Color]);
  console.log(props);
  return (
    <div className="kt-widget26">
      <div className="kt-widget26__content">
        <span className="kt-widget26__number">{props.total}</span>
        <span className="kt-widget26__desc">{props.desc}</span>
      </div>

      <div
        className="kt-widget26__chart"
        style={{ width: "230px", height: "150px" }}
      >
      <canvas
        ref={ref}
        width={683}
        height={312}
        id="kt_chart_doghnut"
      />
      <center>
      <h6>
        <Badge variant="success">{props.baik} Baik</Badge>&nbsp;
        <Badge variant="warning">{props.rr} RR</Badge>&nbsp;
        <Badge variant="danger">{props.rb} RB</Badge>
      </h6>
      </center>
      </div>
    </div>
  );
}
