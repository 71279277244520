/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

function BreadCrumbs(props) {
    const { items } = props;
    if (!items || !items.length) {
        return "";
    }

    const length = items.length;
    // let last = '';
    return (
        <div className="kt-subheader__breadcrumbs">
            <a href="#" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
            </a>
            <span className="kt-subheader__separator kt-subheader__separator--v" />
            {items.map((item, index) => {
                // last = item.page;
                return (
                    <React.Fragment key={`bc${index}`}>
                        <Link className="kt-subheader__desc" to={item.page !== undefined ? `/${item.page}` : '#'}>
                            {item.title}
                        </Link>
                        {length !== index + 1 && (
                            <span className="kt-subheader__separator kt-subheader__separator--v" />
                        )}
                    </React.Fragment>
                )
            })}
            {/* <Link className="btn btn-label-warning btn-bold btn-sm btn-icon-h kt-margin-l-10" to={last !== undefined ? `/${last}/add` : '#'}>
                Add New
            </Link> */}
        </div>
    );
}

export default BreadCrumbs;
