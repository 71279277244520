import React, { useEffect, useMemo, useRef } from "react";
import { Chart } from "chart.js";
import { useSelector } from "react-redux";
import { metronic } from "../../../_metronic";
import { Badge, Button } from "react-bootstrap";
export default function PolarArea(props) {
  const ref = useRef();


  useEffect(() => {
    // For more information about the chartjs, visit this link
    // https://www.chartjs.org/docs/latest/getting-started/usage.html

    const chart = new Chart(ref.current, {
       type: 'polarArea',
      data: {
       labels: ["TANK & PANSER", "KAPAL ANGKUT & PENDARAT ", "PESAWAT & HELIKOPTER", "SENJATA API"],
       datasets: [
         {
           label: "1950",
           fill: true,
           backgroundColor: [
              "#3BC99F",
              "#FFC64E",
              "#A2A7C2",
              "#9EAEFF"
            ],
           pointBorderColor: "#fff",
           pointBackgroundColor: "rgba(179,181,198,1)",
           data: [1414,616,679,2500]
         }
       ]
     },
     options: {
       title: {
         display: false
       },legend:{
          position: 'bottom'
        }
     }
    });

    return () => {
      chart.destroy();
    };
  });
  console.log(props);
  return (
    <div className="kt-widget12">
      <div className="kt-widget12__content">
        <div className="kt-widget12__item">
          <div className="kt-widget12__info">
            <span className="kt-widget12__desc">Total</span>
            <span className="kt-widget12__value">
            <h4>
            <Badge variant="info">5209</Badge>
            </h4>
            </span>
          </div>
          <div className="kt-widget12__info">
            <span className="kt-widget12__desc">Kondisi</span>
            <span className="kt-widget12__value">
              <h4>
                <Badge variant="success">4863 Baik</Badge>&nbsp;
                <Badge variant="warning">171 ‬RR</Badge>&nbsp;
                <Badge variant="danger">171 RB</Badge>
              </h4></span>
          </div>
        </div>

      </div>
      <div className="kt-widget12__chart" >
        <canvas
          ref={ref}
          width={720}
          height={450}
          id="kt_chart_radar"
        />
      </div>
    </div>
  );
}
