import React, { useState } from "react"
import { useFormik } from "formik";
import * as Yup from 'yup';
import { connect } from "react-redux";
import { 
  FormattedMessage, 
  injectIntl 
} from "react-intl";
import clsx from "clsx";
import * as area_service from "../../../store/ducks/admin/area-service.duck";
import { doAdd, doUpdate } from "../../../crud/admin/area-service.crud";
import AlertForm from "../../../components/AlertForm";
import { Modal } from "react-bootstrap";
import TextFieldGroup from "../../../components/form/TextFieldGroup";
import { validationErrorMessage } from "../../../../_metronic/utils/helper";
import { LinearProgress } from "@material-ui/core";

function Form(props) {
    const { show, closeModal, data, reloadData } = props;
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({open: false, type: 'success', message: ''})
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
      paddingRight: "2.5rem"
    });

    const enableLoading = () => {
      setLoading(true);
      setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
      setLoading(false);
      setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };

    const closeModalHandler = () => {
        closeModal();
        setAlert({open:false})
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            parent: data && data.parent ? data.parent.id : '',
            kotama: data && data.kotama ? data.kotama.id : '',
            satpor: data && data.satpor ? data.satpor.id : '',
            satyanis: data && data.satyanis ? data.satyanis.id : ''
        },
        validationSchema: Yup.object({
            parent: Yup.number()
                .integer(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />)
                .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            kotama: Yup.number()
                .integer(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />)
                .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            satpor: Yup.number()
                .integer(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />)
                .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            satyanis: Yup.number()
                .integer(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />)
                .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
        }),
        onSubmit: (values, { resetForm }) => {
            enableLoading();
            if(data === undefined){
                doAdd(values)
                .then(({ data }) => {
                    resetForm();
                    disableLoading();
                    reloadData();
                    setAlert({open: true, type: 'success', message: 'Tambah data berhasil.'})
                })
                .catch((err) => {
                    disableLoading();
                    let errorMessage = validationErrorMessage(err)
                    setAlert({open: true, type: 'danger', message: `Tambah data gagal. ${errorMessage}`})
                });
            } else {
                doUpdate(values, data.id)
                .then(({ data }) => {
                    disableLoading();
                    reloadData();
                    setAlert({open: true, type: 'success', message: 'Simpan data berhasil.'})
                })
                .catch((err) => {
                    disableLoading();
                    let errorMessage = validationErrorMessage(err)
                    setAlert({open: true, type: 'danger', message: `Simpan data gagal. ${errorMessage}`})
                });
            }
        }
    });

    return (
        <Modal
            size="md"
            show={show}
            onHide={() => closeModalHandler()}
            aria-labelledby="example-modal-sizes-title-md"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-md">
                    {data ? 'Edit' : 'Tambah'} data area service
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { props.loading && (<LinearProgress />) }
                <form
                    noValidate={true}
                    autoComplete="off"
                    className="kt-form"
                    onSubmit={formik.handleSubmit}
                >
                    <AlertForm 
                        show={alert.open}
                        variant={alert.type}
                        msg={alert.message}
                        closeAlert={() => setAlert({open: false})}
                    />

                        <TextFieldGroup
                            select={true}
                            margin="normal"
                            label="Kotama"
                            className="kt-width-full"
                            name="kotama"
                            {...formik.getFieldProps('kotama')}
                            onChange={(e) => {
                                formik.handleChange(e)
                                formik.setFieldValue('satyanis', '')
                                formik.setFieldValue('satpor', '')
                                formik.setFieldValue('parent', '')
                                props.loadSatyanisOptions(e.target.value)
                                props.loadAreaServiceOptions(e.target.value)
                                props.loadSatkerOptions(e.target.value)
                            }}
                            errorText={formik.touched.kotama && formik.errors.kotama}
                            error={Boolean(formik.touched.kotama && formik.errors.kotama)}
                        >
                        <option>Pilih Kotama</option>
                            { props.kotama_options && props.kotama_options.map(kotama => (<option key={kotama.id} value={kotama.id}>{kotama.nm_kotama}</option>)) }
                        </TextFieldGroup>
                        <TextFieldGroup
                            select={true}
                            margin="normal"
                            label="Satyanis"
                            className="kt-width-full"
                            name="satyanis"
                            {...formik.getFieldProps('satyanis')}
                            errorText={formik.touched.satyanis && formik.errors.satyanis}
                            error={Boolean(formik.touched.satyanis && formik.errors.satyanis)}
                        >
                        <option>Pilih Satyanis</option>
                            { props.satyanis_options && props.satyanis_options.map(satyanis => (<option key={satyanis.id} value={satyanis.id}>{satyanis.nm_satyanis}</option>)) }
                        </TextFieldGroup>
                        <TextFieldGroup
                            select={true}
                            margin="normal"
                            label="Parent Area Service"
                            className="kt-width-full"
                            name="parent"
                            {...formik.getFieldProps('parent')}
                            errorText={formik.touched.parent && formik.errors.parent}
                            error={Boolean(formik.touched.parent && formik.errors.parent)}
                        >
                        <option>Pilih Area Service Parent</option>
                            { props.area_service_options && props.area_service_options.map(area_service => (<option key={area_service.id} value={area_service.satpor.id}>{area_service.satpor.nm_satpor}</option>)) }
                        </TextFieldGroup>
                        <TextFieldGroup
                            select={true}
                            margin="normal"
                            label="Satker"
                            className="kt-width-full"
                            name="satpor"
                            {...formik.getFieldProps('satpor')}
                            errorText={formik.touched.satpor && formik.errors.satpor}
                            error={Boolean(formik.touched.satpor && formik.errors.satpor)}
                        >
                        <option>Pilih Satker</option>
                            { props.satker_options && props.satker_options.map(satker => (<option key={satker.id} value={satker.id}>{satker.nm_satpor}</option>)) }
                        </TextFieldGroup>
                    <div className="kt-login__actions">

                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={loading}
                        className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                            "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                        }
                        )}`}
                        style={loadingButtonStyle}
                    >
                        { data ? 'Update' : 'Simpan'}
                    </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default injectIntl(
    connect(
      area_service.states,
      area_service.actions
    )(Form)
  );
