/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Form,Row,Col } from "react-bootstrap";
export default function SearchFilter() {
  return (
    <Form.Group  as={Row}>
    <Col sm="3">
    <Form.Control as="select">
      <option value=''>Pilih Area Layanan..</option>
      </Form.Control>
      </Col>
      <Col sm="3">
      <Form.Control as="select">
        <option value=''>Pilih Fungsi..</option>
        </Form.Control>
        </Col>
        <Col sm="3">
        <Form.Control as="select">
          <option value=''>Pilih Jenis..</option>
          </Form.Control>
          </Col>
          <Col sm="3">
          <Form.Control
          placeholder="Merk.."
          >
            </Form.Control>
            </Col>
      </Form.Group>
)
}
