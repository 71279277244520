import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../partials/content/Portlet";
import { metronic } from "../../../_metronic";
import QuickStatsChart from "../../widgets/QuickStatsChart";
import OrderStatisticsChart from "../../widgets/OrderStatisticsChart";
import OrdersWidget from "../../widgets/OrdersWidget";
import SalesBarChart from "../../widgets/SalesBarChart";
import DownloadFiles from "../../widgets/DownloadFiles";
import NewUsers from "../../widgets/NewUsers";
import LatestUpdates from "../../widgets/LatestUpdates";
import BestSellers from "../../widgets/BestSellers";
import RecentActivities from "../../widgets/RecentActivities";
import PortletHeaderDropdown from "../../partials/content/CustomDropdowns/PortletHeaderDropdown";
import BarChart from "../../components/top/Bar";
import { Table } from "react-bootstrap";


export default function Dspp() {
  const { brandColor, dangerColor, successColor, primaryColor } = useSelector(
    state => ({
      brandColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.brand"
      ),
      dangerColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.danger"
      ),
      successColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.success"
      ),
      primaryColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.primary"
      )
    })
  );



  return (
    <>



  <h3>KAPAL ANGKUT</h3>
    <div className="row">
    <div className="col-xl-5">
    <Portlet fluidHeight={true}>
      <PortletHeader
        title=""

      />

      <PortletBody>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>NAMA JENIS</th>
              <th>TOP DSPP</th>
              <th>NYATA</th>
              <th>(+)(-)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>KMC (KAPAL MOTOR CEPAT)</td>
              <td>384</td>
              <td>87</td>
              <td>297</td>
            </tr>
            <tr>
              <td>2</td>
              <td>SPEED BOAT</td>
              <td>70</td>
              <td>38</td>
              <td>32</td>
            </tr>
            <tr>
              <td>3</td>
              <td>KMC LAIN LAIN</td>
              <td>38</td>
              <td>2</td>
              <td>36</td>
            </tr>
            <tr>
              <td>4</td>
              <td>LCU (LANDING CRAFT UTILITY)</td>
              <td>20</td>
              <td>4</td>
              <td>16</td>
            </tr>
            <tr>
              <td>5</td>
              <td>COASTER</td>
              <td>7</td>
              <td>0</td>
              <td>7</td>
            </tr>
            <tr>
              <td>6</td>
              <td>LSM</td>
              <td>7</td>
              <td>1</td>
              <td>6</td>
            </tr>
            <tr>
              <td>7</td>
              <td>TANK BOAT</td>
              <td>10</td>
              <td>0</td>
              <td>10</td>
            </tr>
          </tbody>
        </Table>
        </PortletBody>
      </Portlet>
    </div>
      <div className="col-xl-7">
      <Portlet fluidHeight={true}>
        <PortletHeader
          title=""

        />

        <PortletBody>
          <BarChart />
        </PortletBody>
      </Portlet>


      </div>
    </div>



    <h3>KAPAL PENDARAT</h3>
      <div className="row">
      <div className="col-xl-5">
      <Portlet fluidHeight={true}>
        <PortletHeader
          title=""

        />

        <PortletBody>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>NAMA JENIS</th>
                <th>TOP DSPP</th>
                <th>NYATA</th>
                <th>(+)(-)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>LCR (LANDING CRAFT RUBBER/RAMP)</td>
                <td>3604</td>
                <td>300</td>
                <td>3304</td>
              </tr>
              <tr>
                <td>2</td>
                <td>LCR / PERAHU SERBU 10 ORANG</td>
                <td>152</td>
                <td>28</td>
                <td>124</td>
              </tr>
              <tr>
                <td>3</td>
                <td>LCM (LANDING CRAFT MEKANIC)</td>
                <td>38</td>
                <td>2</td>
                <td>36</td>
              </tr>
              <tr>
                <td>4</td>
                <td>LCR / PERAHU SERBU 8 ORANG</td>
                <td>20</td>
                <td>4</td>
                <td>16</td>
              </tr>
              <tr>
                <td>5</td>
                <td>LCR / PERAHU SERBU 15 ORANG</td>
                <td>7</td>
                <td>0</td>
                <td>7</td>
              </tr>
              <tr>
                <td>6</td>
                <td>LCR / PERAHU INTAI 3 ORANG</td>
                <td>7</td>
                <td>1</td>
                <td>6</td>
              </tr>
            </tbody>
          </Table>
          </PortletBody>
        </Portlet>
      </div>
        <div className="col-xl-7">
        <Portlet fluidHeight={true}>
          <PortletHeader
            title=""

          />

          <PortletBody>
            <BarChart />
          </PortletBody>
        </Portlet>


        </div>
      </div>





    </>
  );
}
