import React, { useState, useRef, useEffect } from "react";
// import { Link } from "react-router-dom";
import ReactTable from "react-table";
import { connect } from "react-redux";
import { 
  // FormattedMessage, 
  injectIntl 
} from "react-intl";
import { Portlet, PortletHeader, PortletHeaderToolbar, PortletBody } from "../../../partials/content/Portlet";
import * as top_jenis from "../../../store/ducks/admin/top-jenis.duck";
import * as global from "../../../store/ducks/global.duck";
import { doList as doListTopKelompok } from "../../../crud/admin/top-kelompok.crud";
import { doList as doListTopKomoditi } from "../../../crud/admin/top-komoditi.crud";
import { doList as doListTopFungsi } from "../../../crud/admin/top-fungsi.crud";
import { doList, doDelete } from "../../../crud/admin/top-jenis.crud";
import ConfirmDelete from "../../../components/ConfirmDelete";
import AlertForm from "../../../components/AlertForm";
import Form from "./Form";
import { Button, InputBase, Divider, IconButton, Typography, Grid, Card, CardContent } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TextFieldGroup from "../../../components/form/TextFieldGroup";

const useStyles = makeStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 8,
  },
});

function TopJenis(props) {
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState({
      kelompok: '',
      komoditi: '',
      fungsi: ''
    });
    const [confirmDelete, setConfirmDelete] = useState({
      open: false,
      id: null
    });
    const [form, setForm] = useState({
      open: false,
      data: null
    });
    const [alert, setAlert] = useState({open: false, type: 'success', message: ''})
    const [totalpages, setTotalpages] = useState(1);
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
      paddingRight: "2.5rem"
    });

    const reactTable = useRef();
    const classes = useStyles();

    const enableLoading = () => {
      setLoading(true);
      setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
      setLoading(false);
      setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };

    const reloadTable = () => reactTable.current && reactTable.current.fireFetchData()
    
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        reloadTable()
      }
    }

    function fetchData(state) {
      enableLoading();
      const ordering = state.sorted.map(next => ((next.desc ? '-' : '') + next.id)).join(',')
      const params = {
        fungsi__komoditi__kelompok__id: filter.kelompok !== 'all' ? filter.kelompok : '',
        fungsi__komoditi__id: filter.komoditi !== 'all' ? filter.komoditi : '',
        fungsi__id: filter.fungsi !== 'all' ? filter.fungsi : '',
        ordering: ordering ? ordering : '-nm_jenis',
        search: search,
        page: state.page + 1,
        page_size: state.pageSize
      }
      doList(params)
        .then(({ data: { results, count } }) => {
            disableLoading();
            let totalpage=Math.ceil(parseInt(count)/parseInt(state.pageSize));
            setTotalpages(totalpage)
            props.getTopJenisData(results, count);
          }
        ).catch(error => {
          disableLoading();
        })
    }

    function handleDelete() {
      setConfirmDelete({open:false})
      setAlert({open: false})
      enableLoading();
      doDelete(confirmDelete.id).then(response => {
        setAlert({open: true, type: 'success', message: 'Hapus data berhasil.'})
        disableLoading();
        reloadTable();
      }).catch(error => {
        setAlert({open: true, type: 'danger', message: 'Hapus data gagal.'})
        disableLoading();
      })
    }
    
    const loadTopKelompokOptions = () => {
      doListTopKelompok({ordering: 'nm_kelompok', page_size: 9999999})
          .then(({ data: { results } }) => {
              props.getTopKelompokOptions(results);
          }
      )
    }

    const loadTopKomoditiOptions = (id_kelompok) => {
      doListTopKomoditi({ordering: 'nm_komoditi', kelompok__id: id_kelompok, page_size: 9999999})
          .then(({ data: { results } }) => {
              props.getTopKomoditiOptions(results);
          }
      )
    }

    const loadTopFungsiOptions = (id_komoditi) => {
      doListTopFungsi({ordering: 'nm_fungsi', komoditi__id: id_komoditi, page_size: 9999999})
          .then(({ data: { results } }) => {
              props.getTopFungsiOptions(results);
          }
      )
    }

    useEffect(() => {
      loadTopKelompokOptions()
      // eslint-disable-next-line
    }, []);

    const columns = [
      {
        Header: "Kelompok",
        accessor: "fungsi.komoditi.kelompok.nm_kelompok",
      },
      {
        Header: "Komoditi",
        accessor: "fungsi.komoditi.nm_komoditi",
      },
      {
        Header: "Fungsi",
        accessor: "fungsi.nm_fungsi"
      },
      {
        Header: "Kode Jenis",
        accessor: "kd_jenis"
      },
      {
        Header: "Nama Jenis",
        accessor: "nm_jenis"
      },
      {
        Header: "Aksi",
        accessor: "id",
        Cell: ({ original }) => (
          <div style={{ textAlign: "center" }}>
            <IconButton onClick={(e) => {
              loadTopKomoditiOptions(original.fungsi.komoditi.kelompok.id)
              loadTopFungsiOptions(original.fungsi.komoditi.id)
              setForm({open: true, data: original})
            }} aria-label="Edit" size="small">
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton onClick={(e) => setConfirmDelete({open: true, id: original.id})} aria-label="Delete" size="small">
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </div>
        )
      }
    ]

    return (
      <Portlet>
        <PortletHeader
          title={'Top Jenis'}
          toolbar={(
              <PortletHeaderToolbar>
                <InputBase
                  className={classes.input}
                  placeholder="Cari Top Jenis"
                  inputProps={{ 'aria-label': 'Cari Top Jenis' }}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e)}
                />
                <IconButton className={classes.iconButton} aria-label="Search" onClick={() => reloadTable()}>
                  <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} />
                <Button variant="contained" color="secondary" onClick={() => setForm({open: true})}><i className="kt-nav__link-icon flaticon2-add" /> Tambah</Button>
              </PortletHeaderToolbar>
            )
          }
        />
        <PortletBody>
          <ConfirmDelete
            handleClose={() => setConfirmDelete({open:false})}
            handleDelete={() => handleDelete()}
            show={confirmDelete.open}
          />
          <AlertForm 
            show={alert.open}
            variant={alert.type}
            msg={alert.message}
            closeAlert={() => setAlert({open: false})}
          />
          <Card>
            <CardContent>
              <Typography variant="h6" component="h6">
                Filter
              </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <TextFieldGroup
                      select={true}
                      margin="normal"
                      label="Top Kelompok"
                      className="kt-width-full"
                      onChange={(e) => {
                        setFilter({...filter, kelompok: e.target.value})
                        loadTopKomoditiOptions(e.target.value)
                      }}
                      value={filter.kelompok}
                    >
                      <option key={0} value="all">Semua</option>
                      { props.top_kelompok_options && props.top_kelompok_options.map(top_kelompok => (<option key={top_kelompok.id} value={top_kelompok.id}>{top_kelompok.nm_kelompok}</option>)) }
                    </TextFieldGroup>
                    <button
                      disabled={loading}
                      className={`btn btn-primary btn-elevate btn-sm ${clsx(
                      {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                      )}`}
                      style={loadingButtonStyle}
                      onClick={() => reloadTable()}
                    >
                    Filter
                  </button>
                </Grid>
                <Grid item xs={4}>
                  <TextFieldGroup
                      select={true}
                      margin="normal"
                      label="Top Komoditi"
                      className="kt-width-full"
                      onChange={(e) => {
                        setFilter({...filter, komoditi: e.target.value})
                        loadTopFungsiOptions(e.target.value)
                      }}
                      value={filter.komoditi}
                  >
                    <option key={0} value="all">Semua</option>
                    { props.top_komoditi_options && props.top_komoditi_options.map(top_komoditi => (<option key={top_komoditi.id} value={top_komoditi.id}>{top_komoditi.nm_komoditi}</option>)) }
                  </TextFieldGroup>
                </Grid>
                <Grid item xs={4}>
                  <TextFieldGroup
                      select={true}
                      margin="normal"
                      label="Top Fungsi"
                      className="kt-width-full"
                      onChange={(e) => setFilter({...filter, fungsi: e.target.value})}
                      value={filter.fungsi}
                  >
                    <option key={0} value="all">Semua</option>
                    { props.top_fungsi_options && props.top_fungsi_options.map(top_fungsi => (<option key={top_fungsi.id} value={top_fungsi.id}>{top_fungsi.nm_fungsi}</option>)) }
                  </TextFieldGroup>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <br/>
          <ReactTable
            ref={reactTable}
            data={props.datum}
            pages={totalpages}
            columns={columns}
            defaultPageSize={10}
            className="-striped -highlight"
            loading={loading}
            showPagination={true}
            pageSizeOptions={[10, 20, 25, 50, 100]}
            manual // this would indicate that server side pagination has been enabled
            onFetchData={fetchData}
          />
          <Form
            show={form.open}
            closeModal={() => setForm({open: false, data: null})}
            data={form.data}
            reloadData={() => reloadTable()}
            loadTopKelompokOptions={() => loadTopKelompokOptions()}
            loadTopKomoditiOptions={(id_kelompok) => loadTopKomoditiOptions(id_kelompok)}
            loadTopFungsiOptions={(id_komoditi) => loadTopFungsiOptions(id_komoditi)}
          />
        </PortletBody>
      </Portlet>
    )
}

const { getTopKelompokOptions, getTopKomoditiOptions, getTopFungsiOptions } = global.actions

export default injectIntl(
    connect(
      top_jenis.states,
      { ...top_jenis.actions, getTopKelompokOptions, getTopKomoditiOptions, getTopFungsiOptions }
    )(TopJenis)
  );
