import React, { useMemo,useState } from "react";
import { useSelector } from "react-redux";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../partials/content/Portlet";
import { metronic } from "../../../_metronic";

import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import BarChart from "../../components/aset/Bar";
import { Table } from "react-bootstrap";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});



export default function Sebaran() {
   const [latlong, setLatlong] = useState({
       lat: -5.1519195,
       lng: 119.4156153,
       zoom: 13,
   });
  const { brandColor, dangerColor, successColor, primaryColor } = useSelector(
    state => ({
      brandColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.brand"
      ),
      dangerColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.danger"
      ),
      successColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.success"
      ),
      primaryColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.primary"
      )
    })
  );



  return (
    <>

    <div className="row">
      <div className="col-lg-12">
      <Portlet fluidHeight={true}>
        <PortletHeader
          title="PETA SEBARAN"

        />

        <PortletBody>
      <Map
                center={[latlong.lat, latlong.lng]}
                zoom={latlong.zoom}
                style={{ width: '100%', height: '400px'}}
             >
             <TileLayer
               attribution=''
               url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />

              <Marker position={[latlong.lat, latlong.lng]}>
              <Popup>
                <span>KODAM XIV/HSN<br/>DENJAS ANG XIV-44-13
                <br/>BAIK : 13
                <br/>RR : 1
                <br/>RB : 1
                <br/>TOP : 3
                <br/>SELISIH : 12
                </span>
              </Popup>
            </Marker>

            </Map>
            </PortletBody>
          </Portlet>

      </div>
    </div>


    <div className="row">
    <div className="col-lg-5">
    <Portlet fluidHeight={true}>
      <PortletHeader
        title="SEBARAN KMC (KAPAL MOTOR CEPAT)"

      />

      <PortletBody>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>KOTAMA / SATKER </th>
              <th>BAIK/RR/RB</th>
              <th>TOTAL</th>
              <th>TOP</th>
              <th>SELISIH</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>KODAM XIV/HSN<br/>DENJAS ANG XIV-44-13</td>
              <td style={{
                        textAlign:"right"
                      }}
                      >13<br/>1<br/>1</td>
              <td style={{
                        textAlign:"right"
                      }}>15</td>
              <td style={{
                        textAlign:"right"
                      }}>3</td>
              <td style={{
                        textAlign:"right"
                      }}>12</td>
            </tr>
            <tr>
              <td>2</td>
              <td>KODAM XIV/HSN<br/>DENBEKANG XIV-44-02/PARE-PARE</td>
              <td style={{
                        textAlign:"right"
                      }}
                      >4<br/>0<br/>0</td>
              <td style={{
                        textAlign:"right"
                      }}>4</td>
              <td style={{
                        textAlign:"right"
                      }}>2</td>
              <td style={{
                        textAlign:"right"
                      }}>2</td>
            </tr>
            <tr>
              <td>3</td>
              <td>KODAM XIV/HSN<br/>DENBEKANG XIV-44-01/WATAMPONE</td>
              <td style={{
                        textAlign:"right"
                      }}
                      >1<br/>0<br/>0</td>
              <td style={{
                        textAlign:"right"
                      }}>1</td>
              <td style={{
                        textAlign:"right"
                      }}>2</td>
              <td style={{
                        textAlign:"right"
                      }}>-1</td>
            </tr>
            <tr>
              <td>4</td>
              <td>KODAM XIV/HSN<br/>KOREM 141/TODDOPULI</td>
              <td style={{
                        textAlign:"right"
                      }}
                      >0<br/>0<br/>0</td>
              <td style={{
                        textAlign:"right"
                      }}>0</td>
              <td style={{
                        textAlign:"right"
                      }}>0</td>
              <td style={{
                        textAlign:"right"
                      }}>0</td>
            </tr>
            <tr>
              <td>4</td>
              <td>KODAM XIV/HSN<br/>DENBEKANG XIV-44-03/KENDARI</td>
              <td style={{
                        textAlign:"right"
                      }}
                      >0<br/>1<br/>1</td>
              <td style={{
                        textAlign:"right"
                      }}>2</td>
              <td style={{
                        textAlign:"right"
                      }}>1</td>
              <td style={{
                        textAlign:"right"
                      }}>1</td>
            </tr>

          </tbody>
        </Table>
        </PortletBody>
      </Portlet>
    </div>
      <div className="col-lg-7">
      <Portlet fluidHeight={true}>
        <PortletHeader
          title=""

        />

        <PortletBody>
          <BarChart />
        </PortletBody>
      </Portlet>


      </div>
    </div>








    </>
  );
}
