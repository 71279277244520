import React, { useEffect, useMemo, useRef } from "react";
import { Chart } from "chart.js";
import { useSelector } from "react-redux";
import { metronic } from "../../../_metronic";
import { Badge, Button } from "react-bootstrap";
export default function Pie(props) {
  const ref = useRef();
  const { brandColor, shape2Color, shape3Color } = useSelector(state => ({
    brandColor: metronic.builder.selectors.getConfig(
      state,
      "colors.state.brand"
    ),
    shape2Color: metronic.builder.selectors.getConfig(
      state,
      "colors.base.shape.2"
    ),
    shape3Color: metronic.builder.selectors.getConfig(
      state,
      "colors.base.shape.3"
    )
  }));

  const data = useMemo(
    () => ({
      labels: ['Baik','RR','RB'],
      datasets: [
        {
          fill: true,
          // borderWidth: 0,
          backgroundColor:[Chart.helpers
            .color(brandColor)
            .alpha(0.6)
            .rgbString(),Chart.helpers
              .color(shape2Color)
              .alpha(0.6)
              .rgbString(),Chart.helpers
                .color(shape3Color)
                .alpha(0.6)
                .rgbString()],

          borderColor: Chart.helpers
            .color(brandColor)
            .alpha(0)
            .rgbString(),

          pointHoverRadius: 4,
          data: [603,52,52]
        }
      ]
    }),
    [brandColor]
  );

  useEffect(() => {
    // For more information about the chartjs, visit this link
    // https://www.chartjs.org/docs/latest/getting-started/usage.html

    const chart = new Chart(ref.current, {
      data,
      type: "pie",
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
                position: 'bottom'
                },
        title: {
          display: false
        },
        hover: {
          mode: "ErrorsPage.js"
        },
        tooltips: {
          enabled: true,
          intersect: false,
          mode: "nearest",
          bodySpacing: 5,
          yPadding: 10,
          xPadding: 10,
          caretPadding: 0,
          displayColors: false,
          backgroundColor: brandColor,
          titleFontColor: "#ffffff",
          cornerRadius: 4,
          footerSpacing: 0,
          titleSpacing: 0
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 5,
            bottom: 5
          }
        }
      }
    });

    return () => {
      chart.destroy();
    };
  }, [data, brandColor, shape2Color, shape3Color]);
  console.log(props);
  return (
    <div className="kt-widget14">
      <div className="kt-widget14__header kt-margin-b-30">
        <h3 className="kt-widget14__title">{props.title}</h3>
        <span className="kt-widget14__desc">
        <h5>
          <Badge variant="success">{props.baik} Baik</Badge>&nbsp;
          <Badge variant="warning">{props.rr} RR</Badge>&nbsp;
          <Badge variant="danger">{props.rb} RB</Badge>
        </h5>
        </span>
      </div>
      <div className="kt-widget14__chart" style={{ height: "220px" }}>
        <canvas
          ref={ref}
          width={683}
          height={312}
          id="kt_chart_order_statistics"
        />
      </div>
    </div>
  );
}
