import React from "react";
import { useSelector } from "react-redux";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../partials/content/Portlet";
import PolarArea from "../../components/dashboard/PolarArea";
import { metronic } from "../../../_metronic";

import PieChart from "../../components/dashboard/Doughnut";
import BarBermotor from "../../components/dashboard/BarKbermotor";
import BarKapal from "../../components/dashboard/BarKapal";
import { Table } from "react-bootstrap";


export default function Fungsi() {
  const { brandColor, dangerColor, successColor, primaryColor } = useSelector(
    state => ({
      brandColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.brand"
      ),
      dangerColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.danger"
      ),
      successColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.success"
      ),
      primaryColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.primary"
      )
    })
  );



  return (
    <>

    <div className="row">
      <div className="col-xl-6">
        <div className="row row-full-height">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
              <PortletBody fluid={true}>
              <PieChart total="1414" baik="1323" rr="75" rb="75" title="TANK & PANSER" desc="TANK & PANSER"/>
              </PortletBody>
            </Portlet>

            <div className="kt-space-20" />

            <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
              <PortletBody fluid={true}>
              <PieChart total="616" baik="570" rr="23" rb="23" title="PESAWAT & HELIKOPTER" desc="PESAWAT & HELIKOPTER"/>
              </PortletBody>
            </Portlet>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-6">
            <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
              <PortletBody fluid={true}>
              <PieChart total="679" baik="570" rr="23" rb="23" title="KAPAL ANGKUT & PENDARAT" desc="KAPAL ANGKUT & PENDARAT"/>
              </PortletBody>
            </Portlet>

            <div className="kt-space-20" />

            <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
              <PortletBody fluid={true}>
                  <PieChart total="2500" baik="2400" rr="50" rb="50" title="SENJATA API" desc="SENJATA API"/>
              </PortletBody>
            </Portlet>
          </div>
        </div>
      </div>

      <div className="col-xl-6">
        <Portlet fluidHeight={true}>

          <PortletBody>
            <PolarArea />
          </PortletBody>
        </Portlet>
      </div>

      <div className="col-xl-6">
      <Portlet fluidHeight={true}>

        <PortletBody>
        <BarBermotor/>
        </PortletBody>
      </Portlet>
      </div>
      <div className="col-xl-6">
      <Portlet fluidHeight={true}>

        <PortletBody>
        <BarKapal/>
        </PortletBody>
      </Portlet>
      </div>


      <div className="col-xl-6">
      <Portlet fluidHeight={true}>

        <PortletBody>
        <h5>SENJATA API</h5>
        <Table striped bordered hover>
           <thead>
              <tr>
                 <th>
                 NO
                 </th>
                 <th>
                 NAMA FUNGSI</th>
                 <th >BAIK
                 </th>
                 <th>RR
                 </th>
                 <th>RB
                 </th>
                 <th>TOTAL
                 </th>
              </tr>
           </thead>

           <tbody>
              <tr >
                 <td>1</td>
                 <td >SISTA INFANTERI</td>
                 <td >308425</td>
                 <td  >18973</td>
                 <td >18973</td>
                 <td  >346371</td>
              </tr>
              <tr >
                 <td>2</td>
                 <td >SENJATA NON STANDAR</td>
                 <td >14240</td>
                 <td  >87</td>
                 <td >87</td>
                 <td  >14414</td>
              </tr>
              <tr >
                 <td>3</td>
                 <td >SENJATA LAIN-LAIN</td>
                 <td >11845</td>
                 <td  >5067</td>
                 <td >5067</td>
                 <td  >21979</td>
              </tr>
              <tr >
                 <td>4</td>
                 <td >SISTA ARHANUD</td>
                 <td >947</td>
                 <td  >89</td>
                 <td >89</td>
                 <td  >1125</td>
              </tr>
              <tr >
                 <td>5</td>
                 <td >SISTA KAVALERI</td>
                 <td >881</td>
                 <td  >84</td>
                 <td >84</td>
                 <td  >1049</td>
              </tr>
              <tr>
                 <td>6</td>
                 <td >SISTA ARMED</td>
                 <td >618</td>
                 <td  >34</td>
                 <td >34</td>
                 <td  >686</td>
              </tr>
           </tbody>
        </Table>

        </PortletBody>
      </Portlet>
      </div>
      <div className="col-xl-6">
      <Portlet fluidHeight={true}>

        <PortletBody>
        <h5>AMUNISI / HANDAK</h5>
      <Table striped bordered hover>
        <thead>
           <tr>
              <th>
              NO
              </th>
              <th>
              NAMA FUNGSI</th>
              <th >BAIK
              </th>
              <th>RR
              </th>
              <th>RB
              </th>
              <th>TOTAL
              </th>
           </tr>
        </thead>
   <tbody >
      <tr >
         <td>1</td>
         <td >MUNISI KALIBER KECIL</td>
         <td  >75299090</td>
         <td  >26624</td>
         <td >26624</td>
         <td  >75352338</td>
      </tr>
      <tr>
         <td>2</td>
         <td >MUNISI KHUSUS</td>
         <td  >6797658</td>
         <td  >720</td>
         <td >720</td>
         <td  >6799098</td>
      </tr>
      <tr >
         <td>3</td>
         <td >MUNISI LAIN-LAIN</td>
         <td  >1536359</td>
         <td  >56</td>
         <td >56</td>
         <td  >1536471</td>
      </tr>
      <tr >
         <td>4</td>
         <td >MUNISI KALIBER BESAR</td>
         <td  >764138</td>
         <td  >812</td>
         <td >812</td>
         <td  >765762</td>
      </tr>
   </tbody>
</Table>
        </PortletBody>
      </Portlet>
      </div>

    </div>










    </>
  );
}
