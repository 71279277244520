import React from "react";
import { useSelector } from "react-redux";
import {
  Portlet,
  PortletBody
} from "../../partials/content/Portlet";
import { metronic } from "../../../_metronic";
import { Table,Button } from "react-bootstrap";


export default function Distribusi() {

  return (
    <>

    <div className="row">
      <div className="col-xl-12">
        <div className="row row-full-height">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <Portlet >
              <PortletBody>

              <center><h5>DAFTAR PENDISTRIBUSIAN MUNISI 2020</h5></center>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>NAMA JENIS</th>
                    <th>SAT</th>
                    <th>DIA PUSAT</th>
                    <th>I/SWJ<br/>(PALDAM)</th>
                    <th>II/SLW<br/>(PALDAM)</th>
                    <th>III/DIP<br/>(PALDAM)</th>
                    <th>IV/BRW<br/>(PALDAM)</th>
                    <th>V/MLW<br/>(PALDAM)</th>
                    <th>VI/UDY<br/>(PALDAM)</th>
                    <th>IX/TPR<br/>(PALDAM)</th>
                    <th>JUMLAH</th>
                    <th>KET</th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                <td>1</td>
                <td>Mu. Kal. 9 mm (Mu-1 Tj)</td>
                <td>Butir</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                </tr>
                <tr>
                <td>2</td>
                <td>SLT C 90 mm CRBR Spanyol</td>
                <td>Butir</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                </tr>
                <tr>
                <td>3</td>
                <td>Proy 155 KH-179 Type HE M107 Spanyol</td>
                <td>Butir</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                </tr>
                <tr>
                <td>4</td>
                <td>Fuze PDM-557 C1</td>
                <td>Butir</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                </tr>

                </tbody>
              </Table>


              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>NAMA JENIS</th>
                    <th>SAT</th>
                    <th>DIA PUSAT</th>
                    <th>XII/MDK<br/>(PALDAM)</th>
                    <th>XIV/HSN<br/>(PALDAM)</th>
                    <th>XVI/PTM<br/>(PALDAM)</th>
                    <th>XVII/CEN<br/>(PALDAM)</th>
                    <th>XVII/BB<br/>(PALDAM)</th>
                    <th>XVIII/BB<br/>(PALDAM)</th>
                    <th>JAYA<br/>(PALDAM)</th>
                    <th>IM<br/>(PALDAM)</th>
                    <th>JUMLAH</th>
                    <th>KET</th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                <td>1</td>
                <td>Mu. Kal. 9 mm (Mu-1 Tj)</td>
                <td>Butir</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                </tr>
                <tr>
                <td>2</td>
                <td>SLT C 90 mm CRBR Spanyol</td>
                <td>Butir</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                </tr>
                <tr>
                <td>3</td>
                <td>Proy 155 KH-179 Type HE M107 Spanyol</td>
                <td>Butir</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                </tr>
                <tr>
                <td>4</td>
                <td>Fuze PDM-557 C1</td>
                <td>Butir</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                </tr>
                </tbody>
              </Table>

              </PortletBody>
            </Portlet>



        </div>
      </div>

    </div>
</div>








    </>
  );
}
