import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import { put, takeLatest } from "redux-saga/effects";

export const actionTypes = {
  LIST_AREA_SERVICE: "area_service/List",
};

const initialAuthState = {
  results: [],
  count: '',
  record: undefined
};

export const reducer = persistReducer(
    { storage, key: "sisfolog-area_service", whitelist: ["results", "count", "record"] },
    (state = initialAuthState, action) => {
      switch (action.type) {
        case actionTypes.LIST_AREA_SERVICE: {
          const { results, count } = action.payload;
          return { results, count };
        }

        default:
          return state;
      }
    }
);

export const states = (state) => (
  {
    area_service_options: state.global.area_service_options,
    kotama_options: state.global.kotama_options,
    satker_options: state.global.satker_options,
    satyanis_options: state.global.satyanis_options,
    datum: state.area_service.results,
    count: state.area_service.count,
  }
);

export const actions = {
  getAreaServiceData: (results, count) => ({ type: actionTypes.LIST_AREA_SERVICE, payload: { results, count} })
};

export function* saga() {
  // yield takeLatest(actionTypes.Login, function* loginSaga() {
  //   console.log('worker login')
  //   yield put(actions.requestUser());
  // });

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   console.log('worker register')
  //   yield put(actions.requestUser());
  // });

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const { data: user } = yield getUserByToken();
  //   const respons = yield getUserByToken();
  //   console.log('worker user requested')
  //   console.log(user)
  //   console.log(respons)

  //   yield put(actions.fulfillUser(user));
  // });
}
