import React, { useState } from 'react';
import CardPage from "../../../partials/content/CodeExample";
import ReactTable from "react-table";
import "react-table/react-table.css";

import { doList } from "../../../crud/tktmin.crud";
export default function Tm() {
         const [data, setData] = useState([]);
         const [pages,setPages]=useState(1);
         const [datas, setDatas] = useState([]);
         const [pagesize,setPagesize]=useState(0);
         const [sort,setSort]=useState([]);
         const [filter,setFilter]=useState([]);
         const [totalpages,setTotalpages]=useState(1);
         const [id,setId]=useState(0);

         const [loading,setLoading]=useState(false);





           function fetchData(state, instance) {
              doList(((state.page)+1),state.pageSize,state.sorted,state.filtered)
              .then(res => res.data)
               .then(
                 (result) => {
                   var totalpage=Math.ceil(parseInt(result.count)/parseInt(state.pageSize));
                   setData(result.results)
                   setTotalpages(totalpage)
                   setPages(state.page)
                   setLoading(false)
                   setPagesize(state.pageSize)
                   setSort(state.sorted)
                   setFilter(state.filtered)
                 }
               ).catch(error => {
                     console.log(error)
                })

           }



           //const { data, pages, loading, fetchData } = this.props;
          return (

            <div className="row">

              <div className="col-md-12">

              <CardPage beforeCodeTitle="Data Transfer Masuk">
                <div className="kt-section">
                  <ReactTable
                       data={data}
                       pages={totalpages}
                       columns={[
                         {
                           Header: "Tanggal Terima",
                           accessor: "tgl_kirim"
                         },{
                             Header: "Tujuan",
                             accessor: "satpor_src.nm_satpor"
                           },{
                               Header: "Alamat",
                               accessor: "satpor_src.alamat"
                             },
                                {
                                  Header: "Latitude",
                                  accessor: "satpor_src.latitude"
                                },
                                 {
                                   Header: "Longitude",
                                   accessor: "satpor_src.longitude"
                                 }

                            ]}
                            defaultSorted={[
                              {
                                id: "lokasi",
                                desc: false
                              }
                            ]}
                     defaultPageSize={10}
                     className="-striped -highlight"
                     loading={loading}
                     showPagination={true}
                     showPaginationTop={true}
                     showPaginationBottom={true}

                     pageSizeOptions={[10]}
                     manual // this would indicate that server side pagination has been enabled
                     onFetchData={fetchData}
                     />
                     </div>
                     </CardPage>
                   </div>
               </div>
         );
     }
