import React, { useState, useEffect } from 'react';
const URL_API_BIDANG="http://localhost:4300/bidang/list";
 export default function CmbBidang(props) {
 const [datas, setDatas] = useState([]);
 const [wait,setWait]=useState(0);
 useEffect(() => {
     fetch(URL_API_BIDANG,{
       method: 'get'
     })
       .then(res => res.json())
       .then(
         (result) => {
           setDatas(result.data)
       }
       )
     },[wait]);

     return (
       <>

       {datas.map((data,i) => (


         <option value={data.idBidang} selected={props.idBidang==data.idBidang}>{data.namaBidang}</option>
         ))}

        </>

     )


}
