import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import {
  Button,
  Modal,
  Col,
  Row,
  Form
} from "react-bootstrap";
import CardPage from "../../../partials/content/CardPage";
import { connect } from "react-redux";

import * as auth from "../../../store/ducks/auth.duck";



 export const Profile = ({user}) =>  {
     return (
       <>
       <div className="row">

         <div className="col-md-8">

         <CardPage beforeCodeTitle="Profile User">
           <div className="kt-section">

           <Form>

             <Form.Group controlId="formName">
               <Form.Label><FormattedMessage id="USER.NAME" /></Form.Label>
               <Form.Control type="text" value={user.nama}/>
             </Form.Group>
             <Form.Group controlId="formEmail">
               <Form.Label><FormattedMessage id="USER.EMAIL" /></Form.Label>
               <Form.Control type="text" value={user.email} />
             </Form.Group>
             <Form.Group controlId="formPangkat">
               <Form.Label><FormattedMessage id="USER.PANGKAT" /></Form.Label>
               <Form.Control type="text" value={user.pangkat} />
             </Form.Group>
             <Form.Group controlId="formDob">
               <Form.Label><FormattedMessage id="USER.DOB"  /></Form.Label>
               <Form.Control type="text" value={user.dob} />
             </Form.Group>
             <Form.Group controlId="formTelp">
               <Form.Label><FormattedMessage id="USER.TELP" /></Form.Label>
               <Form.Control type="text" value={user.telp}  />
             </Form.Group>

          </Form>

             </div>
             </CardPage>
             </div>
             </div>


     </>

     )
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});
export default connect(mapStateToProps)(Profile);
