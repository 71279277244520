import React, { useState, useEffect } from "react"
import { useFormik } from "formik";
import * as Yup from 'yup';
import { connect } from "react-redux";
import { 
  FormattedMessage, 
  injectIntl 
} from "react-intl";
import clsx from "clsx";
import * as table_option from "../../../store/ducks/admin/table-option.duck";
import * as global from "../../../store/ducks/global.duck";
import { doAdd, doUpdate } from "../../../crud/admin/table-option.crud";
import { doList as doListTable } from "../../../crud/admin/table.crud";
import AlertForm from "../../../components/AlertForm";
import { Modal } from "react-bootstrap";
import TextFieldGroup from "../../../components/form/TextFieldGroup";
import { validationErrorMessage } from "../../../../_metronic/utils/helper";

function Form(props) {
    const { show, closeModal, data, reloadData } = props;
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({open: false, type: 'success', message: ''})
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
      paddingRight: "2.5rem"
    });

    const enableLoading = () => {
      setLoading(true);
      setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
      setLoading(false);
      setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };

    const closeModalHandler = () => {
        closeModal();
        setAlert({open:false})
    };

    const loadTableOptions = () => {
      doListTable({ordering: 'name', page_size: 9999999})
        .then(({ data: { results } }) => {
          props.getTableOptions(results);
        }
      )
    }

    useEffect(() => {
      loadTableOptions()
      // eslint-disable-next-line
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            code: data ? data.code : '',
            name: data ? data.name : '',
            active: data ? data.active : '',
            table: data ? data.table.id : ''
        },
        validationSchema: Yup.object({
            code: Yup.string()
              .max(2, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 2}} />)
              .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            name: Yup.string()
              .max(20, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 20}} />)
              .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            table: Yup.number()
                .integer(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />)
                .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
        }),
        onSubmit: (values, { resetForm }) => {
            enableLoading();
            if(data === undefined){
                doAdd(values)
                .then(({ data }) => {
                    resetForm();
                    disableLoading();
                    reloadData();
                    setAlert({open: true, type: 'success', message: 'Tambah data berhasil.'})
                })
                .catch((err) => {
                    disableLoading();
                    let errorMessage = validationErrorMessage(err)
                    setAlert({open: true, type: 'danger', message: `Tambah data gagal. ${errorMessage}`})
                });
            } else {
                doUpdate(values, data.id)
                .then(({ data }) => {
                    disableLoading();
                    reloadData();
                    setAlert({open: true, type: 'success', message: 'Simpan data berhasil.'})
                })
                .catch((err) => {
                    disableLoading();
                    let errorMessage = validationErrorMessage(err)
                    setAlert({open: true, type: 'danger', message: `Simpan data gagal. ${errorMessage}`})
                });
            }
        }
    });

    return (
        <Modal
            size="md"
            show={show}
            onHide={() => closeModalHandler()}
            aria-labelledby="example-modal-sizes-title-md"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-md">
                    {data ? 'Edit' : 'Tambah'} data table option
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                    noValidate={true}
                    autoComplete="off"
                    className="kt-form"
                    onSubmit={formik.handleSubmit}
                >
                    <AlertForm 
                        show={alert.open}
                        variant={alert.type}
                        msg={alert.message}
                        closeAlert={() => setAlert({open: false})}
                    />

                        <TextFieldGroup
                            select={true}
                            margin="normal"
                            label="Table"
                            className="kt-width-full"
                            name="table"
                            {...formik.getFieldProps('table')}
                            errorText={formik.touched.table && formik.errors.table}
                            error={Boolean(formik.touched.table && formik.errors.table)}
                        >
                        <option>Pilih Table</option>
                            { props.table_options && props.table_options.map(table => (<option key={table.id} value={table.id}>{table.name}</option>)) }
                        </TextFieldGroup>
                        <TextFieldGroup
                            type="text"
                            label="Code"
                            margin="normal"
                            className="kt-width-full"
                            name="code"
                            {...formik.getFieldProps('code')}
                            errorText={formik.touched.code && formik.errors.code}
                            error={Boolean(formik.touched.code && formik.errors.code)}
                        />
                        <TextFieldGroup
                            type="text"
                            margin="normal"
                            label="Name"
                            className="kt-width-full"
                            name="name"
                            {...formik.getFieldProps('name')}
                            errorText={formik.touched.name && formik.errors.name}
                            error={Boolean(formik.touched.name && formik.errors.name)}
                        />
                        <TextFieldGroup
                            select={true}
                            margin="normal"
                            label="Active"
                            className="kt-width-full"
                            name="active"
                            {...formik.getFieldProps('active')}
                            errorText={formik.touched.active && formik.errors.active}
                            error={Boolean(formik.touched.active && formik.errors.active)}
                        >
                            <option value={1}>Ya</option>
                            <option value={0}>Tidak</option>
                        </TextFieldGroup>

                    <div className="kt-login__actions">

                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={loading}
                        className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                            "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                        }
                        )}`}
                        style={loadingButtonStyle}
                    >
                        { data ? 'Update' : 'Simpan'}
                    </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

const { getTableOptions } = global.actions

export default injectIntl(
    connect(
      table_option.states,
      {...table_option.actions, getTableOptions}
    )(Form)
  );
