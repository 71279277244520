import React, { useEffect } from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import Header from "./header/Header";
import SubHeader from "./sub-header/SubHeader";
import HeaderMobile from "./header/HeaderMobile";
import AsideLeft from "./aside/AsideLeft";
import Footer from "./footer/Footer";
import ScrollTop from "../../app/partials/layout/ScrollTop";
// import StickyToolbar from "../../app/partials/layout/StickyToolbar";
import HTMLClassService from "./HTMLClassService";
import LayoutConfig from "./LayoutConfig";
import MenuConfig from "./MenuConfig";
import LayoutInitializer from "./LayoutInitializer";
import QuickPanel from "../../app/partials/layout/QuickPanel";
import KtContent from "./KtContent";
import "./assets/Base.scss";
import * as global from "../../app/store/ducks/global.duck";
import { doList as doListSatyanis } from "../../app/crud/admin/satyanis.crud";

const htmlClassService = new HTMLClassService();

const inputAssetMenu = (satyanis_options) => {
  let satMenu = []
  satyanis_options.map(sat => {
    let komMenu = []
    sat.top_komoditi_satyanis.map(k => {
      komMenu.push({
        title: k.nm_komoditi,
        bullet: "dot",
        submenu: [
            {
              title: "Aset Markas",
              bullet: "dot",
              page: `list/aset/${sat.id}/${k.id}`
            },
            {
              title: "Aset Area Layanan",
              bullet: "dot",
              page: `list/area-layanan/${sat.id}/${k.id}`
            }
          ]
      })
      return k
    })
    satMenu.push({
      title: sat.nm_satyanis,
      bullet: "dot",
      submenu: komMenu
    })
    return sat
  })
  return satMenu
}

const satyanisMenu = (prevUrl, satyanis_options) => {
  let satMenu = []
  satyanis_options.map(sat => {
    let komMenu = []
    sat.top_komoditi_satyanis.map(k => {
      komMenu.push({
        title: k.nm_komoditi,
        bullet: "dot",
        page: `${prevUrl}/${sat.id}/${k.id}`
      })
      return k
    })
    satMenu.push({
      title: sat.nm_satyanis,
      bullet: "dot",
      submenu: komMenu
    })
    return sat
  })
  return satMenu
}

function Layout({
  auth,
  satyanis_options,
  getSatyanisOptions,
  children,
  asideDisplay,
  subheaderDisplay,
  selfLayout,
  layoutConfig,
  contentContainerClasses
}) {


  const loadSatyanisOptions = () => {
    doListSatyanis({ordering: 'nm_satyanis', page_size: 9999999})
      .then(({ data: { results } }) => {
        getSatyanisOptions(results);
      }
    )
  }

  useEffect(() => {
    loadSatyanisOptions()
    // eslint-disable-next-line
  }, []);

  htmlClassService.setConfig(layoutConfig);
  // scroll to top after location changes
  // window.scrollTo(0, 0);

  const contentCssClasses = htmlClassService.classes.content.join(" ");
  
  
  // change menu 
  MenuConfig.aside.items[3].submenu = satyanisMenu('top/dspp', satyanis_options)
  MenuConfig.aside.items[8].submenu = inputAssetMenu(satyanis_options)
  MenuConfig.aside.items[11].submenu[0].submenu = satyanisMenu('distribusi/perencanaan', satyanis_options)
  MenuConfig.aside.items[13].submenu = satyanisMenu('distribusi-top/dspp', satyanis_options)
  MenuConfig.aside.items[14].submenu = satyanisMenu('laporan-top/dspp', satyanis_options)
  
  return selfLayout !== "blank" ? (
    <LayoutInitializer
      menuConfig={MenuConfig}
      layoutConfig={LayoutConfig}
      htmlClassService={htmlClassService}
    >
      {/* <!-- begin:: Header Mobile --> */}
      <HeaderMobile />
      {/* <!-- end:: Header Mobile --> */}

      <div className="kt-grid kt-grid--hor kt-grid--root">
        {/* <!-- begin::Body --> */}
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
          {/* <!-- begin:: Aside Left --> */}
          {asideDisplay && (
            <>
              <AsideLeft />
            </>
          )}
          {/* <!-- end:: Aside Left --> */}
          <div
            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
            id="kt_wrapper"
          >
            {/* <!-- begin:: Header READY --> */}

            <Header />
            {/* <!-- end:: Header --> */}

            {/* <!-- begin:: Content --> */}
            <div
              id="kt_content"
              className={`kt-content ${contentCssClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
            >
              {/* <!-- begin:: Content Head --> */}
              {subheaderDisplay && (
                <SubHeader />
              )}
              {/* <!-- end:: Content Head --> */}

              {/* <!-- begin:: Content Body --> */}
              {/* TODO: add class to animate  kt-grid--animateContent-finished */}
              <KtContent>
                {children}
              </KtContent>
              {/*<!-- end:: Content Body -->*/}
            </div>
            {/* <!-- end:: Content --> */}
            <Footer />
          </div>
        </div>
        {/* <!-- end:: Body --> */}
      </div>
      <QuickPanel />
      <ScrollTop />
      {/* <StickyToolbar /> */}
    </LayoutInitializer>
  ) : (
    // BLANK LAYOUT
    <div className="kt-grid kt-grid--ver kt-grid--root">
      <KtContent>
        {children}
      </KtContent>
    </div>
  );
}

const { getSatyanisOptions } = global.actions
const mapStateToProps = ({ builder: { layoutConfig }, auth, global: { satyanis_options } }) => ({
  auth,
  satyanis_options,
  layoutConfig,
  selfLayout: objectPath.get(layoutConfig, "self.layout"),
  asideDisplay: objectPath.get(layoutConfig, "aside.self.display"),
  subheaderDisplay: objectPath.get(layoutConfig, "subheader.display"),
  desktopHeaderDisplay: objectPath.get(
    layoutConfig,
    "header.self.fixed.desktop"
  ),
  contentContainerClasses: ""
  // contentContainerClasses: builder.selectors.getClasses(store, {
  //   path: "content_container",
  //   toString: true
  // })
});

export default connect(mapStateToProps, {getSatyanisOptions})(Layout);
