import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "./Builder";
import DashboardRanpur from "../dashboard/Ranpur";
import DashboardPesawat from "../dashboard/Pesawat";
import DashboardBackup from "./DashboardBackup";

import DashboardFungsi from "../dashboard/Fungsi";

import Persetujuan from "../persetujuan/Aksi";

import TopDspp from "../dashboard/Dspp";


import LaporanDistribusi from "../laporan/Distribusi";

import Cctv from "./portal/Cctv";
import Profile from "./user/Profile";
import DocsPage from "./docs/DocsPage";
import { LayoutSplashScreen } from "../../../_metronic";
import PenggunaKelompok from "../admin/pengguna-kelompok";
import Pengguna from "../admin/pengguna";
import AreaService from "../admin/area-service";
import Kotama from "../admin/kotama";
import Satker from "../admin/satker";
import Propinsi from "../admin/propinsi";
import Kabupaten from "../admin/kabupaten";
import Kecamatan from "../admin/kecamatan";
import Lurah from "../admin/lurah";
import Alamat from "../admin/alamat";
import Asal from "../admin/asal";
import BentukBgn from "../admin/bentuk-bgn";
import Bukti from "../admin/bukti";
import Air from "../admin/air";
import Gas from "../admin/gas";
import Kondisi from "../admin/kondisi";
import Konstruksi from "../admin/konstruksi";
import Listrik from "../admin/listrik";
import Status from "../admin/status";
import Table from "../admin/table";
import TableOption from "../admin/table-option";
import TopKelompok from "../admin/top-kelompok";
import TopKomoditi from "../admin/top-komoditi";
import TopFungsi from "../admin/top-fungsi";
import TopJenis from "../admin/top-jenis";
import TopMerk from "../admin/top-merk";
import TopSatuan from "../admin/top-satuan";
import Satyanis from "../admin/satyanis";
import Tktmin from "../distribusi/tm/Index";
import Satpor from "../admin/Satpor";
import FilterAset from "../aset/Filter";
import SebaranAset from "../aset/Sebaran";
import AreaLayanan from "../aset/AreaLayanan";
import Nyata from "../transaction/nyata";
import NyataAreaService from "../transaction/nyata/NyataAreaService";
import Detail from "../transaction/nyata/Detail";


const GoogleMaterialPage = lazy(() =>
  import("./google-material/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./react-bootstrap/ReactBootstrapPage")
);

export default function HomePage() {
  // useEffect(() => {
  //   console.log('Home page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard/fungsi" />
        }
        <Route path="/builder" component={Builder} />
        <Route path="/dashboard/ranpur" component={DashboardRanpur} />
        <Route path="/dashboard/pesawat" component={DashboardPesawat} />

        <Route path="/dashboard/fungsi" component={DashboardFungsi} />



        <Route path="/top/dspp" component={TopDspp} />


        <Route path="/dashboardbackup" component={DashboardBackup} />

        <Route path="/cctv" component={Cctv} />

        <Route path="/user/profile" component={Profile} />
        {/* <Route path="/detail/aset/:id" component={DetailAset} /> */}
        <Route path="/filter/aset/" component={FilterAset} />

        <Route path="/sebaran/aset/:id" component={SebaranAset} />

        {/* <Route path="/list/aset/" component={ListAset} /> */}
        <Route path="/list/aset/:satyanis/:komoditi" component={Nyata} />
        <Route path="/list/area-layanan/:satyanis/:komoditi" component={NyataAreaService} />
        <Route path="/list/arealayanan/" component={AreaLayanan} />
        <Route path="/detail/aset/:nyata_id" component={Detail} />


        <Route path="/admin/propinsi" component={Propinsi} />
        <Route path="/admin/kabupaten" component={Kabupaten} />
        <Route path="/admin/kecamatan" component={Kecamatan} />
        <Route path="/admin/lurah" component={Lurah} />
        <Route path="/admin/alamat" component={Alamat} />


        <Route path="/admin/asal" component={Asal} />
        <Route path="/admin/bentuk-bangunan" component={BentukBgn} />
        <Route path="/admin/bukti" component={Bukti} />
        <Route path="/admin/gas" component={Gas} />
        <Route path="/admin/air" component={Air} />
        <Route path="/admin/kondisi" component={Kondisi} />
        <Route path="/admin/konstruksi" component={Konstruksi} />
        <Route path="/admin/listrik" component={Listrik} />
        <Route path="/admin/status" component={Status} />

        <Route path="/admin/table" component={Table} />
        <Route path="/admin/table-option" component={TableOption} />

        <Route path="/admin/top-kelompok" component={TopKelompok} />
        <Route path="/admin/top-komoditi" component={TopKomoditi} />
        <Route path="/admin/top-fungsi" component={TopFungsi} />
        <Route path="/admin/top-jenis" component={TopJenis} />
        <Route path="/admin/top-merk" component={TopMerk} />


        <Route path="/admin/pengguna-kelompok" component={PenggunaKelompok} />
        <Route path="/admin/pengguna" component={Pengguna} />
        <Route path="/admin/area-service" component={AreaService} />
        <Route path="/admin/kotama" component={Kotama} />
        <Route path="/admin/satker" component={Satker} />
        <Route path="/admin/satpor" component={Satpor} />
        <Route path="/admin/top-satuan" component={TopSatuan} />
        <Route path="/admin/satyanis" component={Satyanis} />


        <Route path="/laporan/distribusi/" component={LaporanDistribusi} />

        <Route path="/persetujuan/aksi" component={Persetujuan} />

        <Route path="/distribusi/tm" component={Tktmin} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/docs" component={DocsPage} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
