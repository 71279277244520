import React, { useState, useRef } from "react";
// import { Link } from "react-router-dom";
import ReactTable from "react-table";
import { connect } from "react-redux";
import { 
  // FormattedMessage, 
  injectIntl 
} from "react-intl";
import { Portlet, PortletHeader, PortletHeaderToolbar, PortletBody } from "../../../partials/content/Portlet";
import * as pengguna_kelompok from "../../../store/ducks/admin/pengguna-kelompok.duck";
import { doList, doDelete } from "../../../crud/admin/pengguna-kelompok.crud";
import ConfirmDelete from "../../../components/ConfirmDelete";
import AlertForm from "../../../components/AlertForm";
import Form from "./Form";
import { Button, InputBase, Divider, IconButton } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 8,
  }
});

function PenggunaKelompok(props) {
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [confirmDelete, setConfirmDelete] = useState({
      open: false,
      id: null
    });
    const [form, setForm] = useState({
      open: false,
      data: null
    });
    const [alert, setAlert] = useState({open: false, type: 'success', message: ''})
    const [totalpages, setTotalpages] = useState(1);

    const reactTable = useRef();
    const classes = useStyles();

    const enableLoading = () => {
      setLoading(true);
    };

    const disableLoading = () => {
      setLoading(false);
    };

    const reloadTable = () => reactTable.current && reactTable.current.fireFetchData()
    
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        reloadTable()
      }
    }

    function fetchData(state) {
      enableLoading();
      const ordering = state.sorted.map(next => ((next.desc ? '-' : '') + next.id)).join(',')
      const params = {
        ordering: ordering ? ordering : '-kd_kelompok',
        search: search,
        page: state.page + 1,
        page_size: state.pageSize
      }
      doList(params)
        .then(({ data: { results, count } }) => {
            disableLoading();
            let totalpage=Math.ceil(parseInt(count)/parseInt(state.pageSize));
            setTotalpages(totalpage)
            props.getPenggunaKelompokData(results, count);
          }
        ).catch(error => {
          disableLoading();
        })
    }

    function handleDelete() {
      setConfirmDelete({open:false})
      setAlert({open: false})
      enableLoading();
      doDelete(confirmDelete.id).then(response => {
        setAlert({open: true, type: 'success', message: 'Hapus data berhasil.'})
        disableLoading();
        reloadTable();
      }).catch(error => {
        setAlert({open: true, type: 'danger', message: 'Hapus data gagal.'})
        disableLoading();
      })
    }
    
    const columns = [
      {
        Header: "Kode Kelompok",
        accessor: "kd_kelompok"
      },
      {
        Header: "Nama Kelompok",
        accessor: "nm_kelompok"
      },
      {
        Header: "Aksi",
        accessor: "id",
        Cell: ({ original }) => (
          <div style={{ textAlign: "center" }}>
            <IconButton onClick={(e) => setForm({open: true, data: original})} aria-label="Edit" size="small">
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton onClick={(e) => setConfirmDelete({open: true, id: original.id})} aria-label="Delete" size="small">
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </div>
        )
      }
    ]

    return (
      <Portlet>
        <PortletHeader
          title={'Pengguna Kelompok'}
          toolbar={(
              <PortletHeaderToolbar>
                <InputBase
                  className={classes.input}
                  placeholder="Cari Pengguna Kelompok"
                  inputProps={{ 'aria-label': 'Cari Pengguna Kelompok' }}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e)}
                />
                <IconButton className={classes.iconButton} aria-label="Search" onClick={() => reloadTable()}>
                  <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} />
                <Button variant="contained" color="secondary" onClick={() => setForm({open: true})}><i className="kt-nav__link-icon flaticon2-add" /> Tambah</Button>
              </PortletHeaderToolbar>
            )
          }
        />
        <PortletBody>
          <ConfirmDelete
            handleClose={() => setConfirmDelete({open:false})}
            handleDelete={() => handleDelete()}
            show={confirmDelete.open}
          />
          <AlertForm 
            show={alert.open}
            variant={alert.type}
            msg={alert.message}
            closeAlert={() => setAlert({open: false})}
          />
          <ReactTable
            ref={reactTable}
            data={props.datum}
            pages={totalpages}
            columns={columns}
            defaultPageSize={10}
            className="-striped -highlight"
            loading={loading}
            showPagination={true}
            pageSizeOptions={[10, 20, 25, 50, 100]}
            manual // this would indicate that server side pagination has been enabled
            onFetchData={fetchData}
          />
          <Form
            show={form.open}
            closeModal={() => setForm({open: false, data: null})}
            data={form.data}
            reloadData={() => reloadTable()}
          />
        </PortletBody>
      </Portlet>
    )
}


export default injectIntl(
    connect(
      pengguna_kelompok.states,
      pengguna_kelompok.actions
    )(PenggunaKelompok)
  );
