import React, { useState, useRef, useEffect } from "react";
// import { Link } from "react-router-dom";
import ReactTable from "react-table";
import { connect } from "react-redux";
import { 
  // FormattedMessage, 
  injectIntl 
} from "react-intl";
import { Portlet, PortletHeader, PortletHeaderToolbar, PortletBody } from "../../../partials/content/Portlet";
import * as area_service from "../../../store/ducks/admin/area-service.duck";
import * as global from "../../../store/ducks/global.duck";
// import { doList as doListKotama } from "../../../crud/admin/kotama.crud";
import { doList as doListSatker } from "../../../crud/admin/satker.crud";
import { doList as doListSatyanis } from "../../../crud/admin/satyanis.crud";
import { doList, doDelete } from "../../../crud/admin/area-service.crud";
import ConfirmDelete from "../../../components/ConfirmDelete";
import AlertForm from "../../../components/AlertForm";
import Form from "./Form";
import { Button, InputBase, Divider, IconButton, Typography, Grid, Card, CardContent } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TextFieldGroup from "../../../components/form/TextFieldGroup";

const useStyles = makeStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 8,
  },
});

function AreaService(props) {
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState({
      parent: '',
      kotama: '',
      satker: '',
      satyanis: ''
    });
    const [confirmDelete, setConfirmDelete] = useState({
      open: false,
      id: null
    });
    const [form, setForm] = useState({
      open: false,
      data: null
    });
    const [alert, setAlert] = useState({open: false, type: 'success', message: ''})
    const [totalpages, setTotalpages] = useState(1);
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
      paddingRight: "2.5rem"
    });

    const reactTable = useRef();
    const classes = useStyles();

    const enableLoading = () => {
      setLoading(true);
      setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
      setLoading(false);
      setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };

    const reloadTable = () => reactTable.current && reactTable.current.fireFetchData()
    
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        reloadTable()
      }
    }

    function fetchData(state) {
      enableLoading();
      const ordering = state.sorted.map(next => ((next.desc ? '-' : '') + next.id)).join(',')
      const params = {
        parent__id: filter.parent !== 'all' ? filter.parent : '',
        kotama__id: filter.kotama !== 'all' ? filter.kotama : '',
        satyanis__id: filter.satyanis !== 'all' ? filter.satyanis : '',
        ordering: ordering ? ordering : '-parent__nm_satpor',
        search: search,
        page: state.page + 1,
        page_size: state.pageSize
      }
      doList(params)
        .then(({ data: { results, count } }) => {
            disableLoading();
            let totalpage=Math.ceil(parseInt(count)/parseInt(state.pageSize));
            setTotalpages(totalpage)
            props.getAreaServiceData(results, count);
          }
        ).catch(error => {
          disableLoading();
        })
    }

    function handleDelete() {
      setConfirmDelete({open:false})
      setAlert({open: false})
      enableLoading();
      doDelete(confirmDelete.id).then(response => {
        setAlert({open: true, type: 'success', message: 'Hapus data berhasil.'})
        disableLoading();
        reloadTable();
      }).catch(error => {
        setAlert({open: true, type: 'danger', message: 'Hapus data gagal.'})
        disableLoading();
      })
    }
    
    const loadAreaServiceOptions = (id_kotama) => {
      doList({ordering: 'parent__nm_satpor', kotama__id: id_kotama, page_size: 9999999})
          .then(({ data: { results } }) => {
              props.getAreaServiceOptions(results);
          }
      )
    }

    // const loadKotamaOptions = () => {
    //   doListKotama({ordering: 'nm_kotama', page_size: 9999999})
    //       .then(({ data: { results } }) => {
    //           props.getKotamaOptions(results);
    //       }
    //   )
    // }

    const loadSatkerOptions = (id_kotama) => {
      enableLoading();
      doListSatker({ordering: 'kd_satpor', kotama__id: id_kotama, page_size: 9999999})
          .then(({ data: { results } }) => {
              props.getSatkerOptions(results);
              disableLoading();
          }
      ).catch(error => {
        disableLoading();
      })
    }

    const loadSatyanisOptions = (id_kotama) => {
      doListSatyanis({ordering: 'nm_satyanis', kotama__id: id_kotama, page_size: 9999999})
          .then(({ data: { results } }) => {
              props.getSatyanisOptions(results);
          }
      )
    }

    useEffect(() => {
      // loadAreaServiceOptions()
      // eslint-disable-next-line
    }, []);

    const columns = [
      {
        Header: "Kotama",
        accessor: "kotama.nm_kotama",
      },
      {
        Header: "Satyanis",
        accessor: "satyanis.nm_satyanis"
      },
      {
        Header: "Parent",
        accessor: "parent.nm_satpor",
      },
      {
        Header: "Satker",
        accessor: "satpor.nm_satpor"
      },
      {
        Header: "Aksi",
        accessor: "id",
        Cell: ({ original }) => (
          <div style={{ textAlign: "center" }}>
            <IconButton onClick={(e) => {
              loadSatyanisOptions(original.kotama.id)
              loadAreaServiceOptions(original.kotama.id)
              loadSatkerOptions(original.kotama.id)
              setForm({open: true, data: original})
            }} aria-label="Edit" size="small">
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton onClick={(e) => setConfirmDelete({open: true, id: original.id})} aria-label="Delete" size="small">
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </div>
        )
      }
    ]

    return (
      <Portlet>
        <PortletHeader
          title={'Area Service'}
          toolbar={(
              <PortletHeaderToolbar>
                <InputBase
                  className={classes.input}
                  placeholder="Cari Area Service"
                  inputProps={{ 'aria-label': 'Cari Area Service' }}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e)}
                />
                <IconButton className={classes.iconButton} aria-label="Search" onClick={() => reloadTable()}>
                  <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} />
                <Button variant="contained" color="secondary" onClick={() => setForm({open: true})}><i className="kt-nav__link-icon flaticon2-add" /> Tambah</Button>
              </PortletHeaderToolbar>
            )
          }
        />
        <PortletBody>
          <ConfirmDelete
            handleClose={() => setConfirmDelete({open:false})}
            handleDelete={() => handleDelete()}
            show={confirmDelete.open}
          />
          <AlertForm 
            show={alert.open}
            variant={alert.type}
            msg={alert.message}
            closeAlert={() => setAlert({open: false})}
          />
          <Card>
            <CardContent>
              <Typography variant="h6" component="h6">
                Filter
              </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <TextFieldGroup
                        select={true}
                        margin="normal"
                        label="Kotama"
                        className="kt-width-full"
                        onChange={(e) => {
                          setFilter({...filter, kotama: e.target.value})
                          loadSatyanisOptions(e.target.value)
                          loadAreaServiceOptions(e.target.value)
                        }}
                        value={filter.kotama}
                    >
                      <option key={0} value="all">Semua</option>
                      { props.kotama_options && props.kotama_options.map(kotama => (<option key={kotama.id} value={kotama.id}>{kotama.nm_kotama}</option>)) }
                    </TextFieldGroup>
                    <button
                      disabled={loading}
                      className={`btn btn-primary btn-elevate btn-sm ${clsx(
                      {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                      )}`}
                      style={loadingButtonStyle}
                      onClick={() => reloadTable()}
                    >
                    Filter
                  </button>
                </Grid>
                <Grid item xs={3}>
                  <TextFieldGroup
                      select={true}
                      margin="normal"
                      label="Satyanis"
                      className="kt-width-full"
                      onChange={(e) => setFilter({...filter, satyanis: e.target.value})}
                      value={filter.satyanis}
                  >
                    <option key={0} value="all">Semua</option>
                    { props.satyanis_options && props.satyanis_options.map(satyanis => (<option key={satyanis.id} value={satyanis.id}>{satyanis.nm_satyanis}</option>)) }
                  </TextFieldGroup>
                </Grid>
                <Grid item xs={3}>
                  <TextFieldGroup
                    select={true}
                    margin="normal"
                    label="Parent Area Service"
                    className="kt-width-full"
                    onChange={(e) => {
                      setFilter({...filter, parent: e.target.value})
                    }}
                    value={filter.parent}
                  >
                    <option key={0} value="all">Semua</option>
                    { props.area_service_options && props.area_service_options.map(area_service => (<option key={area_service.id} value={area_service.satpor.id}>{area_service.satpor.nm_satpor}</option>)) }
                  </TextFieldGroup>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <br/>
          <ReactTable
            ref={reactTable}
            data={props.datum}
            pages={totalpages}
            columns={columns}
            defaultPageSize={10}
            className="-striped -highlight"
            loading={loading}
            showPagination={true}
            pageSizeOptions={[10, 20, 25, 50, 100]}
            manual // this would indicate that server side pagination has been enabled
            onFetchData={fetchData}
          />
          <Form
            show={form.open}
            closeModal={() => setForm({open: false, data: null})}
            data={form.data}
            reloadData={() => reloadTable()}
            loadSatkerOptions={(id_kotama) => loadSatkerOptions(id_kotama)}
            loadSatyanisOptions={(id_kotama) => loadSatyanisOptions(id_kotama)}
            loadAreaServiceOptions={(id_kotama) => loadAreaServiceOptions(id_kotama)}
            loading={loading}
          />
        </PortletBody>
      </Portlet>
    )
}

const { getAreaServiceOptions, getKotamaOptions, getSatkerOptions, getSatyanisOptions } = global.actions

export default injectIntl(
    connect(
      area_service.states,
      { ...area_service.actions, getAreaServiceOptions, getKotamaOptions, getSatkerOptions, getSatyanisOptions }
    )(AreaService)
  );
