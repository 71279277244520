import React from 'react';
import { Alert } from 'react-bootstrap';

export default function AlertForm(props) {
  return (
    <>
      <Alert show={props.show} variant={props.variant} onClose={() => props.closeAlert()} dismissible>
        <span dangerouslySetInnerHTML={{ __html: props.msg }} />
      </Alert>
    </>
  );
}
