import React, { useState, useEffect } from "react"
import { useFormik } from "formik";
import * as Yup from 'yup';
import { connect } from "react-redux";
import { 
  FormattedMessage, 
  injectIntl 
} from "react-intl";
import clsx from "clsx";
import * as pengguna from "../../../store/ducks/admin/pengguna.duck";
import * as global from "../../../store/ducks/global.duck";
import { doAdd, doUpdate } from "../../../crud/admin/pengguna.crud";
import { doList as doListPenggunaKelompok } from "../../../crud/admin/pengguna-kelompok.crud";
import AlertForm from "../../../components/AlertForm";
import { Modal } from "react-bootstrap";
import TextFieldGroup from "../../../components/form/TextFieldGroup";
import { validationErrorMessage } from "../../../../_metronic/utils/helper";

function Form(props) {
    const { show, closeModal, data, reloadData } = props;
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({open: false, type: 'success', message: ''})
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
      paddingRight: "2.5rem"
    });

    const enableLoading = () => {
      setLoading(true);
      setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
      setLoading(false);
      setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };

    const closeModalHandler = () => {
        closeModal();
        setAlert({open:false})
    };


    useEffect(() => {
        const loadPenggunaKelompokOptions = () => {
            doListPenggunaKelompok({ordering: 'nm_pengguna_kelompok', page_size: 9999999})
            .then(({ data: { results } }) => {
                props.getPenggunaKelompokOptions(results);
            }
            )
        }
      loadPenggunaKelompokOptions()
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            kd_pengguna: data ? data.kd_pengguna : '',
            nm_pengguna: data ? data.nm_pengguna : '',
            pengguna_kelompok: data ? data.pengguna_kelompok.id : ''
        },
        validationSchema: Yup.object({
            kd_pengguna: Yup.string()
              .max(2, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 2}} />)
              .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            nm_pengguna: Yup.string()
              .max(20, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 20}} />)
              .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            pengguna_kelompok: Yup.number()
                .integer(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />)
                .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
        }),
        onSubmit: (values, { resetForm }) => {
            enableLoading();
            if(data === undefined){
                doAdd(values)
                .then(({ data }) => {
                    resetForm();
                    disableLoading();
                    reloadData();
                    setAlert({open: true, type: 'success', message: 'Tambah data berhasil.'})
                })
                .catch((err) => {
                    disableLoading();
                    let errorMessage = validationErrorMessage(err)
                    setAlert({open: true, type: 'danger', message: `Tambah data gagal. ${errorMessage}`})
                });
            } else {
                doUpdate(values, data.id)
                .then(({ data }) => {
                    disableLoading();
                    reloadData();
                    setAlert({open: true, type: 'success', message: 'Simpan data berhasil.'})
                })
                .catch((err) => {
                    disableLoading();
                    let errorMessage = validationErrorMessage(err)
                    setAlert({open: true, type: 'danger', message: `Simpan data gagal. ${errorMessage}`})
                });
            }
        }
    });

    return (
        <Modal
            size="md"
            show={show}
            onHide={() => closeModalHandler()}
            aria-labelledby="example-modal-sizes-title-md"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-md">
                    {data ? 'Edit' : 'Tambah'} data pengguna
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                    noValidate={true}
                    autoComplete="off"
                    className="kt-form"
                    onSubmit={formik.handleSubmit}
                >
                    <AlertForm 
                        show={alert.open}
                        variant={alert.type}
                        msg={alert.message}
                        closeAlert={() => setAlert({open: false})}
                    />

                        <TextFieldGroup
                            select={true}
                            margin="normal"
                            label="Pengguna Kelompok"
                            className="kt-width-full"
                            name="pengguna_kelompok"
                            {...formik.getFieldProps('pengguna_kelompok')}
                            errorText={formik.touched.pengguna_kelompok && formik.errors.pengguna_kelompok}
                            error={Boolean(formik.touched.pengguna_kelompok && formik.errors.pengguna_kelompok)}
                        >
                        <option>Pilih Pengguna Kelompok</option>
                            { props.pengguna_kelompok_options && props.pengguna_kelompok_options.map(pengguna_kelompok => (<option key={pengguna_kelompok.id} value={pengguna_kelompok.id}>{pengguna_kelompok.nm_kelompok}</option>)) }
                        </TextFieldGroup>
                        <TextFieldGroup
                            type="text"
                            label="Kode Pengguna"
                            margin="normal"
                            className="kt-width-full"
                            name="kd_pengguna"
                            {...formik.getFieldProps('kd_pengguna')}
                            errorText={formik.touched.kd_pengguna && formik.errors.kd_pengguna}
                            error={Boolean(formik.touched.kd_pengguna && formik.errors.kd_pengguna)}
                        />
                        <TextFieldGroup
                            type="text"
                            margin="normal"
                            label="Nama Pengguna"
                            className="kt-width-full"
                            name="nm_pengguna"
                            {...formik.getFieldProps('nm_pengguna')}
                            errorText={formik.touched.nm_pengguna && formik.errors.nm_pengguna}
                            error={Boolean(formik.touched.nm_pengguna && formik.errors.nm_pengguna)}
                        />

                    <div className="kt-login__actions">

                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={loading}
                        className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                            "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                        }
                        )}`}
                        style={loadingButtonStyle}
                    >
                        { data ? 'Update' : 'Simpan'}
                    </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

const { getPenggunaKelompokOptions } = global.actions

export default injectIntl(
    connect(
      pengguna.states,
      {...pengguna.actions, getPenggunaKelompokOptions}
    )(Form)
  );
