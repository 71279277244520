import React, { useState } from "react"
import { useFormik } from "formik";
import * as Yup from 'yup';
import { connect } from "react-redux";
import { 
  FormattedMessage, 
  injectIntl 
} from "react-intl";
import clsx from "clsx";
import * as kecamatan from "../../../store/ducks/admin/kecamatan.duck";
import { doAdd, doUpdate } from "../../../crud/admin/kecamatan.crud";
import AlertForm from "../../../components/AlertForm";
import { Modal } from "react-bootstrap";
import TextFieldGroup from "../../../components/form/TextFieldGroup";
import { validationErrorMessage } from "../../../../_metronic/utils/helper";

function Form(props) {
    const { show, closeModal, data, reloadData } = props;
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({open: false, type: 'success', message: ''})
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
      paddingRight: "2.5rem"
    });

    const enableLoading = () => {
      setLoading(true);
      setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
      setLoading(false);
      setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };

    const closeModalHandler = () => {
        closeModal();
        setAlert({open:false})
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            kd_kecamatan: data ? data.kd_kecamatan : '',
            nm_kecamatan: data ? data.nm_kecamatan : '',
            propinsi: data ? data.kabupaten.propinsi.id : '',
            kabupaten: data ? data.kabupaten.id : ''
        },
        validationSchema: Yup.object({
            kd_kecamatan: Yup.string()
              .max(2, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 2}} />)
              .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            nm_kecamatan: Yup.string()
              .max(20, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 20}} />)
              .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            propinsi: Yup.number()
                .integer(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />)
                .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            kabupaten: Yup.number()
                .integer(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />)
                .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
        }),
        onSubmit: (values, { resetForm }) => {
            enableLoading();
            if(data === undefined){
                doAdd(values)
                .then(({ data }) => {
                    resetForm();
                    disableLoading();
                    reloadData();
                    setAlert({open: true, type: 'success', message: 'Tambah data berhasil.'})
                })
                .catch((err) => {
                    disableLoading();
                    let errorMessage = validationErrorMessage(err)
                    setAlert({open: true, type: 'danger', message: `Tambah data gagal. ${errorMessage}`})
                });
            } else {
                doUpdate(values, data.id)
                .then(({ data }) => {
                    disableLoading();
                    reloadData();
                    setAlert({open: true, type: 'success', message: 'Simpan data berhasil.'})
                })
                .catch((err) => {
                    disableLoading();
                    let errorMessage = validationErrorMessage(err)
                    setAlert({open: true, type: 'danger', message: `Simpan data gagal. ${errorMessage}`})
                });
            }
        }
    });

    return (
        <Modal
            size="md"
            show={show}
            onHide={() => closeModalHandler()}
            aria-labelledby="example-modal-sizes-title-md"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-md">
                    {data ? 'Edit' : 'Tambah'} data kecamatan
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                    noValidate={true}
                    autoComplete="off"
                    className="kt-form"
                    onSubmit={formik.handleSubmit}
                >
                    <AlertForm 
                        show={alert.open}
                        variant={alert.type}
                        msg={alert.message}
                        closeAlert={() => setAlert({open: false})}
                    />

                        <TextFieldGroup
                            select={true}
                            margin="normal"
                            label="Propinsi"
                            className="kt-width-full"
                            name="propinsi"
                            {...formik.getFieldProps('propinsi')}
                            onChange={(e) => {
                                formik.handleChange(e)
                                formik.setFieldValue('kabupaten', '')
                                props.loadKabupatenOptions(e.target.value)
                            }}
                            errorText={formik.touched.propinsi && formik.errors.propinsi}
                            error={Boolean(formik.touched.propinsi && formik.errors.propinsi)}
                        >
                        <option>Pilih Propinsi</option>
                            { props.propinsi_options && props.propinsi_options.map(propinsi => (<option key={propinsi.id} value={propinsi.id}>{propinsi.nm_propinsi}</option>)) }
                        </TextFieldGroup>
                        <TextFieldGroup
                            select={true}
                            margin="normal"
                            label="Kabupaten"
                            className="kt-width-full"
                            name="kabupaten"
                            {...formik.getFieldProps('kabupaten')}
                            errorText={formik.touched.kabupaten && formik.errors.kabupaten}
                            error={Boolean(formik.touched.kabupaten && formik.errors.kabupaten)}
                        >
                        <option>Pilih Kabupaten</option>
                            { props.kabupaten_options && props.kabupaten_options.map(kabupaten => (<option key={kabupaten.id} value={kabupaten.id}>{kabupaten.nm_kabupaten}</option>)) }
                        </TextFieldGroup>
                        <TextFieldGroup
                            type="text"
                            label="Kode Kecamatan"
                            margin="normal"
                            className="kt-width-full"
                            name="kd_kecamatan"
                            {...formik.getFieldProps('kd_kecamatan')}
                            errorText={formik.touched.kd_kecamatan && formik.errors.kd_kecamatan}
                            error={Boolean(formik.touched.kd_kecamatan && formik.errors.kd_kecamatan)}
                        />
                        <TextFieldGroup
                            type="text"
                            margin="normal"
                            label="Nama Kecamatan"
                            className="kt-width-full"
                            name="nm_kecamatan"
                            {...formik.getFieldProps('nm_kecamatan')}
                            errorText={formik.touched.nm_kecamatan && formik.errors.nm_kecamatan}
                            error={Boolean(formik.touched.nm_kecamatan && formik.errors.nm_kecamatan)}
                        />

                    <div className="kt-login__actions">

                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={loading}
                        className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                            "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                        }
                        )}`}
                        style={loadingButtonStyle}
                    >
                        { data ? 'Update' : 'Simpan'}
                    </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default injectIntl(
    connect(
      kecamatan.states,
      kecamatan.actions
    )(Form)
  );
