import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import * as global from "./ducks/global.duck";
import * as area_service from "./ducks/admin/area-service.duck";
import * as kotama from "./ducks/admin/kotama.duck";
import * as satker from "./ducks/admin/satker.duck";
import * as propinsi from "./ducks/admin/propinsi.duck";
import * as kabupaten from "./ducks/admin/kabupaten.duck";
import * as kecamatan from "./ducks/admin/kecamatan.duck";
import * as lurah from "./ducks/admin/lurah.duck";
import * as alamat from "./ducks/admin/alamat.duck";
import * as table from "./ducks/admin/table.duck";
import * as table_option from "./ducks/admin/table-option.duck";
import * as top_kelompok from "./ducks/admin/top-kelompok.duck";
import * as top_komoditi from "./ducks/admin/top-komoditi.duck";
import * as top_fungsi from "./ducks/admin/top-fungsi.duck";
import * as top_jenis from "./ducks/admin/top-jenis.duck";
import * as top_merk from "./ducks/admin/top-merk.duck";
import * as top_satuan from "./ducks/admin/top-satuan.duck";
import * as satyanis from "./ducks/admin/satyanis.duck";
import * as pengguna_kelompok from "./ducks/admin/pengguna-kelompok.duck";
import * as pengguna from "./ducks/admin/pengguna.duck";
import * as asal from "./ducks/admin/asal.duck";
import * as bentuk_bgn from "./ducks/admin/bentuk-bgn.duck";
import * as bukti from "./ducks/admin/bukti.duck";
import * as air from "./ducks/admin/air.duck";
import * as gas from "./ducks/admin/gas.duck";
import * as konstruksi from "./ducks/admin/konstruksi.duck";
import * as listrik from "./ducks/admin/listrik.duck";
import * as status from "./ducks/admin/status.duck";
import * as kondisi from "./ducks/admin/kondisi.duck";
import * as nyata from "./ducks/transaction/nyata.duck";
import { metronic } from "../../_metronic";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  global: global.reducer,
  area_service: area_service.reducer,
  kotama: kotama.reducer,
  satker: satker.reducer,
  propinsi: propinsi.reducer,
  kabupaten: kabupaten.reducer,
  kecamatan: kecamatan.reducer,
  lurah: lurah.reducer,
  alamat: alamat.reducer,
  table: table.reducer,
  table_option: table_option.reducer,
  top_kelompok: top_kelompok.reducer,
  top_komoditi: top_komoditi.reducer,
  top_fungsi: top_fungsi.reducer,
  top_jenis: top_jenis.reducer,
  top_merk: top_merk.reducer,
  top_satuan: top_satuan.reducer,
  satyanis: satyanis.reducer,
  pengguna_kelompok: pengguna_kelompok.reducer,
  pengguna: pengguna.reducer,
  asal: asal.reducer,
  bentuk_bgn: bentuk_bgn.reducer,
  bukti: bukti.reducer,
  gas: gas.reducer,
  air: air.reducer,
  listrik: listrik.reducer,
  konstruksi: konstruksi.reducer,
  kondisi: kondisi.reducer,
  status: status.reducer,
  nyata: nyata.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
});

export function* rootSaga() {
  yield all([
    auth.saga(),
    // global.saga(),
    // area_service.saga(),
    // kotama.saga(),
    // satker.saga(),
    // propinsi.saga(),
    // kabupaten.saga(),
    // kecamatan.saga(),
    // lurah.saga(),
    // alamat.saga(),
    // table.saga(),
    // table_option.saga(),
    // top_kelompok.saga(),
    // top_komoditi.saga(),
    // top_fungsi.saga(),
    // top_jenis.saga(),
    // top_merk.saga(),
    // top_satuan.saga(),
    // asal.saga(),
    // bentuk_bgn.saga(),
    // bukti.saga(),
    // gas.saga(),
    // air.saga(),
    // konstruksi.saga(),
    // kondisi.saga(),
    // listrik.saga(),
    // status.saga(),
  ]);
}
