import React, { useState } from "react"
import { useFormik } from "formik";
import * as Yup from 'yup';
import { connect } from "react-redux";
import { 
  FormattedMessage, 
  injectIntl 
} from "react-intl";
import clsx from "clsx";
import * as status from "../../../store/ducks/admin/status.duck";
import { doAdd, doUpdate } from "../../../crud/admin/status.crud";
import AlertForm from "../../../components/AlertForm";
import { Modal } from "react-bootstrap";
import TextFieldGroup from "../../../components/form/TextFieldGroup";
import { validationErrorMessage } from "../../../../_metronic/utils/helper";

function Form(props) {
    const { show, closeModal, data, reloadData } = props;
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({open: false, type: 'success', message: ''})
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
      paddingRight: "2.5rem"
    });

    const enableLoading = () => {
      setLoading(true);
      setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
      setLoading(false);
      setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };

    const closeModalHandler = () => {
        closeModal();
        setAlert({open:false})
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: data ? data : {
            kd_status: '',
            nm_status: ''
        },
        validationSchema: Yup.object({
            kd_status: Yup.string()
              .max(2, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 2}} />)
              .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />),
            nm_status: Yup.string()
              .max(20, <FormattedMessage id="VALIDATION.MAX_LENGTH_FIELD" values={{max: 20}} />)
              .required(<FormattedMessage id="VALIDATION.REQUIRED_FIELD" />)
        }),
        onSubmit: (values, { resetForm }) => {
            enableLoading();
            if(data === undefined){
                doAdd(values)
                .then(({ data }) => {
                    resetForm();
                    disableLoading();
                    reloadData();
                    setAlert({open: true, type: 'success', message: 'Tambah data berhasil.'})
                })
                .catch((err) => {
                    disableLoading();
                    let errorMessage = validationErrorMessage(err)
                    setAlert({open: true, type: 'danger', message: `Tambah data gagal. ${errorMessage}`})
                });
            } else {
                doUpdate(values, data.id)
                .then(({ data }) => {
                    disableLoading();
                    reloadData();
                    setAlert({open: true, type: 'success', message: 'Simpan data berhasil.'})
                })
                .catch((err) => {
                    disableLoading();
                    let errorMessage = validationErrorMessage(err)
                    setAlert({open: true, type: 'danger', message: `Simpan data gagal. ${errorMessage}`})
                });
            }
        }
    });

    return (
        <Modal
            size="md"
            show={show}
            onHide={() => closeModalHandler()}
            aria-labelledby="example-modal-sizes-title-md"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-md">
                    {data ? 'Edit' : 'Tambah'} data status
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                    noValidate={true}
                    autoComplete="off"
                    className="kt-form"
                    onSubmit={formik.handleSubmit}
                >
                    <AlertForm 
                        show={alert.open}
                        variant={alert.type}
                        msg={alert.message}
                        closeAlert={() => setAlert({open: false})}
                    />

                        <TextFieldGroup
                            type="text"
                            label="Kode Status"
                            margin="normal"
                            className="kt-width-full"
                            name="kd_status"
                            {...formik.getFieldProps('kd_status')}
                            errorText={formik.touched.kd_status && formik.errors.kd_status}
                            error={Boolean(formik.touched.kd_status && formik.errors.kd_status)}
                        />
                        <TextFieldGroup
                            type="text"
                            margin="normal"
                            label="Nama Status"
                            className="kt-width-full"
                            name="nm_status"
                            {...formik.getFieldProps('nm_status')}
                            errorText={formik.touched.nm_status && formik.errors.nm_status}
                            error={Boolean(formik.touched.nm_status && formik.errors.nm_status)}
                        />

                    <div className="kt-login__actions">

                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={loading}
                        className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                            "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                        }
                        )}`}
                        style={loadingButtonStyle}
                    >
                        { data ? 'Update' : 'Simpan'}
                    </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}


export default injectIntl(
    connect(
      status.states,
      status.actions
    )(Form)
  );
