import React from "react"
import { Form } from "react-bootstrap"

const TextFieldGroup = (props) => {
  return (
    <Form.Group as={props.as} md={props.md}>
        <Form.Label>{props.label}</Form.Label>
        { props.select ? (
          <Form.Control
            size={props.size ? props.size : 'sm'}
            type={props.type}
            name={props.name}
            isInvalid={props.error}
            className={props.className}
            onChange={props.onChange}
            onBlur={props.onBlur}
            value={props.value}
            placeholder={props.placeholder ? props.placeholder : `Pilih ${props.label}...`}
            defaultValue={props.defaultValue}
            as="select"
          >
            {props.children}
          </Form.Control>
        ) : (
          <Form.Control
            size={props.size ? props.size : 'sm'}
            type={props.type}
            name={props.name}
            isInvalid={props.error}
            className={props.className}
            onChange={props.onChange}
            onBlur={props.onBlur}
            onKeyDown={props.onKeyDown}
            value={props.value}
            placeholder={props.placeholder ? props.placeholder : `Masukan ${props.label}...`}
            defaultValue={props.defaultValue}
          />
        )}
        {props.error && (
          <Form.Control.Feedback type="invalid">{props.errorText}</Form.Control.Feedback>
        )}
        {props.helperText && (
          <Form.Text muted>
          {props.helperText}
          </Form.Text>
        )}
    </Form.Group>
  )
}

export default TextFieldGroup