import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { 
  // FormattedMessage, 
  injectIntl 
} from "react-intl";
import { Portlet, PortletHeader, PortletBody, PortletFooter } from "../../../partials/content/Portlet";
import * as nyata from "../../../store/ducks/transaction/nyata.duck";
import * as global from "../../../store/ducks/global.duck";
import { doList as doListTopFungsi } from "../../../crud/admin/top-fungsi.crud";
import { doList as doListTopJenis } from "../../../crud/admin/top-jenis.crud";
import { doList, doDelete } from "../../../crud/transaction/nyata.crud";
import ConfirmDelete from "../../../components/ConfirmDelete";
import AlertForm from "../../../components/AlertForm";
import clsx from 'clsx';
// import Form from "./Form";
import TextFieldGroup from "../../../components/form/TextFieldGroup";
import { Form, Col, Row, Badge } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic";
import ReactPaginate from 'react-paginate';
import { Grow, Button, LinearProgress } from "@material-ui/core";

function NyataAreaService(props) {
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState({
      areaService: '',
      fungsi: '',
      jenis: ''
    });
    const [confirmDelete, setConfirmDelete] = useState({
      open: false,
      id: null
    });
    const [form, setForm] = useState({
      open: false,
      data: null
    });
    const [alert, setAlert] = useState({open: false, type: 'success', message: ''})
    const [totalpages, setTotalpages] = useState(1);
    const [pageSize, setPageSize] = useState(12);
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
      paddingRight: "2.5rem"
    });

    const komoditi = props.match.params.komoditi;
    const satyanis = props.match.params.satyanis;
    
    const enableLoading = () => {
      setLoading(true);
      setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
      setLoading(false);
      setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };
    
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        fetchData()
      }
    }

    function fetchData(data) {
      enableLoading();
      // const ordering = state.sorted.map(next => ((next.desc ? '-' : '') + next.id)).join(',')
      const params = {
        satyanis__id: satyanis,
        komoditi__id: komoditi,
        fungsi__id: filter.fungsi !== 'all' ? filter.fungsi : '',
        jenis__id: filter.jenis !== 'all' ? filter.jenis : '',
        // ordering: ordering ? ordering : '-kd_merk',
        search: search,
        page: data ? data.selected + 1 : 1,
        page_size: pageSize
      }
      doList(params)
        .then(({ data: { results, count } }) => {
            disableLoading();
            let totalpage=Math.ceil(parseInt(count)/parseInt(pageSize));
            setTotalpages(totalpage)
            props.getNyataData(results, count);
          }
        ).catch(error => {
          disableLoading();
        })
    }

    function handleDelete() {
      setConfirmDelete({open:false})
      setAlert({open: false})
      enableLoading();
      doDelete(confirmDelete.id).then(response => {
        setAlert({open: true, type: 'success', message: 'Hapus data berhasil.'})
        disableLoading();
        fetchData();
      }).catch(error => {
        setAlert({open: true, type: 'danger', message: 'Hapus data gagal.'})
        disableLoading();
      })
    }

    const loadTopFungsiOptions = (id_komoditi) => {
      doListTopFungsi({ordering: 'nm_fungsi', komoditi__id: id_komoditi, page_size: 9999999})
          .then(({ data: { results } }) => {
              props.getTopFungsiOptions(results);
          }
      )
    }

    const loadTopJenisOptions = (id_fungsi) => {
      doListTopJenis({ordering: 'nm_jenis', fungsi__id: id_fungsi, page_size: 9999999})
          .then(({ data: { results } }) => {
              props.getTopJenisOptions(results);
          }
      )
    }

    useEffect(() => {
      if(filter.areaService === '' && filter.fungsi === '' && filter.jenis === ''){
        loadTopFungsiOptions(komoditi)
      }
      fetchData()
      // eslint-disable-next-line
    }, [filter.areaService, filter.fungsi, filter.jenis, props.match.params.satyanis, props.match.params.komoditi]);
    
    return (
      <>
        <div className="row">
          <div className="col-lg-12">
            <ConfirmDelete
              handleClose={() => setConfirmDelete({open:false})}
              handleDelete={() => handleDelete()}
              show={confirmDelete.open}
            />
            <AlertForm 
              show={alert.open}
              variant={alert.type}
              msg={alert.message}
              closeAlert={() => setAlert({open: false})}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <TextFieldGroup
                select={true}
                margin="normal"
                label="Area Layanan"
                className="kt-width-full"
                onChange={(e) => {
                  setFilter({...filter, areaService: e.target.value})
                }}
                value={filter.areaService}
            >
              <option key={0} value="all">Semua</option>
              {/* { props.top_fungsi_options && props.top_fungsi_options.map(top_fungsi => (<option key={top_fungsi.id} value={top_fungsi.id}>{top_fungsi.nm_fungsi}</option>)) } */}
            </TextFieldGroup>
          </div>
          <div className="col-lg-3">
            <TextFieldGroup
                select={true}
                margin="normal"
                label="Top Fungsi"
                className="kt-width-full"
                onChange={(e) => {
                  setFilter({fungsi: e.target.value, jenis: ''})
                  loadTopJenisOptions(e.target.value)
                }}
                value={filter.fungsi}
            >
              <option key={0} value="all">Semua</option>
              { props.top_fungsi_options && props.top_fungsi_options.map(top_fungsi => (<option key={top_fungsi.id} value={top_fungsi.id}>{top_fungsi.nm_fungsi}</option>)) }
            </TextFieldGroup>
          </div>
          <div className="col-lg-3">
            <TextFieldGroup
                select={true}
                margin="normal"
                label="Top Jenis"
                className="kt-width-full"
                onChange={(e) => {
                  setFilter({...filter, jenis: e.target.value})
                }}
                value={filter.jenis}
            >
              <option key={0} value="all">Semua</option>
              { props.top_jenis_options && props.top_jenis_options.map(top_jenis => (<option key={top_jenis.id} value={top_jenis.id}>{top_jenis.nm_jenis}</option>)) }
            </TextFieldGroup>
          </div>
          <div className="col-lg-3">
              <TextFieldGroup
                  margin="normal"
                  label="Cari Top Merk"
                  placeholder="Cari Top Merk ..."
                  className="kt-width-full"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e)}
              />
          </div>
        </div>
        <div className="row">
          { loading && (<div className="col-lg-12"><LinearProgress /></div>) }
          { props.datum && props.datum.length > 0 ? props.datum.map((data, index) => (
            <div className="col-lg-3" key={index}>
              <Grow 
              in={true} 
              timeout={1000}>
                <Portlet fluidHeight={true}>
                <PortletHeader
                  title={data.merk ? data.merk.kd_merk : '-'}
                />
                <PortletBody>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="kt-widget4__pic" style={{textAlign:"center"}}>
                        <img
                          alt=""
                          className="kt-widget4__img"
                          src={toAbsoluteUrl("/media/products/product.jpg")}
                        />
                      </div>
                      <Form.Group as={Row}>
                        <Form.Label column sm="5">NO REG</Form.Label>
                        <Col sm="7">
                          <Form.Control
                            plaintext
                            readOnly
                            defaultValue={data.no_reg}
                          />
                        </Col>
                        <Form.Label column sm="5">KOTAMA</Form.Label>
                        <Col sm="7">
                        <Form.Control
                        plaintext
                        readOnly
                        defaultValue={data.kotama.nm_kotama}/>
                        </Col>
                        <Form.Label column sm="5">SATKER</Form.Label>
                        <Col sm="7">
                        <Form.Control
                        plaintext
                        readOnly
                        defaultValue={data.satpor.nm_satpor}/>
                        </Col>
                        <Form.Label column sm="5">KOMODITI</Form.Label>
                        <Col sm="7">
                        <Form.Control
                        plaintext
                        readOnly
                        defaultValue={data.komoditi.nm_komoditi}/>
                        </Col>
                        <Form.Label column sm="5">FUNGSI:</Form.Label>
                        <Col sm="7">
                        <Form.Control
                          plaintext
                          readOnly
                          defaultValue={data.fungsi.nm_fungsi}
                        />
                        </Col>
                        <Form.Label column sm="5">KONDISI</Form.Label>
                        <Col sm="7">
                          <Badge variant="success">{data.baik} Baik</Badge>&nbsp;
                          <Badge variant="warning">{data.rr} RR</Badge>&nbsp;
                          <Badge variant="danger">{data.rb} RB</Badge>
                        </Col>
                      </Form.Group>
                    </div>
                  </div>
                </PortletBody>
                <PortletFooter style={{textAlign:"center"}}>
                  <Link to={`/detail/aset/${data.id}`} ><Button variant="secondary">Detail</Button></Link>
                </PortletFooter>
              </Portlet>
            </Grow>
            </div>
          )) : !loading && (<div className="col-lg-12 d-flex justify-content-center">Tidak ada data.</div>)
          }
        </div>
        {props.datum && props.datum.length > 1 && (
          <div className="row">
            <div className="col-lg-12">
              <ReactPaginate
                breakClassName="page-item"
                breakLabel={<span className="page-link">...</span>}
                pageClassName="page-item"
                previousClassName="page-item"
                nextClassName="page-item"
                pageLinkClassName="page-link"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                previousLabel={'Previous'}
                nextLabel={'Next'}
                pageCount={totalpages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={fetchData}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            </div>
          </div>
        )}
      </>
    )
}

const { getTopFungsiOptions, getTopJenisOptions } = global.actions

export default injectIntl(
    connect(
      nyata.states,
      { ...nyata.actions, getTopFungsiOptions, getTopJenisOptions }
    )(NyataAreaService)
  );
