import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  Col,
  Form
} from "react-bootstrap";
import { doDelete} from "../../../crud/cctv.crud";
export default function EditForm(props) {
   const [showform,setShowform]=useState(false);
   const [validated,setValidated]=useState(false);
   const [loading,setLoading]=useState(false);

   const confirmDelete = (event) => {
         event.preventDefault();

         doDelete(props.idData).then(res => res.success)
         .then(
           (result) => {
             props.onFormChange({status:true,msg:"data berhasil dihapus",variant:'success'});
         },
         (error) => {
           props.onFormChange({status:false,msg:"data tidak berhasil dihapus",variant:'danger'});
         }
         )
         props.closeForm(false);

        };

        function handleClose() {
          props.closeForm(false);
        }

     return (
       <>
       <Modal show={props.modalVisible} onHide={handleClose}>
         <Modal.Header closeButton>
           <Modal.Title>Hapus Data</Modal.Title>
         </Modal.Header>
         <Form
           onSubmit={confirmDelete}
         >
         <Modal.Body>
         <p>
           Apakah Anda Akan Menghapus Data Ini?
         </p>
         </Modal.Body>
         <Modal.Footer>
           <Button variant="secondary" onClick={handleClose}>
             Tutup
           </Button>
           <Button type="submit" variant="danger">
             Hapus Data
           </Button>
         </Modal.Footer>
          </Form>
       </Modal>
</>

     )
}
