import React, { useState } from 'react';
import CodeExample from "../../../partials/content/CodeExample";
import ReactTable from "react-table";
import AddForm from "../../form/cctv/AddForm";
import EditForm from "../../form/cctv/EditForm";
import DeleteForm from "../../form/cctv/DeleteForm";
import AlertForm from "../../../components/AlertForm";
import "react-table/react-table.css";
import { doList,getData } from "../../../crud/cctv.crud";
export default function Waduk() {
         const [data, setData] = useState([]);
         const [pages,setPages]=useState(1);
         const [datas, setDatas] = useState([]);
         const [pagesize,setPagesize]=useState(0);
         const [sort,setSort]=useState([]);
         const [filter,setFilter]=useState([]);
         const [totalpages,setTotalpages]=useState(1);
         const [id,setId]=useState(0);

         const [loading,setLoading]=useState(false);
         const [showedit,setShowedit]=useState(false);
         const [showdelete,setShowdelete]=useState(false);
         const [showalert,setShowalert]=useState(false);

         const [msg,setMsg]=useState('');
         const [variant,setVariant]=useState('');




         function reloadData(val) {

           if(val.status){
             const state = {page:pages,pageSize:pagesize,sorted:sort,filtered:filter};
              setShowalert(true)
              setVariant(val.variant)
              setMsg(val.msg)
              fetchData(state)
            }else{
              setShowalert(true)
              setVariant(val.variant)
              setMsg(val.msg)
            }
          }

           function fetchData(state, instance) {
              doList(((state.page)+1),state.pageSize,state.sorted,state.filtered)
              .then(res => res.data)
               .then(
                 (result) => {
                   setData(result.data)
                   setTotalpages(result.totalpage)
                   setPages(state.page)
                   setLoading(false)
                   setPagesize(state.pageSize)
                   setSort(state.sorted)
                   setFilter(state.filtered)
                 }
               ).catch(error => {
                     console.log(error)
                })

           }

           function showEdit(val,e){
             e.preventDefault();
              getData(val).then(res => res.data)
              .then(
                (result) => {
                  setDatas(result.data)
              },
              (error) => {
                setLoading(true);
              }
              )
            setShowedit(true);
            setId(val);
           }

           function showDelete(val,e){
             e.preventDefault();
            setShowdelete(true);
            setId(val);
           }



           function closeEdit(val){
             setShowedit(val);
           }
           function closeDelete(val){
             setShowdelete(val);
           }
           function closeAlert(val){
             setShowalert(val);
           }


           //const { data, pages, loading, fetchData } = this.props;
          return (

            <div className="row">

              <div className="col-md-8">

              <CodeExample beforeCodeTitle="  Data - Data CCTV">
                <div className="kt-section">
                <AddForm onFormChange={(value)=>reloadData(value)}/>
                <EditForm modalVisible={showedit} formData={datas} idData={id} closeForm={(value)=>closeEdit(value)} onFormChange={(value)=>reloadData(value)}/>
                <DeleteForm modalVisible={showdelete}  idData={id}  closeForm={(value)=>closeDelete(value)} onFormChange={(value)=>reloadData(value)}/>
                <br/><br/>
                <AlertForm show={showalert} variant={variant} msg={msg} closeAlert={(value)=>closeAlert(value)}/>
                  <ReactTable
                       data={data}
                       pages={totalpages}
                       columns={[

                             {
                               Header: "Lokasi",
                               accessor: "lokasi",
                               filterable:true
                             },
                             {
                               Header: "Jumlah",
                               accessor: "jumlah",
                               filterable:true
                             },
                             {
                               Header: "Nama Bidang",
                               accessor: "namaBidang",
                               filterable:true
                              },
                              {
                                Header: "Lintang",
                                accessor: "lintang"
                              },
                                {
                                  Header: "Bujur",
                                  accessor: "bujur"
                                },{
                                  Header: "Aksi",
                                  accessor: "idCctv",
                                  Cell: row =><div style={{ textAlign: "center" }}> <a href="#" onClick={(e) =>showEdit(row.value, e)}><i class="flaticon2-edit"></i></a>  <a  onClick={(e) =>showDelete(row.value, e)}><i class="flaticon2-rubbish-bin"></i></a></div>
                                }
                            ]}
                            defaultSorted={[
                              {
                                id: "lokasi",
                                desc: false
                              }
                            ]}
                     defaultPageSize={20}
                     className="-striped -highlight"
                     loading={loading}
                     showPagination={true}
                     showPaginationTop={true}
                     showPaginationBottom={true}

                     pageSizeOptions={[20, 25, 50, 100]}
                     manual // this would indicate that server side pagination has been enabled
                     onFetchData={fetchData}
                     />
                     </div>
                     </CodeExample>
                   </div>
               </div>
         );
     }
