import React, { useState } from 'react';
import CardPage from "../../partials/content/CodeExample";
import ReactTable from "react-table";
import "react-table/react-table.css";

import { doList,getData } from "../../crud/admin/satker.crud";
export default function Satpor() {
         const [data, setData] = useState([]);
         const [pages,setPages]=useState(1);
         const [datas, setDatas] = useState([]);
         const [pagesize,setPagesize]=useState(0);
         const [sort,setSort]=useState([]);
         const [filter,setFilter]=useState([]);
         const [totalpages,setTotalpages]=useState(1);
         const [id,setId]=useState(0);

         const [loading,setLoading]=useState(false);





           function fetchData(state, instance) {
            const ordering = state.sorted.map(next => ((next.desc ? '-' : '') + next.id)).join(',')
            const params = {
              ordering: ordering ? ordering : 'kd_satpor',
              search: '',
              page: state.page + 1,
              page_size: state.pageSize
            }
              doList(params)
              .then(res => res.data)
               .then(
                 (result) => {
                   var totalpage=Math.ceil(parseInt(result.count)/parseInt(state.pageSize));
                   setData(result.results)
                   setTotalpages(totalpage)
                   setPages(state.page)
                   setLoading(false)
                   setPagesize(state.pageSize)
                   setSort(state.sorted)
                   setFilter(state.filtered)
                 }
               ).catch(error => {
                     console.log(error)
                })

           }



           //const { data, pages, loading, fetchData } = this.props;
          return (

            <div className="row">

              <div className="col-md-12">

              <CardPage beforeCodeTitle="Data Satpor">
                <div className="kt-section">
                  <ReactTable
                       data={data}
                       pages={totalpages}
                       columns={[
                         {
                           Header: "Kode Inetegrasi",
                           accessor: "kd_uniq"
                         },{
                           Header: "Kode Kotama",
                           accessor: "kotama.kd_kotama"
                         },{
                             Header: "Nama Kotama",
                             accessor: "kotama.nm_kotama"
                           },{
                               Header: "Kode Satpor",
                               accessor: "kd_satpor"
                             },
                             {
                               Header: "Nama Satpor",
                               accessor: "nm_satpor"
                              },
                              {
                                Header: "KD TOP Satuan",
                                accessor: "kd_top_satuan"
                              },{
                                Header: "NM TOP Satuan",
                                accessor: "nm_top_satuan"
                              },
                               {
                                 Header: "Alamat",
                                 accessor: "alamat"
                               },
                                {
                                  Header: "Latitude",
                                  accessor: "latitude"
                                },
                                 {
                                   Header: "Longitude",
                                   accessor: "longitude"
                                 }

                            ]}
                            defaultSorted={[
                              {
                                id: "lokasi",
                                desc: false
                              }
                            ]}
                     defaultPageSize={10}
                     className="-striped -highlight"
                     loading={loading}
                     showPagination={true}
                     showPaginationTop={true}
                     showPaginationBottom={true}

                     pageSizeOptions={[10]}
                     manual // this would indicate that server side pagination has been enabled
                     onFetchData={fetchData}
                     />
                     </div>
                     </CardPage>
                   </div>
               </div>
         );
     }
