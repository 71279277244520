export const validationErrorMessage = (err) => {
  let msg = ''
  if(err.response.status === 400){
    msg += '<ul>'

    Object.keys(err.response.data).map(key => {
      let label = key
      // Replaces any - or _ characters with a space 
      .replace( /[-_]+/g, ' ')
      // insert a space before all caps
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, (str) => str.toUpperCase())
      .trim()

      msg += `<li><b>${label}</b> ${err.response.data[key]}</li>`

      return key
    }) 
    
    msg += '</ul>'
  }
  return msg
}