import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { 
  // FormattedMessage, 
  injectIntl 
} from "react-intl";
import { Portlet, PortletHeader, PortletBody } from "../../../partials/content/Portlet";
import * as nyata from "../../../store/ducks/transaction/nyata.duck";
import { Form, Col, Row, Badge } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic";
import { getData } from "../../../crud/transaction/nyata.crud";
import { LinearProgress } from "@material-ui/core";

function Detail(props) {
    const [loading, setLoading] = useState(false);
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
      paddingRight: "2.5rem"
    });

    const enableLoading = () => {
      setLoading(true);
      setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
      setLoading(false);
      setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };

    function fetchData() {
      enableLoading();
      getData(props.match.params.nyata_id)
        .then(({ data }) => {
            disableLoading();
            props.getNyataDetail(data);
          }
        ).catch(error => {
          disableLoading();
        })
    }

    useEffect(() => {
      fetchData()
      // eslint-disable-next-line
    }, []);
    const { data } = props
    return (
      <>
        <div className="row">
          { loading && (<div className="col-lg-12"><LinearProgress /></div>) }
          { !loading && data && (
            <>
              <div className="col-lg-12">
                <Portlet fluidHeight={true}>
                  <PortletHeader
                    title={`Detail Aset ${data.merk ? data.merk.kd_merk : '-'}`}
                  />
                  <PortletBody>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="kt-widget4__pic" style={{textAlign:"center"}}>
                          <img
                            alt=""
                            className="kt-widget4__img"
                            src={toAbsoluteUrl("/media/products/product.jpg")}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group as={Row}>
                          <Form.Label column sm="3">NO REG</Form.Label>
                          <Col sm="9">
                            <Form.Control
                              plaintext
                              readOnly
                              defaultValue={data.no_reg}
                            />
                          </Col>
                          <Form.Label column sm="3">KOTAMA</Form.Label>
                          <Col sm="9">
                          <Form.Control
                          plaintext
                          readOnly
                          defaultValue={data.kotama.nm_kotama}/>
                          </Col>
                          <Form.Label column sm="3">SATKER</Form.Label>
                          <Col sm="9">
                          <Form.Control
                          plaintext
                          readOnly
                          defaultValue={data.satpor.nm_satpor}/>
                          </Col>
                          <Form.Label column sm="3">KOMODITI</Form.Label>
                          <Col sm="9">
                          <Form.Control
                          plaintext
                          readOnly
                          defaultValue={data.komoditi.nm_komoditi}/>
                          </Col>
                          <Form.Label column sm="3">FUNGSI:</Form.Label>
                          <Col sm="9">
                          <Form.Control
                            plaintext
                            readOnly
                            defaultValue={data.fungsi.nm_fungsi}
                          />
                          </Col>
                          <Form.Label column sm="3">KONDISI</Form.Label>
                          <Col sm="9">
                            <Badge variant="success">{data.baik} Baik</Badge>&nbsp;
                            <Badge variant="warning">{data.rr} RR</Badge>&nbsp;
                            <Badge variant="danger">{data.rb} RB</Badge>
                          </Col>
                        </Form.Group>
                      </div>
                    </div>
                  </PortletBody>
                </Portlet>
              </div>
              <div className="col-lg-12">
              <Portlet fluidHeight={true}>
                <PortletHeader
                  title='Informasi Aset '
                />
                <PortletBody>
                  <div className="row">
                    {
                      data.nyata_dinamis && data.nyata_dinamis.map((dinamis, index) => {
                        if(dinamis.data && dinamis.data.active) {
                          return (
                            <div key={index} className="col-lg-6">
                              <Form.Group controlId="formName" as={Row}>
                                <Form.Label column sm="5" className="text-uppercase">{dinamis.data.name}</Form.Label>
                                <Col sm="7">
                                  <Form.Control
                                  plaintext
                                  readOnly
                                  defaultValue={dinamis.value}/>
                                </Col>
                              </Form.Group>
                            </div>
                          )
                        }
                        return dinamis
                      })
                    }
                  </div>
                </PortletBody>
              </Portlet>
            </div>
            </>
          )}
        </div>
      </>
    )
}

export default injectIntl(
    connect(
      nyata.states,
      nyata.actions
    )(Detail)
  );
